<template>
    <div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g class="ds" clip-path="url(#clip0)">
            <path d="M7.64657 1.22686C7.18485 1.22686 6.81073 1.6011 6.81073 2.06271L6.81072 5.62655L1.4273 0.244239C1.26403 0.0810817 1.05017 -0.000497514 0.836303 -0.000497533C0.622325 -0.000497551 0.408348 0.0810816 0.24519 0.244351C-0.0812361 0.570777 -0.0812362 1.10004 0.245302 1.42635L5.62895 6.80877L2.06433 6.80877C1.60272 6.80877 1.22848 7.18301 1.22848 7.64462C1.22848 8.10623 1.60261 8.48047 2.06433 8.48047L7.64657 8.48047C8.1083 8.48047 8.48242 8.10623 8.48242 7.64462L8.48242 2.0626C8.48242 1.6011 8.1083 1.22686 7.64657 1.22686Z" fill="#6D6E71"/>
            <path d="M16.1881 22.6058C16.6498 22.6058 17.0239 22.2316 17.0239 21.77L17.0239 18.206L22.5731 23.7552C22.8996 24.0816 23.4288 24.0816 23.7552 23.7552C24.0816 23.4288 24.0816 22.8995 23.7552 22.5732L18.2061 17.0241L21.7699 17.0241C22.2316 17.0241 22.6057 16.6499 22.6057 16.1883C22.6057 15.7267 22.2316 15.3525 21.7699 15.3525L16.188 15.3525C15.7262 15.3525 15.3521 15.7267 15.3521 16.1883L15.3521 21.77C15.3522 22.2316 15.7265 22.6058 16.1881 22.6058Z" fill="#6D6E71"/>
            <path d="M1.42815 23.7552L6.97674 18.2061L6.97674 21.77C6.97674 22.2316 7.35087 22.6058 7.81259 22.6058C8.27431 22.6058 8.64844 22.2316 8.64844 21.77L8.64844 16.1881C8.64844 15.7265 8.27431 15.3522 7.81259 15.3522L2.2309 15.3522C1.76929 15.3522 1.39505 15.7265 1.39505 16.1881C1.39505 16.6497 1.76918 17.0239 2.2309 17.0239L5.79474 17.0239L0.24604 22.5732C-0.080387 22.8996 -0.0803871 23.4289 0.246152 23.7553C0.572355 24.0816 1.10161 24.0816 1.42815 23.7552Z" fill="#6D6E71"/>
            <path d="M16.3546 8.48242L21.9365 8.48242C22.3982 8.48242 22.7723 8.10818 22.7723 7.64657C22.7723 7.18496 22.3982 6.81072 21.9365 6.81072L18.3726 6.81072L23.7552 1.42775C24.0816 1.10132 24.0816 0.572062 23.7551 0.245636C23.5919 0.0824775 23.378 0.00089926 23.1641 0.000899242C22.9502 0.000899223 22.7362 0.08259 22.573 0.245747L17.1903 5.62872L17.1903 2.06466C17.1903 1.60305 16.8162 1.22882 16.3545 1.22882C15.8928 1.22882 15.5186 1.60305 15.5186 2.06466L15.5186 7.64657C15.5188 8.1083 15.893 8.48242 16.3546 8.48242Z" fill="#6D6E71"/>
        </g>
        <defs>
            <clipPath id="clip0">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>

    </div>
</template>
<script>
export default{
    name:'Collapse'
}
</script>
<style scoped>
   .ds:hover path{
        fill:#0080F6 ;
    }
</style>