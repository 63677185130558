<template>
    <div class="customer_home siteUser_container">
        <div class="main_table">
            <div class="User__btn_container dialog_update">
                 <div class="dialog_update__selects"  v-if='accessRight3'>
                  <div class="dialog_update__select">
                      <!-- <div class="status_lists">
                          <li v-for="(item, index) in rooms" :key="index" @click="selectRoom(item)">
                              <div >{{item.name}}</div>
                          </li>
                      </div> -->
                       <svg class="icon" style="margin-right:8px" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8184 21.9373L17.4468 16.5678C18.8966 14.9157 19.7777 12.7519 19.7777 10.3857C19.7777 5.21029 15.5653 1 10.3888 1C5.21133 0.998962 1 5.21029 1 10.3847C1 15.5591 5.21237 19.7704 10.3888 19.7704C12.7519 19.7704 14.9136 18.8924 16.5657 17.4468L21.9383 22.8174C22.0598 22.9398 22.2196 23 22.3784 23C22.5382 23 22.697 22.9388 22.8184 22.8174C23.0623 22.5745 23.0623 22.1802 22.8184 21.9373ZM10.3889 18.5251C5.89836 18.5251 2.24536 14.8731 2.24536 10.3847C2.24536 5.89626 5.89836 2.24429 10.3889 2.24429C14.8794 2.24429 18.5324 5.89626 18.5324 10.3847C18.5324 14.8731 14.8794 18.5251 10.3889 18.5251Z" fill="gray"/>
                      </svg>
                      <div ><input type="text" class="input_select" placeholder="Search fullname, email or employee number" v-model='searchUser'></div>
                  </div>
                </div>
                 <md-menu v-if='accessRight2' md-size="medium" class="User_menu" :md-offset-x="-44" :md-offset-y="0" >
                     <md-button class="md-raised md-primary btn_add" md-menu-trigger> 
                        <svg style="margin-right:8px" width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                <path d="M22.6662 7.30428H24.0216V8.65962C24.0216 9.38515 24.6097 9.97331 25.3353 9.97331C26.0608 9.97331 26.6489 9.38515 26.6489 8.65962V7.30428H28.0044C28.7299 7.30428 29.318 6.71612 29.318 5.99059C29.318 5.26506 28.7299 4.67691 28.0044 4.67691H26.6489V3.3215C26.6489 2.59597 26.0608 2.00781 25.3353 2.00781C24.6097 2.00781 24.0216 2.59597 24.0216 3.3215V4.67691H22.6662C21.9407 4.67691 21.3525 5.26506 21.3525 5.99059C21.3525 6.71612 21.9408 7.30428 22.6662 7.30428Z" fill="#FFFFFF"/>
                                <path d="M11.9901 19.3357C15.6753 19.3357 18.6628 16.3482 18.6628 12.6629C18.6628 8.97771 15.6753 5.99023 11.9901 5.99023C8.30486 5.99023 5.31738 8.97771 5.31738 12.6629C5.31738 16.3482 8.30486 19.3357 11.9901 19.3357Z" fill="#FFFFFF"/>
                                <path d="M23.9695 28.6778C23.9695 25.1564 22.4631 21.9796 20.0209 19.7844C19.4588 19.2791 18.6113 19.2597 18.0366 19.7508C14.5515 22.7289 9.42932 22.7297 5.9433 19.751C5.36947 19.2606 4.5212 19.2759 3.96101 19.7817C1.53169 21.976 0.00187687 25.1481 1.79583e-07 28.6714C-0.000375159 29.3974 0.587655 29.9915 1.31369 29.9915H22.6559C23.3793 29.9915 23.9695 29.4054 23.9695 28.6778Z" fill="#FFFFFF"/>
                                <path d="M29.497 21.7856C28.77 22.3778 27.9147 22.8185 26.9801 23.059C26.2648 23.2431 25.8416 23.9789 26.0428 24.6896C26.6655 26.8887 26.597 28.739 26.597 28.678C26.597 29.4036 27.1851 29.9917 27.9107 29.9917H30.6738C31.3993 29.9917 31.9875 29.4036 31.9875 28.678C31.9875 24.5854 32.1155 23.7887 31.5559 22.3179C31.2374 21.4808 30.1915 21.22 29.497 21.7856Z" fill="#FFFFFF"/>
                                <path d="M25.3354 20.6713C27.5465 20.6713 29.339 18.8788 29.339 16.6677C29.339 14.4565 27.5465 12.6641 25.3354 12.6641C23.1243 12.6641 21.3318 14.4565 21.3318 16.6677C21.3318 18.8788 23.1243 20.6713 25.3354 20.6713Z" fill="#FFFFFF"/>
                                </g>
                                <defs>
                                <clipPath id="clip0">
                                <rect width="32" height="32" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>

                        <strong>Add User</strong>
                    </md-button>
                    <md-menu-content >
                        <md-menu-item style="margin-bottom:8px"  @click="showAddSingle">
                            <div class="menu_item_conttent">
                                <svg style="margin-right:8px" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.6662 7.30428H24.0216V8.65962C24.0216 9.38515 24.6097 9.97331 25.3353 9.97331C26.0608 9.97331 26.6489 9.38515 26.6489 8.65962V7.30428H28.0044C28.7299 7.30428 29.318 6.71612 29.318 5.99059C29.318 5.26506 28.7299 4.67691 28.0044 4.67691H26.6489V3.3215C26.6489 2.59597 26.0608 2.00781 25.3353 2.00781C24.6097 2.00781 24.0216 2.59597 24.0216 3.3215V4.67691H22.6662C21.9407 4.67691 21.3525 5.26506 21.3525 5.99059C21.3525 6.71612 21.9408 7.30428 22.6662 7.30428Z" fill="#0080F7"/>
                                    <path d="M15.9901 19.3357C19.6753 19.3357 22.6628 16.3482 22.6628 12.6629C22.6628 8.97771 19.6753 5.99023 15.9901 5.99023C12.3049 5.99023 9.31738 8.97771 9.31738 12.6629C9.31738 16.3482 12.3049 19.3357 15.9901 19.3357Z" fill="#0080F7"/>
                                    <path d="M27.9695 28.6778C27.9695 25.1564 26.4631 21.9796 24.0209 19.7844C23.4588 19.2791 22.6113 19.2597 22.0366 19.7508C18.5515 22.7289 13.4293 22.7297 9.9433 19.751C9.36947 19.2606 8.5212 19.2759 7.96101 19.7817C5.53169 21.976 4.00188 25.1481 4 28.6714C3.99962 29.3974 4.58766 29.9915 5.31369 29.9915H26.6559C27.3793 29.9915 27.9695 29.4054 27.9695 28.6778Z" fill="#0080F7"/>
                                </svg>
                                <span style="text-align: center;"> Add Single User</span>
                               
                            </div>
                        </md-menu-item>
                        <md-menu-item  @click="add_mul_active=true">
                            <div class="menu_item_conttent">
                                <svg style="margin-right:8px" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0)">
                                    <path d="M22.6662 7.30428H24.0216V8.65962C24.0216 9.38515 24.6097 9.97331 25.3353 9.97331C26.0608 9.97331 26.6489 9.38515 26.6489 8.65962V7.30428H28.0044C28.7299 7.30428 29.318 6.71612 29.318 5.99059C29.318 5.26506 28.7299 4.67691 28.0044 4.67691H26.6489V3.3215C26.6489 2.59597 26.0608 2.00781 25.3353 2.00781C24.6097 2.00781 24.0216 2.59597 24.0216 3.3215V4.67691H22.6662C21.9407 4.67691 21.3525 5.26506 21.3525 5.99059C21.3525 6.71612 21.9408 7.30428 22.6662 7.30428Z" fill="#0080F7"/>
                                    <path d="M11.9901 19.3357C15.6753 19.3357 18.6628 16.3482 18.6628 12.6629C18.6628 8.97771 15.6753 5.99023 11.9901 5.99023C8.30486 5.99023 5.31738 8.97771 5.31738 12.6629C5.31738 16.3482 8.30486 19.3357 11.9901 19.3357Z" fill="#0080F7"/>
                                    <path d="M23.9695 28.6778C23.9695 25.1564 22.4631 21.9796 20.0209 19.7844C19.4588 19.2791 18.6113 19.2597 18.0366 19.7508C14.5515 22.7289 9.42932 22.7297 5.9433 19.751C5.36947 19.2606 4.5212 19.2759 3.96101 19.7817C1.53169 21.976 0.00187687 25.1481 1.79583e-07 28.6714C-0.000375159 29.3974 0.587655 29.9915 1.31369 29.9915H22.6559C23.3793 29.9915 23.9695 29.4054 23.9695 28.6778Z" fill="#0080F7"/>
                                    <path d="M29.497 21.7856C28.77 22.3778 27.9147 22.8185 26.9801 23.059C26.2648 23.2431 25.8416 23.9789 26.0428 24.6896C26.6655 26.8887 26.597 28.739 26.597 28.678C26.597 29.4036 27.1851 29.9917 27.9107 29.9917H30.6738C31.3993 29.9917 31.9875 29.4036 31.9875 28.678C31.9875 24.5854 32.1155 23.7887 31.5559 22.3179C31.2374 21.4808 30.1915 21.22 29.497 21.7856Z" fill="#0080F7"/>
                                    <path d="M25.3354 20.6713C27.5465 20.6713 29.339 18.8788 29.339 16.6677C29.339 14.4565 27.5465 12.6641 25.3354 12.6641C23.1243 12.6641 21.3318 14.4565 21.3318 16.6677C21.3318 18.8788 23.1243 20.6713 25.3354 20.6713Z" fill="#0080F7"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0">
                                    <rect width="32" height="32" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                                <span>Add Multiple Users</span>
                            </div>
                        </md-menu-item>
                    </md-menu-content>
                </md-menu>
                  <export-excel
                    v-if='accessRight3'
                    type    = "csv"
                    name    = "ListUser.csv"
                    :fields="dataFieldsExport"
                    :before-generate="handleExport"
                    :data   = "totalUser" >
                  <md-button  class="md-raised User_btn btn_export">    
  
                    <svg style="margin-right:8px" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                        <path d="M23.75 4.87515L18.75 1.12514C18.5625 0.982639 18.3075 0.960139 18.0963 1.06639C17.8838 1.17264 17.75 1.38889 17.75 1.62514V3.50015H15.875C12.0838 3.50015 9 6.58391 9 10.3752C9 10.6652 9.2 10.9177 9.48125 10.9839C9.52875 10.9952 9.5775 11.0002 9.625 11.0002C9.8575 11.0002 10.0763 10.8702 10.1838 10.6552L10.3313 10.3614C11.29 8.44266 13.2175 7.25016 15.3625 7.25016H17.75V9.12516C17.75 9.36141 17.8838 9.57766 18.095 9.68391C18.3063 9.79016 18.5613 9.76766 18.75 9.62516L23.75 5.87515C23.9075 5.75765 24 5.5714 24 5.37515C24 5.1789 23.9075 4.99265 23.75 4.87515Z" fill="#0080F7"/>
                        <path d="M10.25 2L3.75 2C2.233 2 0.999998 3.233 0.999998 4.75L1 20.25C1 21.767 2.233 23 3.75 23L15.25 23C16.767 23 18 21.767 18 20.25L18 11.75C18 11.336 17.664 11 17.25 11C16.836 11 16.5 11.336 16.5 11.75L16.5 20.25C16.5 20.939 15.939 21.5 15.25 21.5L3.75 21.5C3.061 21.5 2.5 20.939 2.5 20.25L2.5 4.75C2.5 4.061 3.061 3.5 3.75 3.5L10.25 3.5C10.664 3.5 11 3.164 11 2.75C11 2.336 10.664 2 10.25 2Z" fill="#0080F7"/>
                        <path d="M13.25 19.5H5.75C5.336 19.5 5 19.164 5 18.75C5 18.336 5.336 18 5.75 18H13.25C13.664 18 14 18.336 14 18.75C14 19.164 13.664 19.5 13.25 19.5Z" fill="#0080F7"/>
                        <path d="M12.25 15.5H5.75C5.336 15.5 5 15.164 5 14.75C5 14.336 5.336 14 5.75 14H12.25C12.664 14 13 14.336 13 14.75C13 15.164 12.664 15.5 12.25 15.5Z" fill="#0080F7"/>
                        </g>
                        <defs>
                        <clipPath id="clip0">
                        <rect width="24" height="24" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                    <strong>Export User List</strong>
                  </md-button>
                </export-excel> 
               
            </div>
            
            <md-table v-model="users" md-card style="max-height:540px" >
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell class="User__user_cell" md-label="Full Name" md-sort-by="name">{{ item.name }}</md-table-cell>
                   <md-table-cell class="User__user_cell" md-label="Employee Number" md-sort-by="emNum">{{ item.emNum }}</md-table-cell>  
                   <md-table-cell class="User__user_cell" md-label="Company" md-sort-by="dept">{{ item.dept }}</md-table-cell>
                   <md-table-cell class="User__user_cell" md-label="Position" md-sort-by="position">{{ item.position }}</md-table-cell>
                   <md-table-cell class="User__user_cell" md-label="Email" md-sort-by="email">{{ item.email }}</md-table-cell>
                    <md-table-cell class="User__user_cell" md-label="User Login" md-sort-by="user">{{ item.username }}</md-table-cell>
                    
                    <md-table-cell class="User__role_cell" md-label="Role" md-sort-by="role">{{ item.role }}</md-table-cell>
                    <md-table-cell md-label="Action" class="User__edit_cell" md-sort-by="edit">

                        <svg class="edit" v-if="accessRight3"  @click="showEdit(item)" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                            <path d="M23.651 13.3364L22.31 11.9954C21.648 11.3354 20.572 11.3344 19.91 11.9954L12.538 19.3674C12.434 19.4714 12.362 19.6054 12.333 19.7504L11.663 23.1014C11.57 23.5674 11.926 23.9994 12.398 23.9994C12.447 23.9994 12.496 23.9944 12.545 23.9844L15.896 23.3144C16.041 23.2854 16.174 23.2144 16.279 23.1094L23.651 15.7374C24.313 15.0754 24.313 13.9994 23.651 13.3364Z" fill="#6D6E71"/>
                            <path d="M12.3979 15H4.89795C4.48395 15 4.14795 14.664 4.14795 14.25C4.14795 13.836 4.48395 13.5 4.89795 13.5H12.3979C12.8119 13.5 13.1479 13.836 13.1479 14.25C13.1479 14.664 12.8119 15 12.3979 15Z" fill="#6D6E71"/>
                            <path d="M11.3979 18H4.89795C4.48395 18 4.14795 17.664 4.14795 17.25C4.14795 16.836 4.48395 16.5 4.89795 16.5H11.3979C11.8119 16.5 12.1479 16.836 12.1479 17.25C12.1479 17.664 11.8119 18 11.3979 18Z" fill="#6D6E71"/>
                            <path d="M9.39795 21H2.89795C1.38095 21 0.147949 19.767 0.147949 18.25V2.75C0.147949 1.233 1.38095 0 2.89795 0H14.3979C15.9149 0 17.1479 1.233 17.1479 2.75V11.25C17.1479 11.664 16.8119 12 16.3979 12C15.9839 12 15.6479 11.664 15.6479 11.25V2.75C15.6479 2.061 15.0869 1.5 14.3979 1.5H2.89795C2.20895 1.5 1.64795 2.061 1.64795 2.75V18.25C1.64795 18.939 2.20895 19.5 2.89795 19.5H9.39795C9.81195 19.5 10.1479 19.836 10.1479 20.25C10.1479 20.664 9.81195 21 9.39795 21Z" fill="#6D6E71"/>
                            <path d="M8.64795 6.99999C9.75251 6.99999 10.6479 6.10456 10.6479 5C10.6479 3.89543 9.75251 3 8.64795 3C7.54338 3 6.64795 3.89543 6.64795 5C6.64795 6.10456 7.54338 6.99999 8.64795 6.99999Z" fill="#6D6E71"/>
                            <path d="M11.8979 12H5.39795C4.98395 12 4.64795 11.664 4.64795 11.25V10.75C4.64795 9.233 5.88095 8 7.39794 8H9.89794C11.4149 8 12.6479 9.233 12.6479 10.75V11.25C12.6479 11.664 12.3119 12 11.8979 12Z" fill="#6D6E71"/>
                            </g>
                            <defs>
                            <clipPath id="clip0">
                            <rect width="24" height="24" fill="white" transform="translate(0.147949)"/>
                            </clipPath>
                            </defs>
                        </svg>
                        
                        <svg class="remove"  v-if='accessRight2' @click="showRemove(item)" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.40058 1H8.97953H15.9269H16.5058V1.57895V4.47368H22.1795H22.7585V5.63158H22.1795H16.5058H15.3479H9.55848H8.40058H2.7269H2.14795V4.47368H2.7269H8.40058V1.57895V1ZM9.55848 4.47368H15.3479V2.15789H9.55848V4.47368ZM5.39005 7.13684V6.55789H4.23216V7.13684V21.0316C4.23216 22.1187 5.1135 23 6.20058 23H18.7058C19.7929 23 20.6743 22.1187 20.6743 21.0316V7.13684V6.55789H19.5164V7.13684V21.0316C19.5164 21.4792 19.1534 21.8421 18.7058 21.8421H6.20058C5.75299 21.8421 5.39005 21.4792 5.39005 21.0316V7.13684ZM13.0322 10.2632V9.68421H11.8743V10.2632V17.9053V18.4842H13.0322V17.9053V10.2632ZM9.55848 9.68421V10.2632V17.9053V18.4842H8.40058V17.9053V10.2632V9.68421H9.55848ZM16.5058 10.2632V9.68421H15.3479V10.2632V17.9053V18.4842H16.5058V17.9053V10.2632Z" fill="#6D6E71"/>
                        </svg>
                         
                    </md-table-cell>
                  
                    <!-- <md-table-cell v-if='accessRight2' md-label="Remove User" class="User__remove_cell" md-sort-by="remove" >
                        <svg @click="showRemove(item)" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.40058 1H8.97953H15.9269H16.5058V1.57895V4.47368H22.1795H22.7585V5.63158H22.1795H16.5058H15.3479H9.55848H8.40058H2.7269H2.14795V4.47368H2.7269H8.40058V1.57895V1ZM9.55848 4.47368H15.3479V2.15789H9.55848V4.47368ZM5.39005 7.13684V6.55789H4.23216V7.13684V21.0316C4.23216 22.1187 5.1135 23 6.20058 23H18.7058C19.7929 23 20.6743 22.1187 20.6743 21.0316V7.13684V6.55789H19.5164V7.13684V21.0316C19.5164 21.4792 19.1534 21.8421 18.7058 21.8421H6.20058C5.75299 21.8421 5.39005 21.4792 5.39005 21.0316V7.13684ZM13.0322 10.2632V9.68421H11.8743V10.2632V17.9053V18.4842H13.0322V17.9053V10.2632ZM9.55848 9.68421V10.2632V17.9053V18.4842H8.40058V17.9053V10.2632V9.68421H9.55848ZM16.5058 10.2632V9.68421H15.3479V10.2632V17.9053V18.4842H16.5058V17.9053V10.2632Z" fill="#6D6E71"/>
                        </svg>
                    </md-table-cell> -->
                    <md-table-cell md-label="Card" class="User__card_cell" md-sort-by="card">
                        <md-button class="md-raised btn_card" @click="showCreateCard(item)" v-if="!item.card">Create</md-button>
                        <div v-else class="card_link"><a href="#" @click.prevent="showCreateCard(item)" >{{item.card}}</a></div>
                    </md-table-cell>

                </md-table-row>
            </md-table>

            
        </div>
        <Pagination  class="table_pagination" v-if=' pagiShow'
          v-model="currentPage" 
          :page-count="totalPages"
          @change="onChange"
        />
        <md-dialog :md-active.sync="add_single_active" class="dialog_AddUser dialog_update">
            <div class="dialog_update__title">
                <div><strong>Add Single User</strong></div>
                <div @click="add_single_active=false"><md-icon class="icon_close ">clear</md-icon></div>
            </div>
            <form class="dialog_update__texts dialog_AddUser__texts" ref="addUserForm">
              <div class="dialog_update__text_1">
                  <input type="text"  class="input_text" placeholder="Full Name" v-model="tempUser.name" required>
                  <div class="label">
                      Full Name*
                  </div>
              </div>
              <div class="dialog_update__text_2">
                <div class="text_phone_1">
                  <input type="number" placeholder="Employee Number"  class="input_text" v-model="tempUser.emNum" required>
                  <div class="label">
                    Employee Number*
                  </div>
                </div>

                <div class="text_phone ">
                  <input type="text" placeholder="Company" class="input_text"  v-model="tempUser.dept">
                  <div class="label">
                    Company
                  </div>
                </div>
              </div>

              <div class="dialog_update__text_2">
                <div class="text_phone_1">
                  <input type="text" placeholder="Position" class="input_text" v-model="tempUser.position">
                  <div class="label">
                    Position
                  </div>
                </div>

                <div class="text_phone ">
                  <input type="email" placeholder="Email"  class="input_text"  v-model="tempUser.email">
                  <div class="label">
                    Email
                  </div>
                </div>
              </div>
              
              <div class="dialog_update__text_1">
                  <input type="text"  class="input_text" placeholder="User Login" v-model="tempUser.username" required>
                  <div class="label">
                      User Login*
                  </div>
              </div>
              <div class="dialog_update__text_1">
                  <input type="password"  class="input_text" placeholder="User Password" v-model="tempUser.password" minlength="8" required>
                  <div class="label">
                      User Password*
                  </div>
              </div>
              <div class="label" style="margin-top:4px;font-size:12px;margin-bottom:20px">
                  Password must at least 8 characters and contains at least one of the following: number, symbol, lower letter and upper letter.  
              </div>   
              <div class="dialog_AddUser__radios">
                  <div class="label" style="font-size:1rem">
                      Role
                  </div>
                  <div class="dialog_AddUser__radio">
                      <div class="radio_group">
                          <div>
                              <input type="radio" value="MASTER" v-model="tempUser.role" :disabled="showRoleMaster">
                              <label style="margin-left:10px" :style="{color:showRoleMaster?'#6D6E71':'#171A26'}" for="master">Master Administrator</label>
                          </div>
                          <div style="margin-top:8px">
                              <input type="radio"  value="OPERATOR" v-model="tempUser.role" :disabled="showRoleAdmin">
                              <label style="margin-left:10px" :style="{color:showRoleMaster?'#6D6E71':'#171A26'}" for="operator">Operator</label>
                          </div>
                      </div>
                      <div class="radio_group">
                          <div>
                              <input type="radio"  value="CONTRACTOR" v-model="tempUser.role">
                              <label style="margin-left:10px" for="contractor">Contractor</label>
                          </div>
                          <div  style="margin-top:8px">
                                <input type="radio" value="STAFF" v-model="tempUser.role">
                              <label style="margin-left:10px" for="staff">Staff</label>
                          </div>
                      </div>
                  </div>
              </div>             
            </form>
            <div class="dialog_update__btn">
                <md-button class="md-raised btn_cancel" @click="add_single_active = false">Cancel</md-button>
                <md-button class="md-raised md-primary btn_update" @click="handleAddSingle">Add</md-button>
            </div>
        </md-dialog>



        <md-dialog :md-active.sync="add_mul_active" class="dialog_AddMulUser dialog_update">
            <div class="dialog_update__title ">
                <div><strong>Add Multiple User</strong></div>
                <div @click="closeAddMulUser"><md-icon class="icon_close ">clear</md-icon></div>
            </div>
            <div class="label" style="cursor:alias">
                Upload a .csv file to add multiple users in once time
            </div>
            <div class="uploadFile_container">
                 <label for="file-upload" class="custom-file-upload">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.27197 5.46869V1H8.2218C8.0354 1 7.85651 1.07416 7.72467 1.20599L3.20599 5.72467C3.07416 5.85651 3 6.0354 3 6.2218V6.27197H7.46887C7.91162 6.27197 8.27197 5.91162 8.27197 5.46869Z" fill="#0080F7"/>
                        <path d="M18.7657 1H9.67822V5.0963C9.67822 6.21298 8.68707 7.12154 7.46887 7.12154H3V20.9748C3 22.0914 3.99115 23 5.20935 23H18.7657C19.9805 23 20.9751 22.0997 20.9751 20.9748V3.02524C20.9751 1.91174 19.9929 1 18.7657 1ZM15.4971 15.2171C15.2227 15.4689 14.7775 15.4691 14.5029 15.2171L12.6907 13.5561V18.2133C12.6907 18.5692 12.3759 18.8579 11.9875 18.8579C11.5992 18.8579 11.2844 18.5692 11.2844 18.2133V13.5561L9.47223 15.2171C9.19775 15.4689 8.75244 15.4689 8.47778 15.2171C8.20331 14.9655 8.20331 14.5575 8.47778 14.3057L11.4904 11.5443C11.7649 11.2925 12.2102 11.2925 12.4847 11.5443L15.4973 14.3057C15.7718 14.5575 15.7718 14.9655 15.4971 15.2171ZM15 9.88312H8.9751C8.58673 9.88312 8.27197 9.59442 8.27197 9.23859C8.27197 8.88258 8.58673 8.59406 8.9751 8.59406H15C15.3884 8.59406 15.7031 8.88258 15.7031 9.23859C15.7031 9.59442 15.3884 9.88312 15 9.88312Z" fill="#0080F7"/>
                    </svg>
                    Upload
                </label>
                <input id="file-upload" type="file" ref="file" @change="handleFileChange"/>
                <span  style="margin-right:10px">{{fileNameDisplay}}</span>
                <md-progress-spinner v-if="loadingFile" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
            </div>
              <export-excel
                class='label'
                type    = "csv"
                name    = "Template.csv"
                :fields="dataFields"
                :data   = "dataTem" >
                Download CSV template
              </export-excel>
            <div class="dialog_update__btn">
                <md-button class="md-raised btn_cancel" @click="closeAddMulUser">Cancel</md-button>
                <md-button class="md-raised md-primary btn_update" @click="handleFileUpload" :disabled="!fileIsUpload" >Add</md-button>
            </div>
        </md-dialog>
        <md-dialog :md-active.sync="remove_active" class="dialog_delete">
            <md-dialog-title class="dialog_delete__title">
            <div><strong>Remove User</strong></div>
            <div @click="remove_active=false"><md-icon class="icon_close">clear</md-icon></div>
            </md-dialog-title>
                <md-dialog-content class="dialog_delete___content">
                <span>Do you want to delete this item?</span>
                <br>
                <span>All related data will alse be deleted.</span>
            </md-dialog-content>
            <md-dialog-actions class="dialog_delete__button">
                <md-button class="md-raised btn_cancel" @click="remove_active = false">Cancel</md-button>
                <md-button class="md-raised md-accent btn_delete" @click="handleRemove">Remove</md-button>
            </md-dialog-actions>
        </md-dialog>
 


        <md-dialog :md-active.sync="edit_active" class="dialog_AddUser dialog_update" :class="{dialog_EditRoleOpe:!accessRight2}">
            <div class="dialog_update__title">
                <div><strong>Edit User</strong></div>
                <div @click="edit_active=false"><md-icon class="icon_close ">clear</md-icon></div>
            </div>
            <form class="dialog_AddUser__texts dialog_update__texts" ref="editUserForm">
               <div class="dialog_update__text_1">
                    <input type="text"  class="input_text" placeholder="Full Name" v-model="tempUser.name" required>
                    <div class="label">
                       Full Name*
                    </div>
                </div>
                <div class="dialog_update__text_2">
                  <div class="text_phone_1">
                    <input type="number"  class="input_text" v-model="tempUser.emNum" required>
                    <div class="label">
                      Employee Number*
                    </div>
                  </div>

                  <div class="text_phone ">
                    <input type="text" placeholder="Company" class="input_text"  v-model="tempUser.dept">
                    <div class="label">
                      Company
                    </div>
                  </div>
                </div>

                <div class="dialog_update__text_2">
                  <div class="text_phone_1">
                    <input type="text"  class="input_text" v-model="tempUser.position">
                    <div class="label">
                      Position
                    </div>
                  </div>

                  <div class="text_phone ">
                    <input type="email"  class="input_text"  v-model="tempUser.email">
                    <div class="label">
                      Email
                    </div>
                  </div>
                </div>
               
                <div class="dialog_update__text_1">
                    <input type="text"  class="input_text" placeholder="User Login" v-model="tempUser.username" required disabled>
                    <div class="label">
                       User Login*
                    </div>
                </div>
                <div class="dialog_update__text_1">
                    <input type="password"  class="input_text" placeholder="********" v-model.lazy='tempUser.editpassword' minlength="8">
                    <div class="label">
                        User Password*
                    </div>
                </div>
                <div class="label" style="margin-top:4px;font-size:12px;margin-bottom:20px">
                    Password must at least 8 characters and contains at least one of the following: number, symbol, lower letter and upper letter.  
                </div>   
                <div class="dialog_AddUser__radios" v-if="accessRight2">
                  <div class="label" style="font-size:1rem">
                      Role
                  </div>
                  <div class="dialog_AddUser__radio">
                      <div class="radio_group">
                          <div>
                              <input type="radio" value="MASTER"  v-model="tempUser.role" :disabled="showRoleMaster">
                              <label style="margin-left:10px" :style="{color:showRoleMaster?'#6D6E71':'#171A26'}" for="master">Master Administrator</label>
                          </div>
                          <div style="margin-top:8px">
                              <input type="radio"  value="OPERATOR" v-model="tempUser.role" :disabled="showRoleAdmin">
                              <label style="margin-left:10px" for="operator" :style="{color:showRoleMaster?'#6D6E71':'#171A26'}">Operator</label>
                          </div>
                      </div>
                      <div class="radio_group">
                          <div>
                              <input type="radio"  value="CONTRACTOR" v-model="tempUser.role">
                              <label style="margin-left:10px" for="contractor">Contractor</label>
                          </div>
                          <div  style="margin-top:8px">
                                <input type="radio" value="STAFF" v-model="tempUser.role">
                              <label style="margin-left:10px" for="staff">Staff</label>
                          </div>
                      </div>
                  </div>
              </div>             
            </form>
            <div class="dialog_update__btn">
                <md-button class="md-raised btn_cancel" @click="edit_active = false">Cancel</md-button>
                <md-button class="md-raised md-primary btn_update" @click="handleEdit">Save</md-button>
            </div>
        </md-dialog>

        <md-dialog :md-active.sync="create_card_active" class="dialog_CreatCard dialog_update">
            <div class="dialog_update__title">
                <div><strong>Create Card</strong></div>
                <div @click="create_card_active=false"><md-icon class="icon_close ">clear</md-icon></div>
            </div>
            <div class="dialog_AddUser__texts dialog_update__texts" v-if='accessRight3'>
                <div class="dialog_update__text_1">
                    <input type="text"  class="input_text" placeholder="Input Card Number" v-model="tempUser.card">
                    <div class="label">
                       Card Number
                    </div>
                </div>         
            </div>
            <div class="dialog_update__btn">
                <md-button class="md-raised btn_cancel" @click="create_card_active = false">Cancel</md-button>
                <md-button class="md-raised md-primary btn_update" @click="handleCreateCard">Create</md-button>
            </div>
        </md-dialog>

</div>
</template>
<script>
import request from '@/utils/request'
import {SERVER_HOSTNAME} from '@/utils/config'
import Pagination from '../../components/Pagination'
import getRoleLevel from '@/utils/getRoleLevel'
export default {
  name:'SiteUser',
  components: { Pagination },
  computed:{
    accessRight2(){
      let roleRight=['ROOT','CUSTOMER','MASTER','OPERATOR'];
      return roleRight.includes(this.$store.state.role)
    },
    accessRight3(){
      let roleRight=['ROOT','CUSTOMER','MASTER','OPERATOR','CONTRACTOR'];
      return roleRight.includes(this.$store.state.role)
    },
    pagiShow()
    {
      let roleRight=['ROOT','CUSTOMER','MASTER','OPERATOR','CONTRACTOR'];
      if(this.totalPages>0 && roleRight.includes(this.$store.state.role))
      {
        return true;
      }
      return false;
    },
    showRoleMaster()
    {
      return getRoleLevel(this.$store.state.role)<=getRoleLevel("MASTER")?true:false;
    },
    showRoleAdmin()
    {
      return getRoleLevel(this.$store.state.role)<=getRoleLevel("OPERATOR")?true:false;
    }
  },
  created() {
    // request('get', `/users?site=${this.siteId}`)
    //   .then(data => {
    //     this.users = data.users
    //     // console.log(this.users)
    //   })
    //   .catch(err => console.log(err))
    // console.log(this.accessRight3)
    if(this.accessRight3)
    {
      request('get',  `/users/${this.currentPage}?site=${this.siteId}`)
      .then(data => {
        this.users = data.users;
        this.totalPages=data.pages;
      })
      .catch(err => {
        console.log(err)
        // alert(err.message)
      })
    }
    
  },
  data(){
    return{
      currentPage: 1,
      totalPages:0,
      file:'',
      fileChoose:'',
      fileNameDisplay:'',
      loadingFile:false,
      fileIsUpload:false,
      siteId: this.$route.params.id,
      add_single_active:false,
      add_mul_active:false,
      remove_active:false,
      edit_active:false,
      create_card_active:false,
      list_site_show:false,
      site_selected:'',
      default_prefix:this.$route.params.id.substring(0,4)+'_',
      radio:'',
      searchUser:'',
      users:[],
      tempUser: {},
      totalUser:[],
      dataFields:{
        'Employee Number':'number',
        'Employee Card Number':'cardNum',
        'Name':'name',
        'Company':'dept',
        'Position':'position',
        'Cost Center':'costCenter',
        'Email':'email'
      },
      dataFieldsExport:{
        'Employee Number':'emNum',
        'Employee Card Number':'card',
        "User Login":'username',
        'Name':'name',
        'Company':'dept',
        'Position':'position',
        // 'Cost Center':'costCenter',
        'Email':'email',
      },
      dataTem:[
        {
          number:'',
          carNum:'',
          name:'',
          dept:'',
          position:'',
          costCenter:'',
          email:''
        }
      ],

    }
    
  },
  watch:{
    'tempUser.username':function(){
      if(this.add_single_active==true)
      {
        if(this.tempUser.username.length<this.default_prefix.length)
        {
          this.tempUser.username=this.default_prefix;
        }
        if(this.tempUser.username.indexOf(this.default_prefix)!==0)
        {
          this.tempUser.username=this.default_prefix;
        }
      }
      
     
    },
    searchUser:function(){
      // console.log(this.searchUser)
      if(this.searchUser.length>=3)
      {
        this.currentPage=1;
        
        request('post','/users/search/1',{site:this.siteId,field:this.searchUser})
        .then(data=>{
          // console.log(data)
          this.users=data.users
          this.totalPages=data.pages
          // this.totalPages=0;
        })
        .catch(err=>{
          console.log(err)
          alert(err.message)
        })
      }
      else if(this.searchUser.length===0)
      {
        this.currentPage=1;
        request('get',  `/users/${this.currentPage}?site=${this.siteId}`)
        .then(data => {
          this.users = data.users;
          this.totalPages=data.pages;
        })
        .catch(err =>{
          console.log(err)
          alert(err.message)
        })
      }
    }
  },
  methods:{
    onChange: function () {
      // console.log(this.currentPage)

      if(this.searchUser.length>0)
      {
        request('post',`/users/search/${this.currentPage}`,{site:this.siteId,type:this.searchType,field:this.searchUser})
          .then(data=>{
            // console.log(data)
            this.users=data.users
            this.totalPages=data.pages
            // this.totalPages=0;
          })
          .catch(err=>{
            console.log(err)
            alert(err.message)
          })
      }
      else
      {
         request('get',  `/users/${this.currentPage}?site=${this.siteId}`)
        .then(data => {
          this.users = data.users;
          this.totalPages=data.pages;
        })
        .catch(err => {
          console.log(err)
          alert(err.message)
        })
      }
      
    },
    handleFileChange(){
      if(this.$refs.file.files[0]!==null && this.$refs.file.files[0]!==undefined)
      {
        this.file=this.$refs.file.files[0];
        this.fileChoose=this.file.name;
        if(this.fileChoose.length>30)
        {
          this.fileNameDisplay= this.fileChoose.substring(0,30)
          this.fileNameDisplay+="..."
        }
        else
        {
          this.fileNameDisplay=this.fileChoose
        }
        this.fileIsUpload=true;
      }
      
    },
     async handleFileUpload(){
      if(!this.fileChoose.includes(".csv"))
      {
        return alert("Please upload a csv file to add multiple users!")
      }
      this.fileIsUpload=false;
      var myHeaders = new Headers();
      myHeaders.append("Authorization", 'Bearer ' + localStorage.drkToken);

      var formdata = new FormData();
      formdata.append("users", this.file);
      formdata.append("site", this.siteId);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      this.loadingFile=true;
      fetch(SERVER_HOSTNAME+"/users/import", requestOptions)
      .then(res=>{
          return res.json()
      })
      .then(data=>{
        if(data.message===null || data.message===undefined)
        {
          // console.log("data:",data)
          // data.users.forEach(el=>this.users.push(el))
          // this.add_mul_active=false;
          // this.file='';
          // this.fileChoose='';
          return  request('get',  `/users/${this.currentPage}?site=${this.siteId}`)
          
        }
        else
        {
          throw new Error(data.message)
        }
        
      })
      .then(dt=>{
        this.users = dt.users;
        this.totalPages=dt.pages;
        this.add_mul_active=false;
        this.file='';
        this.fileChoose='';
        this.fileNameDisplay=''
        this.loadingFile=false;
      })
      .catch(err=>{
        this.loadingFile=false;
        if(err.message!==null && err.message.includes("Employee Number"))
        {
          alert(err.message)
        }
      })

    },
    selectSite(el){
      this.site_selected=el;
      this.list_site_show=false;
    },
    showAddSingle() {
      this.tempUser = {
        
        name: '',
        username: '',
        password: '',
        role: 'STAFF',
        card: '',
        emNum:'',
        dept:'',
        position:'',
        email:''
      }
      this.add_single_active=true
    },
    showEdit(el) {
      this.tempUser = {
        ...el,
        password:'',
        editpassword:''
      }
      this.edit_active=true
    },
    showRemove(el) {
      this.tempUser = el
      this.remove_active=true
    },
    showCreateCard(el) {
      this.tempUser = {...el}
      this.create_card_active=true
    },
    closeAddMulUser(){
      if(this.loadingFile===false)
      {
        this.add_mul_active=false;
      }
    },
    handleAddSingle() {
      this.tempUser.site = this.siteId
      if(this.tempUser.role==="CONTRACTOR" || this.tempUser.role==="STAFF")
      {
        console.log("role staff,contac")
        if(this.tempUser.emNum.length===0)
        {
          return alert("Please fill employee number!")
        }
        if(this.tempUser.username.length===5)
        {
          this.tempUser.username=this.tempUser.username+this.tempUser.emNum;
        }
        if(this.tempUser.password.length<8)
        {
          this.tempUser.password="12345678s"
        }
      
        
        request('post', '/users', this.tempUser)
          // .then(data => {
          //   this.users.push(data.user)
          //   this.add_active = false
          //   this.add_single_active = false
          // })
        .then(()=>{
          this.add_single_active=false;
          return request('get',  `/users/${this.currentPage}?site=${this.siteId}`)
        })
        .then(data=>{
          this.users=data.users;
          this.totalPages=data.pages;
         
        })
        .catch(err => {
          if(err.message==="Forbidden")
          {
            return alert("No permission to add this role!")
          }
          alert("Username already exist.")
        })
      }
      else
      {

        if (this.$refs.addUserForm.reportValidity()) {
          request('post', '/users', this.tempUser)
            // .then(data => {
            //   this.users.push(data.user)
            //   this.add_active = false
            //   this.add_single_active = false
            // })
          .then(()=>{
            
            return request('get',  `/users/${this.currentPage}?site=${this.siteId}`)
          })
          .then(data=>{
            this.users=data.users;
            this.totalPages=data.pages;
            this.add_single_active=false;
          })
          .catch(err => {
            if(err.message==="Forbidden")
            {
              return alert("No permission to add this role!")
            }
            alert("Username already exist.")
          })
        }
      }
      
    },
    handleEdit() {
      this.tempUser.site = this.siteId
      // console.log(this.tempUser.editpassword)
      if(this.tempUser.editpassword.length>0 && this.tempUser.editpassword.length<8)
      {
        return alert("Password must at least 8 characters!")
      }
      // if(this.tempUser.editpassword.length>=8)
      // {
      //   this.tempUser.password=this.tempUser.editpassword;
      // }
      if (this.$refs.editUserForm.reportValidity()) {
        request('put', '/users', this.tempUser)
          .then(data => {
            for (let i = 0; i < this.users.length; i++) {
              if (this.users[i]._id == data.user._id) {
                this.users[i] = data.user
                break
              }
            }
            this.edit_active = false
          })
          .catch(err => {
            console.log(err)
            alert(err.message)
          })
      }
    },
    handleRemove() {
      request('delete', `/users/${this.tempUser._id}`)
        .then(() => {
          // for (let i = 0; i < this.users.length; i++) {
          //   if (this.users[i]._id == this.tempUser._id) {
          //     this.users.splice(i, 1)
          //     break
          //   }
          // }
          if(this.users.length===1 && this.currentPage>1)
          {
            this.currentPage=this.currentPage-1;
          }
          return request('get',  `/users/${this.currentPage}?site=${this.siteId}`)
        })
        .then(data=>{
          this.users=data.users;
          this.totalPages=data.pages;
          this.remove_active=false;
        })
        .catch(err => {
          alert(err.message)
        })
    },
    handleCreateCard() {
      this.tempUser.site = this.siteId

      request('put', '/users', this.tempUser)
        .then(data => {
          for (let i = 0; i < this.users.length; i++) {
            if (this.users[i]._id == data.user._id) {
              this.users[i] = data.user
              break
            }
          }
          this.create_card_active = false
        })
        .catch(err => {
          console.log(err)
          alert(err.message)
        })
    },
    handleExport:async function(){
      this.totalUser=[];
      try{
        let data = await request('get',`/users?site=${this.siteId}`)
        this.totalUser=data.users;
      }
      catch(err){
        console.log(err)
        alert("Failed to export data!")
      }
      
      // .then(data=>{
      //   console.log(data)
      //   this.totalUser=data.users;
      // })
      // .catch(err=>{
      //   console.log(err)
      //   alert(err.message)
      // })
    }
  },
}
</script>
<style lang="scss" >
 .siteUser_container{
     width: 100%;
     height: 100%;
     display: flex;
     justify-content: center;
     flex-direction: column;
    .main_table{
        align-items:flex-end;
        .md-table-cell{
            height: 56px !important;
        }
        .User__user_cell{
            width: 340px;
        }
        .User__edit_cell{
            min-width:140px;
            max-width: 140px;
            svg.edit{
              margin-top:5px;
              margin-right: 20px;
            }
            svg.edit:hover path{
                fill: #0080F6;
            }
            svg.remove:hover path{
                fill: #ED1D24;
            }
        }
        // .User__remove_cell{
        //     width:168px;
        //      svg:hover path{
        //         fill: #ED1D24;
        //     }
        // }
        .User__role_cell{
            width:337px;
        }
        .btn_card{
            width: 120px;
            height: 32px;
            color:#0080F7;
            border: 1px solid #0080F7;
            border-radius: 4px;
            text-transform: none; 
            .md-button-content{
                color: #0080F7;
                font-size: 0.8rem;
                font-weight: bold;
            }
        }
        .User__btn_container{
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-start;
          width: 100%;
          position: relative;
          .dialog_update__selects{
            position: absolute;
            left: 0px;
            top:6px;
            .input_select{
              color: black;
              font-size: 1rem;
              height: 48px;
              width: 365px;
              background-color:white;
              border-width: 0px;
              border-radius: 2px;
              padding: 8px;
              padding-right: 40px;
            }
            .input_select:hover,:focus{
              outline: none !important;
              border:1px solid #0080F6 !important ;
            }
            .dialog_update__select{
              display: flex;
              flex-direction: column-reverse;
              position: relative;
              .icon{
                position: absolute;
                right: 0px;
                top:12px;
                z-index: 2;
              }

            }

          }
            .User_btn{
                border-radius: 4px;
                font-size: 1rem;
                height: 48px !important;
                margin-bottom: 26px;
                margin-right: 0px;
                border-radius: 4px;
                text-transform: none; 
                 .md-button-content{
                     display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .btn_export{
                width: 199px;
                background: white;
                border: 2px solid #0080F7;
                color: #0080F7 !important;
               
               
            }
            .User_menu{
                border-radius: 4px;
                font-size: 1rem;
                // margin-bottom: 26px;
                padding: 0px;
                margin-right: 0px;
                margin-right: 24px;
                margin-top:5px;
                 text-transform: none; 
                .md-button-content{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                     text-transform: none; 
                }
                .btn_add{
                    height: 48px;
                    width: 159px;
                    border-radius: 4px;
                    margin-bottom: 5px;
                }
               
            }


        }
    }
    
 }
 .dialog_EditRoleOpe{
    .md-dialog-container
    {
      min-height: 650px !important;
      max-height: 650px !important ;
      width: 516px ;
    }
 }
 .dialog_AddUser{
   .md-dialog-container
    {
      max-height: 100vh;
      width: 516px ;
    }
    .dialog_AddUser__texts{
      height: 630px;
      max-height: calc(100% - 300px);
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 80px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 0px;
      padding: 0;
      .dialog_AddUser__radios{
          display: flex;
          flex-direction: column;
          height: 87px;
          width: 100%;
          .label{
              margin-bottom: 12px;
          }
          .dialog_AddUser__radio{
            padding-left: 4px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .radio_group{
                width: 50%;
                display: flex;
                flex-direction: column;
                
            }


        }
      }
    }
 }
.dialog_AddMulUser{
   .md-dialog-container
    {
      min-height: 303px;
      max-height: 303px;
      width: 516px ;
    }
    .label{
        font-size: 0.8rem;
        color:#6D6E71;
        margin-top: 8px;
        cursor: pointer;
    }
    .label:hover{
      color:#0080F6;
    }
     .uploadFile_container{
         margin-top: 29px;
         display: flex;
         flex-direction: row;
         align-items: center;
        .custom-file-upload{
            width: 136px;
            height: 48px;
            border: 2px solid #0080F7;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 1rem;
            color: #0080F6;
            font-weight: bold;
        }
        .custom-file-upload:hover{
            background-color:#ECECEC;
        }
        input[type="file"] {
            display: none;
        }
        span{
            color: #0080F6;
            text-decoration: underline;
            margin-left: 40px;
        }
     }
     .uploadFile_container+div{
         font-size: 0.75rem;
         text-decoration: underline;
     }
 }
.dialog_CreatCard{
    .md-dialog-container
    {
      min-height: 268px;
      max-height: 268px;
      width: 516px ;
    }
    .dialog_AddUser__texts{
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 0px;
      padding: 0;
    }
}
.menu_item_conttent{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    
}
input[type="radio"] {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
    transform: scale(1.5);
}
.card_link a{
  color:black !important;
}
.card_link a:link{
  text-decoration: none !important;
}
.card_link a:hover{
  text-decoration: underline !important;
  color:#0080F6 !important;
  cursor: pointer;
}
</style>