export default (role)=>{
    let level = 0;
    switch(role){
        case "STAFF":
            level=0;
            break;
        case "CONTRACTOR":
            level=1;
            break;
        case "ADMIN":
            level=2;
            break;
        case "MASTER":
            level=3;
            break;
        case "CUSTOMER":
            level=4;
            break;
        case "ROOT":
            level=5;
            break;
        default:
            break
    }
    return level;
 }