<template>
    <div class="main_footer md-elevation-2">
        <div class="main_footer__title" >Powered by DrK System</div>
        <div>Version 1.00</div>
    </div>
</template>
<script>
export default {
   name:"Footer" 
}
</script>
<style lang="scss" scoped>
.main_footer{     
    display: flex;
    flex-direction: row;
    z-index: 5;
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 50px;
    border: 1px solid #ECECEF;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    .main_footer__title{
        margin-left: 27px;
 
    }
    .main_footer__title+div{
        margin-right: 32px;
    }
}
</style>