<template>
    <div class="customer_home customersite_container">
        <div class="main_table">
        <md-button class="md-raised md-primary btn_customer" @click="showAdd"> <md-icon class="md-size-2x">add</md-icon>Add Site</md-button>
            <md-table v-model="sites" md-card style="max-height:540px">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell class="site_cell" md-label="Site Name" md-sort-by="name">{{ item.name }}</md-table-cell>
                <md-table-cell class="systems_cell1" md-label="System " md-sort-by="name">{{ item.system ? systemName(item.system._id || item.system ) : '' }}</md-table-cell>
                <md-table-cell md-label="Description" class="description_cell1" md-sort-by="description">{{ item.description }}</md-table-cell>
                <md-table-cell md-label="Status" class="status_cell1" md-sort-by="status"><div :style="{color:item.active?'green':'red'}">{{item.active ? 'Active' : 'Inactive'}}</div></md-table-cell>
                <md-table-cell md-label="" md-sort-by="func" >
                    <md-menu md-size="medium" md-align-trigger>
                    <md-button md-menu-trigger><md-icon>more_horiz</md-icon></md-button>
                    <md-menu-content>
                        <md-menu-item @click="showUpdate(item)">Update</md-menu-item>
                        <md-menu-item @click="showDelete(item)"><div style="color:red">Delete</div></md-menu-item>
                    </md-menu-content>
                    </md-menu>
                </md-table-cell>
                </md-table-row>
            </md-table>
        </div>
      
    <md-dialog :md-active.sync="delete_active" class="dialog_delete">
        <md-dialog-title class="dialog_delete__title">
          <div><strong>Delete Site</strong></div>
          <div @click="delete_active=false"><md-icon class="icon_close">clear</md-icon></div>
        </md-dialog-title>
        <md-dialog-content class="dialog_delete___content">
          <span>Do you want to delete this site?</span>
          <br>
          <span>All related data will alse be deleted.</span>
        </md-dialog-content>
        <md-dialog-actions class="dialog_delete__button">
          <md-button class="md-raised btn_cancel" @click="delete_active = false">Cancel</md-button>
          <md-button class="md-raised md-accent btn_delete" @click="handleDeleteSite">Delete</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="update_active" class="dialog_updateSite">
      <div class="dialog_update__title">
        <div><strong>Update Site</strong></div>
        <div @click="update_active=false"><md-icon class="icon_close ">clear</md-icon></div>
      </div>
      <form class="dialog_update__texts" ref="updateSiteForm">
        <div class="dialog_update__text_1">
          <input type="text"  class="input_text" v-model="tempSite.name" required>
          <div class="label">
            Site Name*
          </div>
        </div>
        <div class="dialog_update__text_2">
          <div class="label">Access Key</div>
          <div class="input_token">
            <input type="text" disabled  class="input_text" v-model="tempSite.accessKey">
            <div  class="show_icon" @click="generateAccessKey"><md-icon style="color:#0080F6" class="icon" >cached</md-icon></div>
          </div>
          
        </div>
        <div class="dialog_update__selects">
          <div class="dialog_update__select">
            <div class="system_lists" v-if="list_systems_show">
              <li v-for="item in systems" :key="item._id" @click="selectSystem(item)">
                <div >{{item.name}}</div>
              </li>
            </div>
            <md-icon class="icon">keyboard_arrow_down</md-icon>
            <div @click="list_systems_show=!list_systems_show"><input type="text" class="input_select" placeholder="System" disabled :value="system_selected ? system_selected.name : null" required></div>
            <div class="label" :style="{color:list_systems_show?'#0080F6':'#6D6E71'}">
              System*
            </div>
          </div>
        </div>
        <div class="dialog_update_logos">
           <div class="dialog_update_logo">
             <div class="label" style="margin-bottom:7px">Origin</div>
             <div class="image" :style="`background-image: url(${tempSite.originalImage});`"><md-icon style="color:#D7D7D7;" v-if="!tempSite.originalImage">camera_alt</md-icon></div>
             <label>
               <input type="file" @change="addSiteChangeImage($event, 'originalImage')" />
               Add file
             </label>
           </div>
           <div class="dialog_update_logo">
             <div class="label" style="margin-bottom:7px">Image(75x75)</div>
             <div class="image" :style="`background-image: url(${tempSite.smallImage});`"><md-icon style="color:#D7D7D7;" v-if="!tempSite.smallImage">camera_alt</md-icon></div>
             <label>
               <input type="file" @change="addSiteChangeImage($event, 'smallImage')" />
               Add file
             </label>
           </div>
           <div class="dialog_update_logo">
             <div class="label" style="margin-bottom:7px">Image(181x178)</div>
             <div class="image" :style="`background-image: url(${tempSite.mediumImage});`"><md-icon style="color:#D7D7D7;" v-if="!tempSite.mediumImage">camera_alt</md-icon></div>
             <label>
               <input type="file" @change="addSiteChangeImage($event, 'mediumImage')" />
               Add file
             </label>
           </div>
        </div>
        <div class="dialog_update_radios">
            <div class="label">
                Status
            </div>
            <div class="dialog_update_radio">
                <md-radio v-model="tempSite.active" class="md-primary" :value="true">Active</md-radio>
                <md-radio v-model="tempSite.active" class="md-primary" :value="false">Inactive</md-radio>
            </div>
        </div>
        <div class="dialog_update__text_1 dialog_descript">
          <input type="text"  class="input_text" v-model="tempSite.description">
          <div class="label">
            Description
          </div>
        </div>
      </form>
     
    
      <div class="dialog_update__btn">
         <md-button class="md-raised btn_cancel" @click="update_active = false">Cancel</md-button>
         <md-button class="md-raised md-primary btn_update" @click="handleUpdateSite">Update</md-button>
      </div>
    </md-dialog>
    <md-dialog :md-active.sync="add_active" class="dialog_updateSite">
      <div class="dialog_update__title">
        <div><strong>Add Site</strong></div>
        <div @click="add_active=false"><md-icon class="icon_close ">clear</md-icon></div>
      </div>
      <form class="dialog_update__texts" ref="addSiteForm">
        <div class="dialog_update__text_1">
          <input type="text"  class="input_text" v-model="tempSite.name" required>
          <div class="label">
            Site Name*
          </div>
        </div>
        <div class="dialog_update__text_2">
          <div class="label">Access Key</div>
          <div class="input_token">
            <input type="text" disabled  class="input_text" v-model="tempSite.accessKey">
            <div  class="show_icon" @click="generateAccessKey"><md-icon style="color:#0080F6" class="icon" >cached</md-icon></div>
          </div>
          
        </div>
        <div class="dialog_update__selects">
          <div class="dialog_update__select">
            <div class="system_lists" v-if="list_systems_show">
              <li v-for="item in systems" :key="item._id" @click="selectSystem(item)">
                <div >{{item.name}}</div>
              </li>
            </div>
            <md-icon class="icon">keyboard_arrow_down</md-icon>
            <div @click="list_systems_show=!list_systems_show"><input type="text" class="input_select" placeholder="System" disabled :value="system_selected ? system_selected.name : null" required></div>
            <div class="label" :style="{color:list_systems_show?'#0080F6':'#6D6E71'}">
              System*
            </div>
          </div>
        </div>
        <div class="dialog_update_logos">
           <div class="dialog_update_logo">
             <div class="label" style="margin-bottom:7px">Origin</div>
             <div class="image" :style="`background-image: url(${tempSite.originalImage});`"><md-icon style="color:#D7D7D7;" v-if="!tempSite.originalImage">camera_alt</md-icon></div>
             <label>
               <input type="file" @change="addSiteChangeImage($event, 'originalImage')" />
               Add file
             </label>
           </div>
           <div class="dialog_update_logo">
             <div class="label" style="margin-bottom:7px">Image(75x75)</div>
             <div class="image" :style="`background-image: url(${tempSite.smallImage});`"><md-icon style="color:#D7D7D7;" v-if="!tempSite.smallImage">camera_alt</md-icon></div>
             <label>
               <input type="file" @change="addSiteChangeImage($event, 'smallImage')" />
               Add file
             </label>
           </div>
           <div class="dialog_update_logo">
             <div class="label" style="margin-bottom:7px">Image(181x178)</div>
             <div class="image" :style="`background-image: url(${tempSite.mediumImage});`"><md-icon style="color:#D7D7D7;" v-if="!tempSite.mediumImage">camera_alt</md-icon></div>
             <label>
               <input type="file" @change="addSiteChangeImage($event, 'mediumImage')" />
               Add file
             </label>
           </div>
        </div>
        <div class="dialog_update_radios">
            <div class="label">
                Status
            </div>
            <div class="dialog_update_radio">
                <md-radio v-model="tempSite.active" class="md-primary" :value="true">Active</md-radio>
                <md-radio v-model="tempSite.active" class="md-primary" :value="false">Inactive</md-radio>
            </div>
        </div>
        <div class="dialog_update__text_1 dialog_descript">
          <input type="text"  class="input_text" v-model="tempSite.description">
          <div class="label">
            Description
          </div>
        </div>
      </form>
     
    
      <div class="dialog_update__btn">
         <md-button class="md-raised btn_cancel" @click="add_active = false">Cancel</md-button>
         <md-button class="md-raised md-primary btn_update" @click="handleAddSite">Add</md-button>
      </div>
    </md-dialog>
</div>
</template>
<script>
import request from '@/utils/request'
import resize from '@/utils/resize'

export default {
  name:'CustomerSite',
  created() {
    request('get', '/sites')
      .then(data => {
        this.sites = data.sites
      })
      .catch(err => console.log(err))

    request('get', '/systems')
      .then(data => {
        this.systems = data.systems
      })
      .catch(err => console.log(err))
  },
  data(){
    return{
      update_active:false,
      delete_active:false,
      add_active:false,
      list_systems_show:false,
      radio:'',
      sites: [],
      systems:[],
      system_selected:'',
      tempSite: {}
    }
    
  },
  methods:{
    showAdd() {
      this.tempSite = {
        name: '',
        description: '',
        active: true,
        accessKey: '',
        originalImage: '',
        smallImage: '',
        mediumImage: '',
        customer: null,
        system: null,
      },
      this.generateAccessKey()
      this.add_active=true
    },
    showUpdate(el){
      this.tempSite = {
        ...el
      }
      this.system_selected = el.system
      this.update_active=true;
    },
    showDelete(el){
      this.tempSite = el
      this.delete_active=true;
    },
    addSiteChangeImage(e, key) {
      let size
      let file = e.target.files[0]

      if (key == 'smallImage') {
        size = 75
      } else if (key == 'mediumImage') {
        size = 181
      } else {
        size = 512
      }

      if(file.type.match(/image.*/)) {
        resize(file, size)
          .then(dataUrl => {
            this.tempSite[key] = dataUrl
          })

      }
    },
    handleDeleteSite(){
      request('delete', `/sites/${this.tempSite._id}`)
        .then(() => {
          for (let i = 0; i < this.sites.length; i++) {
            if (this.sites[i]._id == this.tempSite._id) {
              this.sites.splice(i, 1)
              break
            }
          }
          this.delete_active=false;
        })
        .catch(err => {
          console.log(err)
          alert('Cannot delete this site because it’s using the data.')
        })
    },
    handleUpdateSite(){
      if (this.system_selected) {
        this.tempSite.system = this.system_selected._id
      } else {
        this.tempSite.system = null
      }
      if (this.$refs.updateSiteForm.reportValidity()) {
        request('put', '/sites', this.tempSite)
          .then(data => {
            for (let i = 0; i < this.systems.length; i++) {
              if (data.site.system == this.systems[i]._id) {
                data.site.system = this.systems[i]
                break
              }
            }

            for (let i = 0; i < this.sites.length; i++) {
              if (this.sites[i]._id == data.site._id) {
                this.sites[i] = data.site
                break
              }
            }
            this.system_selected='';
            this.update_active=false
          })
          .catch(err => {
            console.log(err)
            alert(err.message)
          })
      }
    },
    handleAddSite(){
      if (this.system_selected) {
        this.tempSite.system = this.system_selected._id
      }
      if (this.$refs.addSiteForm.reportValidity()) {
        request('post', '/sites', this.tempSite)
          .then(data => {
            this.sites.push(data.site)
            this.add_active = false
            this.system_selected=''
          })
          .catch(err => {
            console.log(err)
            alert(err.message)
          })
      }

    },
    selectSystem(el){
      this.system_selected=el;
      this.list_systems_show=false;
    },
    generateAccessKey() {
      const LENGTH = 24
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < LENGTH; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      this.tempSite.accessKey = result
    },
    systemName(systemId) {
      for (let i = 0; i < this.systems.length; i++) {
        if (this.systems[i]._id == systemId) {
          return this.systems[i].name
        }
      }
      return ''
    }
  },
}
</script>
<style lang="scss" >
 .customersite_container{
    .main_table{
        align-items:flex-end;
        .md-table-cell{
            height: 56px !important;
        }
        .site_cell{
            width: 314px;
        }
        .systems_cell1{
            width:305px;
        }
        .description_cell1{
            width: 450px;
        }
        .status_cell1{
            width: 199px;
        }
    }
    
 }
 .dialog_updateSite{
   .md-dialog-container
    {
     max-height: 100vh;
      width: 516px ;
      padding: 22px 32px 32px 38px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
    }
    .dialog_update__title{
      
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      div{
        font-size: 1.4rem;
      }
      .icon_close:hover{
        color: red !important;
      }
      
    }
    .dialog_update__texts{
      max-height: calc(100% - 300px);
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 80px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 0px;
      padding: 0;
      .label{
        font-size: 0.8rem;
        color:#6D6E71;
        margin-bottom: 5px;
      }
      .input_text{
        color: black;
        font-size: 1rem;
        height: 48px;
        background-color: #ECECEC;
        border-width: 0px;
        border-radius: 2px;
        padding: 8px;
      }
      .input_text:focus{
        outline: none !important;
        border:1px solid #0080F6 !important ;
      }
      .input_text:focus + div {
        color:#0080F6;
      }
      .dialog_update__text_logo{
        display: flex;
        flex-direction: column;
        .image_logo{
          display: flex;
          flex-direction: row;
          align-items: flex-end;
        }
        img{
          width: 120px;
          height: 70px;
          background-color: #D7D7D7;
        }
        input[type="file"] {
          display: none;
        }
        label{
            cursor: pointer;
            text-decoration: underline;
            color: #0080F7;
            font-size: 0.8rem;
            margin-left: 17px;

        }
      }
      .dialog_update__text_1{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 25px;
      }
      .dialog_update__text_2{
        margin-top:25px ;
        display: flex;
        flex-direction: column;
        .input_token{
          position: relative;
          display:flex;
          align-items: center;
          input{
            width: 100%;
            z-index: 0;
          }
         
          .show_icon{
            position: absolute;
            right:14px ;
            margin: auto;
            z-index: 100;
            cursor: pointer;
          }
        }
      }
      .dialog_update__selects{
        margin-top: 25px;
        .input_select{
          color: black;
          font-size: 1rem;
          height: 48px;
          width: 100%;
          background-color: #ECECEC;
          border-width: 0px;
          border-radius: 2px;
          padding: 8px;
        }
        .input_select:hover{
          outline: none !important;
          border:1px solid #0080F6 !important ;
        }
        .dialog_update__select{
          display: flex;
          flex-direction: column-reverse;
          position: relative;
          .icon{
            position: absolute;
            left: 412px;
            top:36px;
          }
          .system_lists{
            position: absolute;
            top:75px;
            width: 100%;
            background-color: white;
            // margin-top:5px;
            list-style-type: none;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            z-index: 15;
            li{
              height: 35px;
              display: flex;
              align-items:center;
              justify-content: space-between;
              font-size: 1rem;
              div{
                margin-left: 19px;
              }

            }
            li:hover{
              background-color: #CFECF9;
            }
          }
        }

      }
      .dialog_update_logos{
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .dialog_update_logo{
          display: flex;
          flex-direction:column;
          align-items: flex-start;
          margin-right:24px;
          .image{
            background-position: center;
            background-size: cover;
            background-color: #ECECEC;
            width: 120px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            margin-bottom:7px
          }
          input[type="file"] {
            display: none;
          }
          label{
            cursor: pointer;
            text-decoration: underline;
            color: #0080F7;
            font-size: 0.8rem;

          }
        }
      }
      .dialog_update_radios{
          margin-top: 24px;
          .md-primary{
              margin-right: 58px;
          }
      }
      .dialog_descript{
        margin-top: 0px;
      }
    }
    
    .dialog_update__btn{
      height: 50px;
      margin-top: 13px;
      padding: 0px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      position: absolute;
      bottom: 32px;
      width: 446px;
       .btn_update{
        width: 164px;
        height: 50px;
        text-transform: none;
        font-weight: bold;
        border-radius: 4px;
        margin-right: 0px;
      }
      .btn_cancel{
        width: 100px;
        height: 50px;
        text-transform: none;
        font-weight: bold;
        border-radius: 4px;
        margin-right: 14px;
      }
    }
 }

</style>