<template>
    <div class="customer_home siteRoomShelf_container">
      <div class="main_table">
        <div class="Room__btn_container" v-if="accessRight3">
            <md-button class="md-raised md-primary Room_btn btn_room" @click="showAddRoom"> 
                <svg style="margin-right:8px" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.3333 24C19.9654 24 19.6667 23.7012 19.6667 23.3334V1.33331H4.33331V23.3333C4.33331 23.7012 4.03465 23.9999 3.66665 23.9999C3.29866 23.9999 3 23.7011 3 23.3333V0.666653C3 0.298658 3.29866 0 3.66665 0H20.3333C20.7011 0 20.9999 0.298658 20.9999 0.666653V23.3333C21 23.7012 20.7011 24 20.3333 24ZM18.8333 2.50002V23.3333H5.16666V2.50002H18.8333ZM8.49998 13.0833C8.49998 12.4851 8.08964 12 7.5833 12C7.07696 12 6.66662 12.4851 6.66662 13.0833C6.66662 13.6816 7.07696 14.1667 7.5833 14.1667C8.08964 14.1667 8.49998 13.6816 8.49998 13.0833Z" fill="white"/>
                </svg>

                <strong>Create Location</strong>
            </md-button>
            <md-button class="md-raised  md-primary Room_btn btn_shelf" @click="showAddShelf">       
                <svg style="margin-right:8px" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.4609 0H12.7031V2.15091H11.2969V0H9.53906V5.454H14.4609V0Z" fill="white"/>
                    <path d="M20.7891 0H19.0312V2.15091H17.625V0H15.8672V5.454H20.7891V0Z" fill="white"/>
                    <path d="M8.13281 0H6.375V2.15091H4.96875V0H3.21094V5.454H8.13281V0Z" fill="white"/>
                    <path d="M14.4609 12H12.7031V14.1509H11.2969V12H9.53906V17.454H14.4609V12Z" fill="white"/>
                    <path d="M20.7891 12H19.0312V14.1509H17.625V12H15.8672V17.454H20.7891V12Z" fill="white"/>
                    <path d="M8.13281 12H6.375V14.1509H4.96875V12H3.21094V17.454H8.13281V12Z" fill="white"/>
                    <path d="M22.1953 3.09375V6.86025H1.80469V3.09375H0.046875V24H1.80469V21.4493H22.1953V24H23.9531V3.09375H22.1953ZM22.1953 18.8602H1.80469V9.4493H22.1953V18.8602Z" fill="white"/>
                </svg>

                <strong>Create Shelf</strong>
            </md-button>
        </div>
        <div class="Room_container">
          <div class="room-wrapper" v-for="(room, index) in rooms" :key="index">
            <room-content :roomInfo="room" :listShelf="room.shelves" @update="showUpdateRoom(room)" @delete="showDeleteRoom(room)" @updateShelf="showUpdateShelf(room.shelves[$event])" @deleteShelf="showDeleteShelf(room.shelves[$event])" />
          </div>
        </div>
      </div>

      <md-dialog :md-active.sync="room_add_active" class="dialog_updateRoom dialog_update">
          <div class="dialog_update__title">
              <div><strong>Create Location</strong></div>
              <div @click="room_add_active=false"><md-icon class="icon_close ">clear</md-icon></div>
          </div>
          <form class="dialog_update__texts dialog_updateRoom_texts" ref="addRoomForm">
              <div class="dialog_update__text_1">
                  <input type="text"  class="input_text" placeholder="Location Name" v-model="tempRoom.name" required>
                  <div class="label">
                    Location Name*
                  </div>
              </div>

              <div class="dialog_update__text_1 ">
                  <textarea  rows="3"  class="input_text area_text" placeholder="Description" v-model="tempRoom.description"></textarea>
                  <div class="label">
                      Description
                  </div>
              </div>  
          </form>      
          <div class="dialog_update__btn" >
              <md-button class="md-raised btn_cancel" @click="room_add_active = false">Cancel</md-button>
              <md-button class="md-raised md-primary btn_update" @click="handleAddRoom">Create</md-button>
          </div>
      </md-dialog>

      <md-dialog :md-active.sync="room_update_active" class="dialog_updateRoom dialog_update">
          <div class="dialog_update__title">
              <div><strong>Update Location</strong></div>
              <div @click="room_update_active=false"><md-icon class="icon_close ">clear</md-icon></div>
          </div>
          <form class="dialog_update__texts dialog_updateRoom_texts" ref="updateRoomForm">
              <div class="dialog_update__text_1">
                  <input type="text"  class="input_text" placeholder="Location Name" v-model="tempRoom.name" required>
                  <div class="label">
                    Location Name*
                  </div>
              </div>

              <div class="dialog_update__text_1 ">
                  <textarea  rows="3"  class="input_text area_text" placeholder="Description" v-model="tempRoom.description"></textarea>
                  <div class="label">
                      Description
                  </div>
              </div>  
          </form>      
          <div class="dialog_update__btn" >
              <md-button class="md-raised btn_cancel" @click="room_update_active = false">Cancel</md-button>
              <md-button class="md-raised md-primary btn_update" @click="handleUpdateRoom">Update</md-button>
          </div>
      </md-dialog>

      <md-dialog :md-active.sync="room_delete_active" class="dialog_delete">
          <md-dialog-title class="dialog_delete__title">
            <div><strong>Delete Location</strong></div>
            <div @click="room_delete_active=false"><md-icon class="icon_close">clear</md-icon></div>
          </md-dialog-title>
          <md-dialog-content class="dialog_delete___content">
            <span>Do you want to delete this location?</span>
            <br>
            <span>All related data will alse be deleted.</span>
          </md-dialog-content>
          <md-dialog-actions class="dialog_delete__button">
            <md-button class="md-raised btn_cancel" @click="room_delete_active = false">Cancel</md-button>
            <md-button class="md-raised md-accent btn_delete" @click="handleDeleteRoom">Delete</md-button>
        </md-dialog-actions>
      </md-dialog>

       <md-dialog :md-active.sync="shelf_add_active" class="dialog_updateShelf dialog_update">
        <div class="dialog_update__title">
            <div><strong>Create Shelf</strong></div>
            <div @click="shelf_add_active=false"><md-icon class="icon_close ">clear</md-icon></div>
        </div>
        <form class="dialog_update__texts dialog_updateShelf_texts" ref="addShelfForm">
            <div class="dialog_update__text_1">
                <input type="text"  class="input_text" placeholder="Shelf Name" v-model="tempShelf.name" required>
                <div class="label">
                    Shelf Name*
                </div>
            </div>

            <div class="dialog_update__text_1">
                <input type="text"  class="input_text" placeholder="Description" v-model="tempShelf.description">
                <div class="label">
                    Description
                </div>
            </div>


            <div class="dialog_update__selects">
                <div class="dialog_update__select">
                    <div class="status_lists" v-if="list_room_show">
                        <li v-for="(item, index) in rooms" :key="index" @click="selectRoom(item)">
                            <div >{{item.name}}</div>
                        </li>
                    </div>
                    <md-icon class="icon">keyboard_arrow_down</md-icon>
                    <div @click="list_room_show=!list_room_show"><input type="text" class="input_select" placeholder="Location" disabled v-model="room_selected.name" required></div>
                    <div class="label" :style="{color:list_room_show?'#0080F6':'#6D6E71'}">
                        Location*
                    </div>
                </div>
            </div>

             <div class="dialog_update__text_1">
                <input type="number" min="1"  class="input_text" placeholder="Bin" v-model="tempShelf.bin" required>
                <div class="label">
                    Bin
                </div>
            </div>

            <div class="dialog_update_radios">
              <div class="label">
                Status
              </div>
              <div class="dialog_update_radio">
                <md-radio v-model="tempShelf.active" class="md-primary" :value="true">Active</md-radio>
                <md-radio v-model="tempShelf.active" class="md-primary" :value="false">Inactive</md-radio>
              </div>
            </div>

            <div class="dialog_update_radios">
              <div class="label">
                Is open?
              </div>
              <div class="dialog_update_radio">
                <md-radio v-model="tempShelf.isOpen" class="md-primary" :value="true">Open</md-radio>
                <md-radio v-model="tempShelf.isOpen" class="md-primary" :value="false">Closed</md-radio>
              </div>
            </div>
        </form>
    
  
        <div class="dialog_update__btn">
            <md-button class="md-raised btn_cancel" @click="shelf_add_active = false">Cancel</md-button>
            <md-button class="md-raised md-primary btn_update" @click="handleAddShelf">Create</md-button>
        </div>
      </md-dialog>

       <md-dialog :md-active.sync="shelf_update_active" class="dialog_updateShelf dialog_update">
        <div class="dialog_update__title">
            <div><strong>Update Shelf</strong></div>
            <div @click="shelf_update_active=false"><md-icon class="icon_close ">clear</md-icon></div>
        </div>
        <form class="dialog_update__texts dialog_updateShelf_texts" ref="updateShelfForm">
            <div class="dialog_update__text_1">
                <input type="text"  class="input_text" placeholder="Shelf Name" v-model="tempShelf.name" required>
                <div class="label">
                    Shelf Name*
                </div>
            </div>

            <div class="dialog_update__text_1">
                <input type="text"  class="input_text" placeholder="Description" v-model="tempShelf.description">
                <div class="label">
                    Description
                </div>
            </div>
            <div class="dialog_update__selects">
                <div class="dialog_update__select">
                    <div class="status_lists" v-if="list_room_show">
                        <li v-for="(item, index) in rooms" :key="index" @click="selectRoom(item)">
                            <div >{{item.name}}</div>
                        </li>
                    </div>
                    <md-icon class="icon">keyboard_arrow_down</md-icon>
                    <div @click="list_room_show=!list_room_show"><input type="text" class="input_select" placeholder="Location" disabled v-model="room_selected.name" required></div>
                    <div class="label" :style="{color:list_room_show?'#0080F6':'#6D6E71'}">
                        Location*
                    </div>
                </div>
            </div>
             <div class="dialog_update__text_1">
                <input type="number" min="1" class="input_text" placeholder="Bin" v-model="tempShelf.bin" required>
                <div class="label">
                    Bin
                </div>
            </div>
            <div class="dialog_update_radios">
              <div class="label">
                Status
              </div>
              <div class="dialog_update_radio">
                <md-radio v-model="tempShelf.active" class="md-primary" :value="true">Active</md-radio>
                <md-radio v-model="tempShelf.active" class="md-primary" :value="false">Inactive</md-radio>
              </div>
            </div>
            <div class="dialog_update_radios">
              <div class="label">
                Is open?
              </div>
              <div class="dialog_update_radio">
                <md-radio v-model="tempShelf.isOpen" class="md-primary" :value="true">Open</md-radio>
                <md-radio v-model="tempShelf.isOpen" class="md-primary" :value="false">Closed</md-radio>
              </div>
            </div>
        </form>
        <div class="dialog_update__btn">
            <md-button class="md-raised btn_cancel" @click="shelf_update_active = false">Cancel</md-button>
            <md-button class="md-raised md-primary btn_update" @click="handleUpdateShelf">Update</md-button>
        </div>
      </md-dialog>

      <md-dialog :md-active.sync="shelf_delete_active" class="dialog_delete">
          <md-dialog-title class="dialog_delete__title">
            <div><strong>Delete Shelf</strong></div>
            <div @click="shelf_delete_active=false"><md-icon class="icon_close">clear</md-icon></div>
          </md-dialog-title>
          <md-dialog-content class="dialog_delete___content">
            <span>Do you want to delete this shelf?</span>
            <br>
            <span>All related data will alse be deleted.</span>
          </md-dialog-content>
          <md-dialog-actions class="dialog_delete__button">
            <md-button class="md-raised btn_cancel" @click="shelf_delete_active = false">Cancel</md-button>
            <md-button class="md-raised md-accent btn_delete" @click="handleDeleteShelf">Delete</md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>
</template>
<script>
import RoomContent from '../../components/RoomContent'
import request from '@/utils/request'

export default {
  name:'SiteRoomShelf',
  components:{
      RoomContent
  },
  created() {
    request('get', `/rooms?site=${this.siteId}`)
      .then(data => {
        this.allRooms = data.rooms
      })
      .catch(err => console.log(err))
    request('get', `/shelves?site=${this.siteId}`)
      .then(data => {
        this.shelves = data.shelves
      })
      .catch(err => console.log(err))
    request('get', `/bins?site=${this.siteId}`)
      .then(data => {
        this.bins = data.bins
      })
      .catch(err => console.log(err))
  },
  data(){
    return{
        siteId: this.$route.params.id,
        room_add_active:false,
        room_update_active: false,
        room_delete_active: false,

      
        shelf_add_active:false,
        shelf_update_active: false,
        shelf_delete_active: false,

        list_room_show:false,
        list_bin_show:false,
        room_selected:{},
        bin_selected:[],
        binNum_selected:'',
        
        shelves: [],
        bins: [],
        allRooms: [],
        tempRoom: {},
        tempShelf: {}
    }
    
  },
  computed: {
    rooms() {
      let roomMap = {}
      for (let i = 0; i < this.shelves.length; i++) {
        let shelf = this.shelves[i]
        if (shelf.room && shelf.room.site == this.siteId) {
          if (!roomMap[shelf.room._id]) {
            roomMap[shelf.room._id] = {
              ...shelf.room,
              shelves: [],
              binCount: 0
            }
          }
  
          let currentShelf = {
            _id: shelf._id,
            name: shelf.name,
            description: shelf.description,
            hardwarePort: shelf.hardwarePort,
            binCount:0,
            room: shelf.room,
            isOpen: shelf.isOpen == undefined ? true : shelf.isOpen,
            active: shelf.active == undefined ? true : shelf.active
          }
  
          for (let j = 0; j < this.bins.length; j++) {
            let bin = this.bins[j]
            if (bin.shelf == shelf._id) {
              roomMap[shelf.room._id].binCount++
              currentShelf.binCount++
            }
          }

          roomMap[shelf.room._id].shelves.push(currentShelf)
        }
      }

      for (let i = 0; i < this.allRooms.length; i++) {
        if (!roomMap[this.allRooms[i]._id]) {
          roomMap[this.allRooms[i]._id] = {
            ...this.allRooms[i],
            shelves: [],
            binCount: 0
          }
        }
      }

      return Object.values(roomMap)
    },
    accessRight3(){
      let roleRight=['ROOT','CUSTOMER','MASTER','OPERATOR','CONTRACTOR'];
      return roleRight.includes(this.$store.state.role)
    },
  },
  methods:{
    showAddRoom() {
      this.tempRoom = {
        name: '',
        description: '',
        site: this.siteId,
        isOpen: true,
        active: true
      }
      this.room_add_active=true
    },
    showUpdateRoom(el) {
      this.tempRoom = {...el}
      this.room_update_active=true;
    },
    showDeleteRoom(el) {
      this.tempRoom = {...el}
      this.room_delete_active=true;
    },
    showAddShelf() {
      this.tempShelf = {
        name: '',
        description: '',
        // hardwarePort: this.siteId,
        room: '',
        isOpen: true,
        active: true
      }
      this.shelf_add_active=true
    },
    showUpdateShelf(el) {
      this.tempShelf = {...el, bin: 0}
      if (this.tempShelf.isOpen == undefined) {
        this.tempShelf.isOpen = true
      }
      if (this.tempShelf.active == undefined) {
        this.tempShelf.active = true
      }

      this.room_selected = {...el.room}
      this.bin_selected = []
      for (let i = 0; i < this.bins.length; i++) {
        if (this.bins[i].shelf == el._id) {
          this.bin_selected.push(this.bins[i])
        }
      }


      for (let i = 0; i < this.bins.length; i++) {
        if (this.bins[i].shelf == this.tempShelf._id) {
          this.tempShelf.bin++
        }
      }

      this.shelf_update_active=true;
    },
     showDeleteShelf(el) {
      this.tempShelf = {...el}
      this.shelf_delete_active=true;
    },
    selectSite(el){
      this.site_selected={...el};
      this.list_site_show=false;
    },
    selectRoom(el){
      this.room_selected={...el};
      this.list_room_show=false;
    },
    handleAddRoom() {
      if (this.$refs.addRoomForm.reportValidity()) {
        request('post', '/rooms', this.tempRoom)
          .then(data => {
            console.log('PP')
            this.allRooms.push(data.room)
            this.room_add_active = false
          })
          .catch(err => {
            console.log(err)
            alert(err.message)
          })
      }
    },
    handleUpdateRoom() {
      if (this.$refs.updateRoomForm.reportValidity()) {
        this.tempRoom.site=this.siteId
        request('put', '/rooms', this.tempRoom)
          .then(data => {
            // console.log(data)
            for (let i = 0; i < this.allRooms.length; i++) {
              if (this.allRooms[i]._id == data.room._id) {
                this.allRooms.splice(i, 1, data.room)
                break
              }
            }
            for (let i = 0; i < this.shelves.length; i++) {
              if (this.shelves[i].room && this.shelves[i].room._id == data.room._id) {
                this.shelves[i].room = data.room
              }
            }
            this.room_update_active = false
          })
          .catch(err => {
            console.log(err)
            alert(err.message)
          })
      }
    },
    handleDeleteRoom() {
      request('delete', `/rooms/${this.tempRoom._id}`, {site: this.siteId})
        .then(() => {
          for (let i = 0; i < this.rooms.length; i++) {
            if (this.rooms[i]._id == this.tempRoom._id) {
              this.rooms.splice(i, 1)
              break
            }
          }
          this.room_delete_active=false;
        })
        .catch(err => {
          alert(err.message)
        })
    },
    handleAddShelf() {
      if (this.room_selected && this.room_selected._id) {
        this.tempShelf.room = this.room_selected._id
      }
      else
      {
        return alert("Please sellect location!")
      }

      this.tempShelf.site = this.siteId

      if (this.$refs.addShelfForm.reportValidity()) {
        request('post', '/shelves', this.tempShelf)
          .then(data => {
            this.shelves.push(data.shelf)
            for (let i = 0; i < this.bins.length; i++) {
              if (this.bins[i].shelf == data.shelf._id) {
                this.bins.splice(i, 1)
                i--
              }
            }
            for (let i = 0; i < data.shelf.binCount; i++) {
              this.bins.push({
                name: 'Bin ' + (i+1),
                shelf: data.shelf._id
              })
            }
            this.shelf_add_active = false
          })
          .catch(err => {
            console.log(err)
            alert(err.message)
          })
      }
    },
    handleUpdateShelf() {
      if (this.room_selected && this.room_selected._id) {
        this.tempShelf.room = this.room_selected._id
      }
      this.tempShelf.site = this.siteId

      if (this.$refs.updateShelfForm.reportValidity()) {
        request('put', '/shelves', this.tempShelf)
          .then(data => {
            for (let j = 0; j < this.rooms.length; j++) {
              if (this.rooms[j]._id == data.shelf.room) {
                data.shelf.room = {
                  _id: this.rooms[j]._id,
                  name: this.rooms[j].name,
                  description: this.rooms[j].description,
                  site: this.rooms[j].site
                }
                break
              }
            }

            for (let i = 0; i < this.shelves.length; i++) {
              if (this.shelves[i]._id == data.shelf._id) {
                this.shelves.splice(i, 1, data.shelf)
              }
            }

            for (let i = 0; i < this.bins.length; i++) {
              if (this.bins[i].shelf == data.shelf._id) {
                this.bins.splice(i, 1)
                i--
              }
            }
            for (let i = 0; i < data.shelf.binCount; i++) {
              this.bins.push({
                name: 'Bin ' + (i+1),
                shelf: data.shelf._id
              })
            }
          
            this.shelf_update_active = false
          })
          .catch(err => {
            console.log(err)
            alert(err.message)
          })
      }
    },
    handleDeleteShelf() {
      request('delete', `/shelves/${this.tempShelf._id}`, {site: this.siteId})
        .then(() => {
          for (let i = 0; i < this.shelves.length; i++) {
            if (this.shelves[i]._id == this.tempShelf._id) {
              this.shelves.splice(i, 1)
              break
            }
          }

          for (let i = 0; i < this.bins.length; i++) {
            if (this.bins[i].shelf == this.tempShelf._id) {
              this.bins.splice(i, 1)
              i--
            }
          }
          this.shelf_delete_active=false;
        })
        .catch(err => {
          alert(err.message)
        })
    }
  },
}
</script>
<style lang="scss" >
 .siteRoomShelf_container{
     width: 100%;
     display: flex;
     justify-content: center;
    .main_table{
        // width: 100%f;
        height: 50%;
        width: 1386px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .Room__btn_container{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            position: relative;
            width: 100%;
            margin-right: 16px;
            .Room_btn{
                background-color: #0080F7;
                border-radius: 4px;
                text-transform: none; 
                font-size: 1rem;
                height: 48px !important;
                margin-bottom: 26px;
                margin-right: 0px;
                border-radius: 4px;
                 .md-button-content{
                     display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .btn_room{
                margin-right: 24px;
                width: 183px;
            }
            .btn_shelf{
                width: 170px;             
            }
        }
        .Room_container{
            width: 100%;
            display: flex;
            flex-direction:row;
            flex-wrap: wrap;
            
            .room-wrapper {
              flex-basis: 50%;
              padding: 16px;
            }
        }
    }
    
 }
.dialog_updateShelf{
   .md-dialog-container
    {
      max-height: 100vh;
      overflow: visible;
       
    }
    .dialog_updateShelf_texts{
      max-height: calc(100% - 300px);
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 80px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 0px;
      padding: 0;
    }
 }

.dialog_updateRoom{
   .md-dialog-container
    {
      min-height:378px;
      max-height: 378px;
    //   overflow: visible;
       
    }
    .dialog_updateRoom_texts{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 0px;
      padding: 0;
      .area_text{
        height: 78px;
        padding-top: 12px;
        resize: none;
      }
    }
 }
 .site_lists{
   z-index: 18;
 }
 .room_lists{
   z-index: 17;
 }
</style>