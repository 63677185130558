<template>
    <div class='shelf_container'>
      <div class="shelf_headers">
        <div>{{info.name}}</div>
        <div class="status" :style="!info.isOpen ? 'background-color: gray' : ''">
          {{ info.isOpen?'Open':'Closed' }}
        </div>
        <div v-if='accessRight3' >
            <md-menu md-size='medium' md-align-trigger>
              <div md-menu-trigger><Dot/></div>
               <md-menu-content>
                  <md-menu-item @click="$emit('update')">Update</md-menu-item>
                  <md-menu-item @click="$emit('delete')"><div style="color:red">Delete</div></md-menu-item>
              </md-menu-content>
            </md-menu>          
          </div>
      </div>
      <div class="shelf_content">
        <div>ID: {{info._id}}</div>
        <div>Description: {{info.description}}</div>
        <div>Total Bin: {{info.binCount || 0}}</div>
      </div>
    </div>
</template>
<script>
import Dot from './icon/Dot'
export default{
    name:'ShelfContent',
    props:{
          info:{
          type:Object,
          default(){
            return {}
          }
        }
    },
    components:{
        Dot
    },
    data () {
        return {
        
        }
    },
    computed:{
      accessRight3(){
        let roleRight=['ROOT','CUSTOMER','MASTER','ADMIN','CONTRACTOR'];
        return roleRight.includes(this.$store.state.role)
      },
    }
}
</script>
<style lang="scss" scoped>
.shelf_container{
    height:95px;
    max-width: 625px;
    border: 1px solid #D7D7D7;
    box-sizing: border-box;
    background-color: white;
    padding:10px 18px;
    .shelf_headers{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      div{
        font-weight: bold;
        font-size: 1rem;

        &:first-child {
          flex-grow: 1;
        }

        &:nth-child(2) {
          margin-right: 8px;
          color: white;
          font-size: 14px;
          background: green;
          padding: 2px 4px;
          border-radius: 5px;
        }
      }
    }
    .shelf_content{
      font-size: 0.8rem;
      color: #6D6E71;
      div{
        text-align: left;
      }
    }
  } 
</style>