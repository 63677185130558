<template>
  <div class="bin" :style="`background-color: ${progressColor}`">
    <div class="bin-status">
      <StatusCircle :percentage="Math.max(percent, 0)" :progressColor="progressColor" />
    </div>
    <div class="bin-info">
      <div class="bin-id">ID: {{id}}</div>
      <div class="bin-device-id">Part No: {{deviceId}}</div>
      <div class="bin-name">Name: {{name}}</div>
    </div>
    <div class="bin-context">
      <md-menu md-size="medium" md-align-trigger>
        <md-button md-menu-trigger><md-icon>more_horiz</md-icon></md-button>
        <md-menu-content>
            <md-menu-item @click="$emit('detail')">View detail</md-menu-item>
            <md-menu-item @click="$emit('update')" v-if='accessRight3'>Update</md-menu-item>
            <md-menu-item @click="$emit('delete')" v-if='accessRight3'><div style="color:red">Delete</div></md-menu-item>
        </md-menu-content>
      </md-menu>
    </div>
  </div>
</template>

<script>
import StatusCircle from '@/components/StatusCircle'

export default {
  components: {
    StatusCircle
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    deviceId: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    quantity: {
      type: Number,
      required: true
    },
    capacity: {
      type: Number,
      required: true
    },
    itemStatus:{
      type:Number,
      default:0
    },
    percent:{
      type:Number,
      default:0
    }
    // lowThreshold: {
    //   type: Number,
    //   default: 50
    // },
    // critThreshold: {
    //   type: Number,
    //   default: 20
    // }
  },
  computed: {
    accessRight3(){
      let roleRight=['ROOT','CUSTOMER','MASTER','ADMIN','CONTRACTOR'];
      return roleRight.includes(this.$store.state.role)
    },
    percentage() {
      let percent = parseInt(this.quantity / this.capacity * 100)
      return Math.max(0, Math.min(100, percent)) || 0
    },
    progressColor() {
      if (this.itemStatus === 0) {
        return '#6D6E71' // Unassign
      }

      else if (this.itemStatus==1) { // Good
        return '#00C14D'
      } else if (this.itemStatus==2) { // Low
        return '#EB9515'
      } else if (this.itemStatus==-1) { // Critical / Expired
        return '#ED1D24'
      }

      return '#6D6E71'
    }
  }
}
</script>

<style lang="scss" scoped>
.bin {
  display: flex;
  border-radius: 5px;
  padding: 16px;
  align-self: stretch;

  .bin-status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 16px;
  }

  .bin-info {
    flex-grow: 1;
    color: #fff;
    font-size: 16px;
    line-height: 24px;

    .bin-id {
      font-weight: bold;
    }
  }

  .bin-context {
    /deep/ .md-icon {
      color: #fff;
    }

    /deep/ .md-button {
      min-width: 48px;
    }
  }
}
</style>
