<template>
  <div class="customer_container">
    <Header></Header>
    <nav class="main_nav">
      <router-link to="/" style="width:70px"><md-icon style="color:#0080F6">home</md-icon></router-link>
      <router-link to="/customer/system" style="margin-right:12px">System</router-link>
      <router-link to="/customer/home">Customer</router-link> 
      <router-link to="/customer/site">Site</router-link>
      <!-- <div class="animation start-home"></div> -->
    </nav>
    <div class="customer-body">
      <transition name="fade">
        <router-view/>
      </transition>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from '../../components/Footer'
import Header from '../../components/Header'
export default {
  name:'Customer',
  components:{
    Footer,
    Header
  },
  created()
  {
    let roleRight=['ROOT'];
    if(!roleRight.includes(this.$store.state.role))
    {
      return this.$router.push('/')
    }
  }
}
</script>
<style lang="scss">
  .customer_container{
    min-height: 100vh;
    --textColor:#6D6E71;
    background-color: #ECECEC;
    display:flex;
    flex-direction: column;
    // justify-content: center;
    align-items:center;
    .main_nav{
      margin-top:96px;
      min-height: 48px !important;
      max-height: 48px;
      width: 100%;
      // margin-top:96px;
      background-color: white;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      font-size: 1rem;
      font-weight: bold;
      display: flex;
      justify-content:flex-start;
      align-content: flex-start;
      align-items: center;
      a{
        text-align: center;
        line-height:48px;
        min-width: 115px;
        height: 100%;
        color: #6D6E71 !important;
         &.router-link-exact-active {
          color: #0080F6 !important;
          border-bottom: 3px solid  #0080F6;
          border-radius: 4px 4px 0px 0px;
        }
      }
      a:hover{
        text-decoration: none !important;
      }
      a span{
        color: #6D6E71;
      }
      a span:hover{
        color: #0080F6;
      }
    }

    .customer-body {
      // background: yellow;
      padding: 0px 32px 72px 32px;
      width: 100%;
      // background-color: #ECECEC;
      text-align: left;
      flex-grow: 1;
      position: relative;
      z-index: 1;
    }
  }
  
</style>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>