<template>
  <div class="site-setting">
    <div class="User__btn_container" v-if='settingPermission'>
      <!-- <md-button class="md-raised md-primary btn_customer add-bin-btn" v-if="!generalInfoEditing" @click="enableEditGeneralInfo"> <md-icon class="md-size-2x">create</md-icon>Edit setting</md-button> -->
      <md-button v-if="!generalInfoEditing" class="md-raised md-primary Setting_btn btn_edit"  @click="enableEditGeneralInfo"> 
        <svg style="margin-right:8px" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M21.3256 1.82783L20.1521 0.654382C19.2796 -0.218127 17.7587 -0.218127 16.8862 0.654382L15.3685 2.17207C15.3674 2.17315 15.3653 2.17532 15.3642 2.1764C15.3631 2.17748 15.3609 2.17965 15.3598 2.18073L6.32407 11.2154C6.22447 11.315 6.16061 11.4438 6.14112 11.5824L5.5544 15.6895C5.52517 15.8919 5.59337 16.0954 5.73734 16.2405C5.85967 16.365 6.02529 16.4321 6.19633 16.4321C6.22664 16.4321 6.25803 16.4299 6.28834 16.4256L10.3954 15.8389C10.534 15.8194 10.6639 15.7544 10.7624 15.6559L19.7971 6.62013C19.7982 6.61905 19.8003 6.61689 19.8014 6.6158C19.8025 6.61472 19.8047 6.61256 19.8057 6.61147L21.3234 5.09379C21.7597 4.65753 22 4.07838 22 3.46135C22 2.84431 21.7608 2.26409 21.3256 1.82783ZM9.99813 14.5831L6.96275 15.0172L7.39684 11.9808L15.8242 3.55445L18.4255 6.15574L9.99813 14.5831ZM20.4065 4.17473L19.3435 5.23776L16.7422 2.63647L17.8052 1.57344C18.1874 1.19023 18.852 1.19023 19.2331 1.57344L20.4065 2.74689C20.5981 2.93741 20.7021 3.1918 20.7021 3.46135C20.7021 3.7309 20.5981 3.9842 20.4065 4.17473ZM20.7843 11.4525V16.8651C20.7843 19.6114 18.5511 21.8447 15.8048 21.8447H4.97958C2.23323 21.8447 0 19.6114 0 16.8651V6.03991C0 3.29356 2.23323 1.06033 4.97958 1.06033H10.3922C10.7516 1.06033 11.0417 1.35152 11.0417 1.70984C11.0417 2.06815 10.7505 2.35935 10.3922 2.35935H4.97958C2.94986 2.35935 1.29902 4.01019 1.29902 6.03991V16.8651C1.29902 18.8948 2.94986 20.5456 4.97958 20.5456H15.8048C17.8345 20.5456 19.4853 18.8948 19.4853 16.8651V11.4525C19.4853 11.0931 19.7765 10.803 20.1348 10.803C20.4931 10.803 20.7843 11.0931 20.7843 11.4525Z" fill="white"/>
        </svg>
        <strong>Edit Setting</strong>
      </md-button>
      <md-button class="md-raised Setting_btn" v-if="generalInfoEditing" style="height:48px;width:100px;border-radius: 4px;" @click="discard_active=true"><strong>Cancel</strong></md-button>
      <md-button class="md-raised md-primary Setting_btn" style="width:158px;height:48px;border-radius: 4px;" v-if="generalInfoEditing" @click="saveGeneralInfo"><strong>Save</strong></md-button>
    </div>
    <div class="general-info">
      <h3 class="title">General info</h3>
      <div class="info-fields">
        <div class="info-field">
          <label>Customer</label>
          <p >{{site.customer ? site.customer.name : 'Unassign'}}</p>
        </div>

        <div class="info-field">
          <label>Site</label>
          <p v-if="!generalInfoEditing">{{site.name}}</p>
          <input type="text" v-model="tempSiteName" v-else>
        </div>

        <div class="info-field"  style=" visibility: hidden;">
          <label>System</label>
          <p>{{site.system ? site.system.name : ''}}</p>
        </div>

        <div class="info-field" >
          <label>Address</label>
          <p>{{site.customer ? site.customer.address : 'Unassign'}}</p>
        </div>

        <div class="info-field">
          <label>Phone</label>
          <p>{{site.customer ? site.customer.phone : 'Unassign'}}</p>
        </div>

        <div class="info-field" style=" visibility: hidden;" >
          <label>Email</label>
          <p>{{site.customer ? site.customer.email : 'Unassign'}}</p>
        </div>

        <div class="info-field" style=" visibility: hidden;">
          <label>Tax</label>
          <p>{{site.customer ? site.customer.tax : 'Unassign'}}</p>
        </div>

        <div class="info-field">
          <label>Website</label>
          <p>{{site.customer ? site.customer.website : 'Unassign'}}</p>
        </div>
      </div>
    </div>

    <div class="inventory-info">
      <div class="low-stock">
        <h3 class="title" style="margin-bottom:16px">Low Stock Notification Schedule</h3>
        <div class="info-fields">
          <!-- <div class="info-field">
            <label>Type Notification</label>
            <p v-if="!generalInfoEditing" ><span v-for="item in type_nofi" :key="item" style="margin-right:10px">{{item}}</span></p>
            <div class="checkbox-type" v-else>
              <md-checkbox :value="true" class="md-primary" v-model="tempLowStockSms">SMS</md-checkbox>
              <md-checkbox :value="true" class="md-primary" v-model="tempLowStockEmail">Email</md-checkbox>
            </div>
          </div> -->

          <div class="info-field">
            <label >Time Notification</label>
            <p v-if="!generalInfoEditing">{{time_nofi_text}}</p>
            <div class="time-nofi-radios" v-else>
              <div >
                    <input type="radio" value="" v-model="tempLowStockSchedule" name="lowStockSchedule">
                    <label style="margin-left:9px;color: #171A26;" for="no">No lowstock notification</label>
                </div>
                <div style="margin-top:6px">
                    <input type="radio"  value="daily" v-model="tempLowStockSchedule" name="lowStockSchedule">
                    <label style="margin-left:9px;color: #171A26;" for="daily">Daily at 00:00 UTC</label>
                    <!-- <span style="margin-left:8px">(</span>
                    <span @click="time_setting_active=true" class="custom_time_text">Customize time</span>
                    <span>)</span> -->
                </div>
                <div style="margin-top:6px">
                    <input type="radio"  value="weekly" v-model="tempLowStockSchedule" name="lowStockSchedule">
                    <label style="margin-left:9px;color: #171A26;" for="weekly">Weekly on every Sunday at 00:00 UTC</label> 
                </div>
                 <div style="margin-top:6px">
                    <input type="radio"  value="monthly" v-model="tempLowStockSchedule" name="lowStockSchedule">
                    <label style="margin-left:9px;color: #171A26;" for="monthly">Monthly on 1st day of every month at 00:00 UTC</label> 
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="inventory-report">
        <h3 class="title">Inventory Report Schedule</h3>
        <div class="info-fields">
          <div class="info-field">
            <label>Time Report</label>
            <p v-if="!generalInfoEditing">{{time_report_text}}</p>
            <div class="time-nofi-radios" v-else>
              <div >
                    <input type="radio" value="" v-model="tempInventoryReportSchedule" name="inventoryReportSchedule">
                    <label style="margin-left:9px;color: #171A26;" for="no">No inventory report</label>
                </div>
                <div style="margin-top:6px">
                    <input type="radio"  value="daily" v-model="tempInventoryReportSchedule" name="inventoryReportSchedule">
                    <label style="margin-left:9px;color: #171A26;" for="daily">Daily at 00:00 UTC </label>
                    <!-- <span style="margin-left:8px">(</span>
                    <span @click="report_setting_active=true" class="custom_time_text">Customize time</span>
                    <span>)</span> -->
                </div>
                <div style="margin-top:6px">
                    <input type="radio"  value="weekly" v-model="tempInventoryReportSchedule" name="inventoryReportSchedule">
                    <label style="margin-left:9px;color: #171A26;" for="weekly">Weekly on every Sunday at 00:00 UTC</label> 
                </div>
                 <div style="margin-top:6px">
                    <input type="radio"  value="monthly" v-model="tempInventoryReportSchedule" name="inventoryReportSchedule">
                    <label style="margin-left:9px;color: #171A26;" for="monthly">Monthly on 1st day of every month at 00:00 UTC</label> 
                </div>
            </div>
          </div>

          <hr />

          <div class="mail-now">
            <p style="color:#6D6E71">Generate and send inventory report via email now</p>
            <button><md-icon >mail_outline</md-icon> Send email</button>
          </div>
        </div>
      </div>
    </div>
    <md-dialog :md-active.sync="discard_active" class="dialog_delete">
      <md-dialog-title class="dialog_delete__title">
      <div><strong>Discard Change</strong></div>
      <div @click="discard_active=false"><md-icon class="icon_close">clear</md-icon></div>
      </md-dialog-title>
          <md-dialog-content class="dialog_delete___content">
          <span>Are you sure you want to discard unsaved changes?</span>
      </md-dialog-content>
      <md-dialog-actions class="dialog_delete__button">
          <md-button class="md-raised btn_cancel" @click="discard_active = false">Cancel</md-button>
          <md-button class="md-raised md-accent btn_delete" @click="handleDiscard">Discard</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="report_setting_active" class="dialog_timeSetting">
        <div class="dialog_update__title">
            <div><strong>Daily Report Timming</strong></div>
            <div @click="report_setting_active=false"><md-icon class="icon_close ">clear</md-icon></div>
        </div>
         <div style="color:#6D6E71;font-size:14px;margin-top:4px">24 hour hh:mm format</div>
         <div style="display:flex">  
          <div class="dialog_selectors"  v-for="(item,index) in time_report_setting" :key="index">
              <div class="selector_content">
                {{item.title}} Notification
              </div>
              <div class="selector_container">
                  <div class="dialog_update__selects" style="margin-right:8px">
                    <div class="dialog_update__select">
                        <div class="status_lists md-scrollbar" v-if="item.list_hour_show">
                            <li v-for="i in hour_list" :key="i" @click="selectHour(i,index,2)">
                                <div >{{i}}</div>
                            </li>
                        </div>
                        <md-icon class="icon">keyboard_arrow_down</md-icon>
                        <div @click="item.list_hour_show=!item.list_hour_show"><input type="text" class="input_select" placeholder="HH" disabled v-model="item.hour_selected"></div>
                    </div>
                  </div>
                  <div class="dialog_update__selects">
                    <div class="dialog_update__select">
                        <div class="status_lists " v-if="item.list_min_show">
                            <li v-for="i in minute_list" :key="i" @click="selectMin(i,index,2)">
                                <div >{{i}}</div>
                            </li>
                        </div>
                        <md-icon class="icon">keyboard_arrow_down</md-icon>
                        <div @click="item.list_min_show=!item.list_min_show"><input type="text" class="input_select" placeholder="MM" disabled v-model="item.min_selected"></div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="dialog_update__btn" >
            <md-button class="md-raised btn_cancel" @click="report_setting_active = false">Cancel</md-button>
            <md-button class="md-raised md-primary btn_update" @click="report_setting_active=false">Set</md-button>
        </div>
    </md-dialog>

        <md-dialog :md-active.sync="time_setting_active" class="dialog_timeSetting">
        <div class="dialog_update__title">
            <div><strong>Daily Notification Timming</strong></div>
            <div @click="time_setting_active=false"><md-icon class="icon_close ">clear</md-icon></div>
        </div>
         <div style="color:#6D6E71;font-size:14px;margin-top:4px">24 hour hh:mm format</div>
         <div style="display:flex">  
          <div class="dialog_selectors"  v-for="(item,index) in time_nofi_setting" :key="index">
              <div class="selector_content">
                {{item.title}} Notification
              </div>
              <div class="selector_container">
                  <div class="dialog_update__selects" style="margin-right:8px">
                    <div class="dialog_update__select">
                        <div class="status_lists" v-if="item.list_hour_show">
                            <li v-for="i in hour_list" :key="i" @click="selectHour(i,index,1)">
                                <div >{{i}}</div>
                            </li>
                        </div>
                        <md-icon class="icon">keyboard_arrow_down</md-icon>
                        <div @click="item.list_hour_show=!item.list_hour_show"><input type="text" class="input_select" placeholder="HH" disabled v-model="item.hour_selected"></div>
                    </div>
                  </div>
                  <div class="dialog_update__selects">
                    <div class="dialog_update__select">
                        <div class="status_lists " v-if="item.list_min_show">
                            <li v-for="i in minute_list" :key="i" @click="selectMin(i,index,1)">
                                <div >{{i}}</div>
                            </li>
                        </div>
                        <md-icon class="icon">keyboard_arrow_down</md-icon>
                        <div @click="item.list_min_show=!item.list_min_show"><input type="text" class="input_select" placeholder="MM" disabled v-model="item.min_selected"></div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="dialog_update__btn" >
            <md-button class="md-raised btn_cancel" @click="time_setting_active = false">Cancel</md-button>
            <md-button class="md-raised md-primary btn_update" @click="time_setting_active=false">Set</md-button>
        </div>
    </md-dialog>
    <md-snackbar :md-active.sync="showSnackbar" :md-duration="2000" md-position="center">Site settings saved successfully!</md-snackbar>
  </div>
</template>

<script>
import request from '@/utils/request'
export default {
  created() {
    request('get', '/sites/' + this.id)
      .then(data => {
        this.site = data.site
      })
      .catch(err => console.log(err))
  },
  data() {
    return {
      id: this.$route.params.id,
      site: {
        customer: {},
        system: {}
      },
      generalInfoEditing: false,
      tempSiteName: '',
      tempLowStockSms: false,
      tempLowStockEmail: false,
      tempLowStockSchedule: '',
      tempInventoryReportSchedule: '',
      showSnackbar: false,
      discard_active:false,
      type_nofi:['SMS'],
      time_nofi:'',
      time_nofi_text:'',
      time_report:'',
      time_report_text:'',
      time_setting_active:false,
      report_setting_active:false,
      hour_list:['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
      minute_list:['00','15','30','45'],
      time_nofi_setting:[
        {
          title:'1st',
          list_hour_show:false,
          list_min_show:false,
          hour_selected:'',
          min_selected:'',
        },
        {
          title:'2nd',
          list_hour_show:false,
          list_min_show:false,
          hour_selected:'',
          min_selected:'',
        },
        {
          title:'3rd',
          list_hour_show:false,
          list_min_show:false,
          hour_selected:'',
          min_selected:'',
        }
      ],
      time_report_setting:[
        {
          title:'1st',
          list_hour_show:false,
          list_min_show:false,
          hour_selected:'',
          min_selected:'',
        },
        {
          title:'2nd',
          list_hour_show:false,
          list_min_show:false,
          hour_selected:'',
          min_selected:'',
        },
        {
          title:'3rd',
          list_hour_show:false,
          list_min_show:false,
          hour_selected:'',
          min_selected:'',
        }
      ]
    }
  },
  computed:{
    settingPermission()
    {
      let accessRight=['ROOT','CUSTOMER','MASTER','OPERATOR'];
      return (accessRight.includes(this.$store.state.role))
    }
  },
  watch:{
    time_nofi:function(){
      switch(this.time_nofi){
        case "no":
          this.time_nofi_text='No lowstock notification'
          break;
        case "daily":
          this.time_nofi_text='Daily at 00:00 UTC'
          break;
        case 'weekly':
          this.time_nofi_text='Weekly on every Sunday at 00:00 UTC'
          break;
        case 'monthly':
          this.time_nofi_text='Monthly on 1st day of every month at 00:00 UTC'
          break;
        default:
          break;
      }
    },
    time_report:function(){
      switch(this.time_report){
        case "no":
          this.time_report_text='No inventory report'
          break;
        case "daily":
          this.time_report_text='Daily at 00:00 UTC'
          break;
        case 'weekly':
          this.time_report_text='Weekly on every Sunday at 00:00 UTC'
          break;
        case 'monthly':
          this.time_report_text='Monthly on 1st day of every month at 00:00 UTC'
          break;
        default:
          break;
      }
    }
  },
  methods: {
    handleCustomTime(){
      console.log("custom time")
    },
    handleDiscard(){
      console.log('discard')
      this.generalInfoEditing = false;
      this.discard_active=false;
    },
    selectHour(el,index,type){
      if(type===1)
      {
        this.time_nofi_setting[index].hour_selected=el;
        this.time_nofi_setting[index].list_hour_show=false;
      }
      else{
        this.time_report_setting[index].hour_selected=el;
        this.time_report_setting[index].list_hour_show=false;
      }
      
    },
    selectMin(el,index,type){
      if(type===1)
      {
        this.time_nofi_setting[index].min_selected=el;
        this.time_nofi_setting[index].list_min_show=false;
      }
      else{
        this.time_report_setting[index].min_selected=el;
        this.time_report_setting[index].list_min_show=false;
      }
    },
    enableEditGeneralInfo() {
      this.generalInfoEditing = true
      this.tempSiteName = this.site.name
      this.tempLowStockSms = this.site.lowStockSms || false
      this.tempLowStockEmail = this.site.lowStockEmail || false,
      this.tempLowStockSchedule = this.site.lowStockSchedule || ''
      this.tempInventoryReportSchedule = this.site.inventoryReportSchedule || ''
    },
    saveGeneralInfo() {
      if (!this.tempSiteName) {
        alert('Site name is required.')
      } else {
        let data = {
          ...this.site,
          name: this.tempSiteName,
          lowStockSms: this.tempLowStockSms || false,
          lowStockEmail: this.tempLowStockEmail || false,
          lowStockSchedule: this.tempLowStockSchedule,
          inventoryReportSchedule: this.tempInventoryReportSchedule
        }
        request('put', '/sites/info', data)
          .then(data => {
            this.site.name = data.site.name
            this.site.lowStockSms = data.site.lowStockSms
            this.site.lowStockEmail = data.site.lowStockEmail
            this.site.lowStockSchedule = data.site.lowStockSchedule
            this.site.inventoryReportSchedule = data.site.inventoryReportSchedule
            
            this.generalInfoEditing = false
            this.showSnackbar = true
          })
          .catch(err => console.log(err))
      }

    }
  }
}
</script>

<style lang="scss" >
.site-setting {
  // padding: 32px;
  margin-top:26px;
  .User__btn_container {
    text-align: right;
    margin-bottom: 20px;
    position: relative;
    z-index: 0;
    width: 100%;
    .Setting_btn {
      cursor: pointer;
      border-radius: 4px;
      font-size: 1rem;
      height: 48px !important;
      margin-right: 0px;
      border-radius: 4px;
      margin-left: 16px;
      .md-button-content{
          display: flex;
        justify-content: center;
        align-items: center;
        text-transform: none;
      }
    }

   
  }

  .general-info {
    background-color: #fff;
    padding: 32px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    .title {
      font-size: 20px;
      margin: 0;
    }

    .info-fields {
      display: flex;
      flex-wrap: wrap;
      .info-field {
        flex-basis: 33.33%;
        margin-top: 24px;

        label {
          color: #6D6E71;
          font-size: 14px;
        }

        p {
          font-size: 16px;
          margin: 0;
          margin-top: 9px;
        }

        input {
          display: block;
          font-size: 16px;
          padding: 4px 8px;
          margin-top: 12px;
          width: calc(100% - 32px);
        }
      }
    }
  }

  .inventory-info {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    
    .low-stock {
      flex-basis: calc(50% - 16px);
      background-color: #fff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      padding: 32px;

      .title {
        font-size: 20px;
        margin: 0;
      }

      .info-fields {
        .info-field {
          flex-basis: 33.33%;
          // margin-top: 18px;

          label {
            color: #6D6E71;
            font-size: 14px;
          }

          p {
            font-size: 16px;
          }
        }
      }
    }

    .inventory-report {
      flex-basis: calc(50% - 16px);
      background-color: #fff;
      padding: 32px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      .title {
        font-size: 20px;
        margin: 0;
      }

      .info-fields {
        .info-field {
          margin-top: 16px;

          label {
            color: #6D6E71;
            font-size: 14px;
          }

          p {
            font-size: 16px;
          }
        }

        .mail-now {
          button {
            background-color: #4CAF50;
            color: #fff;
            font-size: 14px;
            padding: 8px 16px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            /deep/ .md-icon {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.Setting_btn{
  background-color: #0080F7;
  border-radius: 4px;
  text-transform: none; 
  font-size: 1rem;
  height: 48px !important;
  margin-bottom: 26px;
  margin-right: 0px;
  border-radius: 4px;
  width: 171px;
    .md-button-content{
      display: flex;
      justify-content: center;
      align-items: center;
  }
}
.checkbox-type{
  display: flex;
  flex-direction:flex-start;
}
.time-nofi-radios{
  margin-top:5px;
  display: flex;
  flex-direction: column;
  color: #171A26;
  div{
    display:flex;
    align-items: center;
    
  }
  .custom_time_text{
    text-decoration: underline;
    color:#0080F7;
    cursor: pointer;
  }
  .custom_time_text:hover{
    color:green;
  }
}

.dialog_timeSetting{
   .md-dialog-container
    {
      min-height:301px;
      max-height: 301px;
      width: 680px ;
      padding: 22px 32px 32px 38px !important;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      overflow: visible;
       
    }
    .dialog_update__title{
      
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      div{
        font-size: 1.4rem;
      }
      .icon_close:hover{
        color: red !important;
      }
      
    }
    .dialog_selectors{
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      margin-right: 40px;
      .selector_content{
        margin-bottom: 8px;
        font-weight: bold;
      }
      .selector_container{
        display: flex;
        .dialog_update__selects{
          width: 82px;
        .input_select{
          color: black;
          font-size: 1rem;
          height: 48px;
          width: 82px;
          background-color: #ECECEC;
          border-width: 0px;
          border-radius: 2px;
          padding: 8px;
        }
        .input_select:hover{
          outline: none !important;
          border:1px solid #0080F6 !important ;
        }
        .dialog_update__select{
          display: flex;
          flex-direction: column-reverse;
          position: relative;
          .icon{
            position: absolute;
            left: 55px;
            top:12px;
          }
          .status_lists{
            width: 100%;
            max-height: 200px;
            overflow: auto;
            // overflow: visible;
            background-color: white;
            position: absolute;
            top:50px;
            z-index: 15;
            list-style-type: none;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            li{
              height: 35px;
              display: flex;
              align-items:center;
              justify-content: space-between;
              font-size: 1rem;
              div{
                margin-left: 19px;
              }

            }
            li:hover{
              background-color: #CFECF9;
            }
          }
        }

      }
      }
    }
    .dialog_update__btn{
      height: 50px;
      margin-top: 13px;
      padding: 0px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      position: absolute;
      bottom: 32px;
      width: 610px;
       .btn_update{
        width: 164px;
        height: 50px;
        text-transform: none;
        font-weight: bold;
        border-radius: 4px;
        margin-right: 0px;
      }
      .btn_cancel{
        width: 100px;
        height: 50px;
        text-transform: none;
        font-weight: bold;
        border-radius: 4px;
        margin-right: 14px;
      }
    }
 }
</style>