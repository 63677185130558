<template>
    <div class="customer_home system_container">
        <div class="main_table">
        <md-button class="md-raised md-primary btn_customer" @click="showAddSystemForm"> <md-icon class="md-size-2x">add</md-icon>Add System</md-button>
            <md-table v-model="systems" md-card style="max-height:540px">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell class="systems_cell" md-label="System Name" md-sort-by="name">{{ item.name }}</md-table-cell>
                <md-table-cell md-label="Description" class="description_cell" md-sort-by="description">{{ item.description }}</md-table-cell>
                <md-table-cell md-label="Status" class="status_cell" md-sort-by="status"><div :style="{color:item.active?'green':'red'}">{{item.active ? 'Active' : 'Inactive'}}</div></md-table-cell>
                <md-table-cell md-label="" md-sort-by="func" >
                    <md-menu md-size="medium" md-align-trigger>
                    <md-button md-menu-trigger><md-icon>more_horiz</md-icon></md-button>
                    <md-menu-content>
                        <md-menu-item @click="showUpdate(item)">Update</md-menu-item>
                        <md-menu-item @click="showDelete(item)"><div style="color:red">Delete</div></md-menu-item>
                    </md-menu-content>
                    </md-menu>
                </md-table-cell>
                </md-table-row>
            </md-table>
        </div>
      
    <md-dialog :md-active.sync="delete_active" class="dialog_delete">
        <md-dialog-title class="dialog_delete__title">
          <div><strong>Delete System</strong></div>
          <div @click="delete_active=false"><md-icon class="icon_close">clear</md-icon></div>
        </md-dialog-title>
        <md-dialog-content class="dialog_delete___content">
          <span>Do you want to delete this system?</span>
          <br>
          <span>All related data will alse be deleted.</span>
        </md-dialog-content>
        <md-dialog-actions class="dialog_delete__button">
          <md-button class="md-raised btn_cancel" @click="delete_active = false">Cancel</md-button>
          <md-button class="md-raised md-accent btn_delete" @click="handleDeleteSystem">Delete</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="update_active" class=" dialog_updateSystem ">
      <div class="dialog_update__title">
        <div><strong>Update System</strong></div>
        <div @click="update_active=false"><md-icon class="icon_close">clear</md-icon></div>
      </div>
      <form class="dialog_update__texts" ref="updateSystemForm">
        <div class="dialog_update__text_1">
          <input type="text"  class="input_text" v-model="tempSystem.name" required>
          <div class="label">
            System Name*
          </div>
        </div>
        <div class="dialog_update_radios">
            <div class="label">
                Status
            </div>
            <div class="dialog_update_radio">
                <md-radio v-model="tempSystem.active" class="md-primary" :value="true">Active</md-radio>
                <md-radio v-model="tempSystem.active" class="md-primary" :value="false">Inactive</md-radio>
            </div>
        </div>
         <div class="dialog_update__text_1">
          <input type="text"  class="input_text" v-model="tempSystem.description">
          <div class="label">
            Description
          </div>
        </div>
      </form>
      <div class="dialog_update__btn">
         <md-button class="md-raised btn_cancel" @click="update_active = false">Cancel</md-button>
         <md-button class="md-raised md-primary btn_update" @click="handleUpdateSystem">Update</md-button>
      </div>
    </md-dialog>
    
    <md-dialog :md-active.sync="add_active" class="  dialog_updateSystem ">
      <div class="dialog_update__title">
        <div><strong>Add System</strong></div>
        <div @click="add_active=false"><md-icon class="icon_close">clear</md-icon></div>
      </div>
      <form class="dialog_update__texts" ref="addSystemForm">
        <div class="dialog_update__text_1">
          <input type="text"  class="input_text" v-model="tempSystem.name" required>
          <div class="label">
            System Name*
          </div>
        </div>
        <div class="dialog_update_radios">
            <div class="label">
                Status
            </div>
            <div class="dialog_update_radio">
                <md-radio v-model="tempSystem.active" class="md-primary" :value="true">Active</md-radio>
                <md-radio v-model="tempSystem.active" class="md-primary" :value="false">Inactive</md-radio>
            </div>
        </div>
         <div class="dialog_update__text_1">
          <input type="text"  class="input_text" v-model="tempSystem.description">
          <div class="label">
            Description
          </div>
        </div>
      </form>
      <div class="dialog_update__btn">
         <md-button class="md-raised btn_cancel" @click="add_active = false">Cancel</md-button>
         <md-button class="md-raised md-primary btn_update" @click="handleAddSystem">Add</md-button>
      </div>
    </md-dialog>
</div>
</template>
<script>
import request from '@/utils/request'

export default {
  name:'CustomerSystem',
  created() {
    request('get', '/systems')
      .then(data => {
        this.systems = data.systems
      })
      .catch(err => console.log(err))
  },
  data(){
    return{
      update_active:false,
      delete_active:false,
      add_active:false,
      radio:'',
      systems: [],
      tempSystem: {}
    }
  },
  methods:{
    showAddSystemForm() {
      this.tempSystem = {
        name: '',
        description: '',
        active: true
      }
      this.add_active=true
    },
    showUpdate(el){
      this.tempSystem={
        ...el
      }
      this.update_active=true
    },
    showDelete(el){ 
      this.tempSystem={
        ...el
      }
      this.delete_active=true;
    },
    handleAddSystem() {
      if (this.$refs.addSystemForm.reportValidity()) {
        request('post', '/systems', this.tempSystem)
          .then(data => {
            this.systems.push(data.system)
            this.add_active = false
          })
          .catch(err => {
            console.log(err)
            alert(err.message)
          })
      }
    },
    handleUpdateSystem() {
      if (this.$refs.updateSystemForm.reportValidity()) {
        request('put', '/systems', this.tempSystem)
          .then(data => {
            for (let i = 0; i < this.systems.length; i++) {
              if (this.systems[i]._id == data.system._id) {
                this.systems[i] = data.system
                break
              }
            }
            this.update_active = false
          })
          .catch(err => {
            alert(err.message)
          })
      }
    },
    handleDeleteSystem() {
      request('delete', `/systems/${this.tempSystem._id}`)
        .then(() => {
          for (let i = 0; i < this.systems.length; i++) {
            if (this.systems[i]._id == this.tempSystem._id) {
              this.systems.splice(i, 1)
              break
            }
          }
          this.delete_active=false;
        })
        .catch(err => {
          alert(err.message)
        })
    }
  },
  watch:{
    site_selected:function(){
      if(this.site_selected.length>0)
      {
        this.num_sites_selected=this.site_selected.length+" Sites/Projects";
      }
      else
      {
        this.num_sites_selected='';
      }
    }
  }
}
</script>
<style lang="scss" >
 .system_container{
    .main_table{
        align-items:flex-end;
        .md-table-cell{
            height: 56px !important;
        }
        .systems_cell{
            width: 410px;
        }
        .description_cell{
            width: 610px;
        }
        .status_cell{
            width: 229px;
        }
    }
    
 }
.dialog_updateSystem{
    .md-dialog-container
    {
      min-height: 446px ;
      max-height: 446px;
      width: 516px ;
      padding: 22px 32px 32px 38px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
    }
    .dialog_update__title{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      div{
        font-size: 1.4rem;
      }
      .icon_close:hover{
        color: red !important;
      }
      
    }
    .dialog_update__texts{
      height: 242px;
      // width: 100%;
      .label{
        font-size: 0.8rem;
        color:#6D6E71;
        margin-bottom: 5px;
      }
      .input_text{
        color: black;
        font-size: 1rem;
        height: 48px;
        background-color: #ECECEC;
        border-width: 0px;
        border-radius: 2px;
        padding: 8px;
      }
      .input_text:focus{
        outline: none !important;
        border:1px solid #0080F6 !important ;
      }
      .input_text:focus + div {
        color:#0080F6;
      }

      .dialog_update__text_1{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 20px;
      }
      .dialog_update_radios{
          margin-top: 24px;
          .md-primary{
              margin-right: 58px;
          }
      }
    }
    .dialog_update__btn{
      margin-top:72px;
      height: 50px;
      padding: 0px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
       .btn_update{
        width: 164px;
        height: 50px;
        text-transform: none;
        font-weight: bold;
        border-radius: 4px;
        margin-right: 0px;
      }
      .btn_cancel{
        width: 100px;
        height: 50px;
        text-transform: none;
        font-weight: bold;
        border-radius: 4px;
        margin-right: 14px;
      }
    }
  }
</style>