<template>
    <div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 14C4.48571 14 3.94286 13.7714 3.57143 13.4C3.2 13.0286 3 12.5143 3 12C3 11.4857 3.22857 10.9714 3.57143 10.5714C3.94286 10.2 4.45714 10 5 10C5.51429 10 6.02857 10.2286 6.4 10.5714C6.77143 10.9429 7 11.4571 7 12C7 12.5143 6.77143 13.0286 6.4 13.4C6.02857 13.7714 5.51429 14 5 14ZM13.5429 13.4C13.9143 13.0286 14.1429 12.5143 14.1429 12C14.1429 11.4857 13.9143 10.9714 13.5429 10.5714C13.1714 10.2 12.6571 10 12.1429 10C11.6286 10 11.0857 10.2286 10.7143 10.5714C10.3429 10.9429 10.1429 11.4571 10.1429 12C10.1429 12.5143 10.3714 13.0286 10.7143 13.4C11.0857 13.7714 11.6 14 12.1429 14C12.6571 14 13.1714 13.7714 13.5429 13.4ZM20.6857 13.4C21.0571 13.0286 21.2857 12.5143 21.2857 12C21.2857 11.4857 21.0571 10.9714 20.6857 10.5714C20.3143 10.2 19.8 10 19.2857 10C18.7714 10 18.2286 10.2286 17.8571 10.5714C17.4857 10.9429 17.2857 11.4571 17.2857 12C17.2857 12.5143 17.5143 13.0286 17.8571 13.4C18.2286 13.7714 18.7429 14 19.2857 14C19.8 14 20.3143 13.7714 20.6857 13.4Z" fill="#6D6E71"/>
        </svg>
    </div>
</template>
<script>
export default{
    name:'Dot'
}
</script>
<style scoped>
    svg:hover path{
        fill:#0080F6 ;
    }
</style>