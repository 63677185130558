<template>
  <div id="app">
    <svg  class="vector" width="537" height="496" viewBox="0 0 537 496" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M284.478 19.6866L384.318 1.24414L384.006 1.55541L264.837 113.533L284.478 19.6866ZM383.46 1.71104L284.712 19.92L265.305 112.755L383.46 1.71104Z" fill="#0080F7"/>
<path d="M283.854 19.376L284.244 19.6094L376.913 73.4582L376.758 73.6916L284.634 20.1541L369.197 159.601L265.071 113.3L186.898 174.386L186.742 174.152L265.071 112.989L265.149 113.067L368.496 159.056L283.854 19.376Z" fill="#0080F7"/>
<path d="M264.915 113.222L265.149 113.145L281.672 166.371L369.042 159.212L368.886 159.445L309.262 256.326L281.438 166.682L186.742 174.464V174.23L281.36 166.449L264.915 113.222ZM368.574 159.523L281.75 166.604L309.418 255.626L368.574 159.523Z" fill="#0080F7"/>
<path d="M186.586 173.919L186.898 174.152L309.808 256.093H309.34L215.657 255.937V255.859L186.586 173.919ZM308.95 255.782L187.054 174.619L215.891 255.626L308.95 255.782Z" fill="#0080F7"/>
<path d="M88.9287 278.27L216.125 255.548L154.398 324.726L309.34 255.859L309.418 256.093L153.384 325.427L153.774 324.96L215.424 255.937L89.0066 278.504L88.9287 278.27Z" fill="#0080F7"/>
<path d="M88.6948 278.426L88.9286 278.27L233.194 205.123L293.986 206.135V206.368L233.272 205.356L89.2404 278.426L154.008 324.96L215.58 362.934L215.658 255.781H215.891L215.813 363.401L215.658 363.245L153.852 325.193L88.6948 278.426Z" fill="#0080F7"/>
<path d="M281.984 166.293L282.062 166.371L376.056 242.008L375.822 242.086L309.418 256.093L215.735 363.323H215.657L130.158 362.001L98.593 410.869L98.3592 410.713L129.847 362.001L72.0938 361.145L154.241 324.804L154.008 325.194L130.314 361.767L215.58 363.012L309.184 255.782H309.262L375.432 241.852L281.984 166.604L233.583 205.279L233.428 205.045L281.984 166.293ZM153.54 325.349L73.2628 360.911L130.002 361.767L153.54 325.349Z" fill="#0080F7"/>
<path d="M88.8508 278.348L89.0846 278.425L72.8733 360.988L98.5932 410.635L215.813 363.012V363.167L227.816 411.335L98.5932 410.868L49.3357 478.646L72.5615 360.988L88.8508 278.348ZM98.3593 410.791L72.7953 361.455L49.8033 477.557L98.3593 410.791ZM215.658 363.323L99.2167 410.635L227.504 411.024L215.658 363.323Z" fill="#0080F7"/>
<path d="M41.152 232.903L89.2404 278.504L53.4664 281.927L72.9512 361.456L1.3252 286.752L53.1547 281.772L41.152 232.903ZM88.6949 278.27L41.6976 233.681L53.3885 281.694L88.6949 278.27ZM53.2326 282.005L1.87077 286.986L72.4835 360.6L53.2326 282.005Z" fill="#0080F7"/>
<path d="M312.848 67.6221L313.315 67.6999L435.29 78.5163L351.739 95.6359L419.078 144.582L418.845 144.66L368.808 159.523L215.502 256.093L264.993 113.067H265.071L351.194 95.4802L312.848 67.6221ZM433.497 78.5941L313.783 68.0112L351.505 95.4024L433.497 78.5941ZM351.427 95.6359L265.149 113.3L215.969 255.47L368.73 159.212L418.533 144.427L351.427 95.6359Z" fill="#0080F7"/>
<path d="M368.73 159.367H368.964L375.9 242.008L360.624 277.492L447.059 314.066L446.981 314.299L360.546 277.726L338.022 329.94L309.106 255.626L360.39 277.336L375.666 241.852L368.73 159.367ZM360.312 277.648L309.652 256.17L338.1 329.318L360.312 277.648Z" fill="#0080F7"/>
<path d="M1.63702 286.674L153.93 324.959L153.852 325.193L1.55908 286.985L1.63702 286.674Z" fill="#0080F7"/>
<path d="M53.1547 281.771L53.3885 281.849L31.3318 359.899L72.6395 360.91L203.733 219.752L154.086 325.115C154.709 327.449 176.143 407.6 177.078 410.868V410.946L193.523 473.355L97.8917 409.935L98.0476 409.701L193.055 472.732L176.766 410.946C176.688 410.713 176.454 409.857 175.753 407.289C175.129 404.954 174.194 401.608 173.103 397.562C170.921 389.469 168.037 378.73 165.153 367.992C159.386 346.514 153.618 325.037 153.618 325.037V324.959V324.882L202.564 221.075L72.7953 361.144H72.7174L31.02 360.132L31.098 359.977L53.1547 281.771Z" fill="#0080F7"/>
<path d="M383.46 1.08887L434.588 78.6715L434.355 78.8272L383.616 1.86703L368.964 159.367L462.724 239.673H462.335L376.134 242.163L447.292 314.377L447.059 314.454L338.022 329.862L263.824 225.9L226.881 411.802L226.647 411.725L263.668 225.277L263.824 225.511L338.1 329.551L446.747 314.221L375.511 241.93L462.023 239.44L368.73 159.445V159.367L383.46 1.08887Z" fill="#0080F7"/>
<path d="M418.767 144.504L419 144.426L447.136 314.221L446.903 314.298L418.767 144.504Z" fill="#0080F7"/>
<path d="M315.186 192.983L424.534 206.99V207.224L315.186 193.295V192.983Z" fill="#0080F7"/>
<path d="M313.237 67.7773L313.471 67.8552L281.984 166.37L281.672 166.293L313.237 67.7773Z" fill="#0080F7"/>
<path d="M301.391 105.674L368.886 159.211L368.73 159.445L301.235 105.907L301.391 105.674Z" fill="#0080F7"/>
<path d="M434.355 78.5938L434.588 78.7494L369.042 159.289L460.776 181.389L460.542 181.544L375.822 242.085H375.744L294.766 206.757L360.546 277.57L360.312 277.725L293.675 205.979L375.744 241.774L460.152 181.467L368.574 159.445L368.73 159.289L434.355 78.5938Z" fill="#0080F7"/>
<path d="M161.334 265.431L161.568 265.508L154.008 325.116L153.774 325.038L161.334 265.431Z" fill="#0080F7"/>
<path d="M88.8508 278.426H89.0846L98.6711 410.791H98.3594L88.8508 278.426Z" fill="#0080F7"/>
<path d="M183.781 261.462L184.014 261.384L215.813 363.089L215.58 363.167L183.781 261.462Z" fill="#0080F7"/>
<path d="M31.02 359.666L31.2538 359.899L98.5932 410.713H98.6711L113.557 488.996L215.658 363.012L337.554 329.629L215.736 297.724L215.813 297.413L338.567 329.629L338.1 329.785L215.813 363.246L113.402 489.619V489.386L98.3593 410.869L31.3318 360.289L49.7254 478.102H49.4136L31.02 359.666Z" fill="#0080F7"/>
<path d="M234.051 204.267C235.766 205.201 235.142 207.613 233.194 207.535C233.038 207.535 232.804 207.457 232.648 207.38C230.933 206.446 231.557 204.033 233.505 204.111C233.661 204.111 233.895 204.189 234.051 204.267Z" fill="#0080F7"/>
<path d="M246.443 168.472C248.158 169.406 247.534 171.818 245.586 171.74C245.43 171.74 245.196 171.662 245.04 171.585C243.326 170.651 243.949 168.238 245.898 168.316C246.054 168.316 246.287 168.394 246.443 168.472Z" fill="#0080F7"/>
<path d="M265.85 112.211C267.565 113.145 266.941 115.557 264.993 115.479C264.837 115.479 264.603 115.402 264.447 115.324C262.733 114.39 263.356 111.978 265.305 112.056C265.539 112.056 265.694 112.133 265.85 112.211Z" fill="#0080F7"/>
<path d="M285.257 18.9094C286.972 19.8432 286.348 22.2555 284.4 22.1777C284.244 22.1777 284.01 22.0999 283.854 22.0221C282.139 21.0883 282.763 18.676 284.711 18.7538C284.945 18.7538 285.101 18.8316 285.257 18.9094Z" fill="#0080F7"/>
<path d="M302.17 104.819C303.885 105.752 303.261 108.165 301.313 108.087C301.157 108.087 300.923 108.009 300.767 107.931C299.052 106.997 299.676 104.585 301.624 104.663C301.78 104.663 302.014 104.741 302.17 104.819Z" fill="#0080F7"/>
<path d="M369.431 157.734C371.146 158.667 370.522 161.08 368.574 161.002C368.418 161.002 368.184 160.924 368.028 160.846C366.314 159.913 366.937 157.5 368.886 157.578C369.119 157.578 369.275 157.656 369.431 157.734Z" fill="#0080F7"/>
<path d="M419.546 143.182C421.261 144.116 420.637 146.528 418.689 146.45C418.533 146.45 418.299 146.372 418.143 146.295C416.429 145.361 417.052 142.948 419.001 143.026C419.234 143.104 419.39 143.104 419.546 143.182Z" fill="#0080F7"/>
<path d="M435.056 77.1936C436.771 78.1274 436.147 80.5397 434.198 80.4619C434.043 80.4619 433.809 80.3841 433.653 80.3062C431.938 79.3725 432.562 76.9602 434.51 77.038C434.744 77.1158 434.9 77.1158 435.056 77.1936Z" fill="#0080F7"/>
<path d="M461.088 179.833C462.802 180.767 462.179 183.179 460.23 183.102C460.074 183.102 459.841 183.024 459.685 182.946C457.97 182.012 458.594 179.6 460.542 179.678C460.776 179.755 460.932 179.755 461.088 179.833Z" fill="#0080F7"/>
<path d="M462.958 238.117C464.673 239.051 464.049 241.464 462.101 241.386C461.945 241.386 461.711 241.308 461.555 241.23C459.84 240.296 460.464 237.884 462.412 237.962C462.646 237.962 462.802 238.04 462.958 238.117Z" fill="#0080F7"/>
<path d="M315.965 192.128C317.68 193.062 317.056 195.474 315.108 195.396C314.952 195.396 314.718 195.319 314.562 195.241C312.847 194.307 313.471 191.895 315.419 191.973C315.653 192.05 315.809 192.05 315.965 192.128Z" fill="#0080F7"/>
<path d="M295.155 125.283C296.87 126.217 296.246 128.63 294.298 128.552C294.142 128.552 293.908 128.474 293.752 128.396C292.038 127.462 292.661 125.05 294.61 125.128C294.843 125.128 294.999 125.206 295.155 125.283Z" fill="#0080F7"/>
<path d="M294.921 205.435C296.636 206.369 296.012 208.781 294.064 208.703C293.908 208.703 293.674 208.625 293.518 208.547C291.804 207.614 292.427 205.201 294.376 205.279C294.532 205.357 294.765 205.357 294.921 205.435Z" fill="#0080F7"/>
<path d="M264.603 224.577C266.318 225.511 265.694 227.923 263.746 227.846C263.59 227.846 263.356 227.768 263.2 227.69C261.486 226.756 262.109 224.344 264.058 224.422C264.291 224.422 264.447 224.5 264.603 224.577Z" fill="#0080F7"/>
<path d="M216.593 295.935C218.307 296.869 217.684 299.281 215.735 299.203C215.58 299.203 215.346 299.125 215.19 299.047C213.475 298.114 214.099 295.701 216.047 295.779C216.281 295.779 216.437 295.857 216.593 295.935Z" fill="#0080F7"/>
<path d="M204.045 219.442C205.759 220.375 205.136 222.788 203.187 222.71C203.031 222.71 202.798 222.632 202.642 222.554C200.927 221.621 201.551 219.208 203.499 219.286C203.733 219.364 203.889 219.364 204.045 219.442Z" fill="#0080F7"/>
<path d="M184.716 260.45C186.43 261.384 185.807 263.797 183.858 263.719C183.703 263.719 183.469 263.641 183.313 263.563C181.598 262.629 182.222 260.217 184.17 260.295C184.404 260.295 184.56 260.373 184.716 260.45Z" fill="#0080F7"/>
<path d="M162.269 264.497C163.984 265.431 163.36 267.843 161.412 267.766C161.256 267.766 161.022 267.688 160.866 267.61C159.152 266.676 159.775 264.264 161.724 264.342C161.88 264.342 162.113 264.419 162.269 264.497Z" fill="#0080F7"/>
<path d="M126.028 303.561C127.742 304.495 127.119 306.907 125.17 306.829C125.014 306.829 124.781 306.751 124.625 306.673C122.91 305.74 123.533 303.327 125.482 303.405C125.716 303.405 125.872 303.405 126.028 303.561Z" fill="#0080F7"/>
<path d="M89.7858 277.414C91.5005 278.348 90.877 280.76 88.9285 280.683C88.7726 280.683 88.5388 280.605 88.3829 280.527C86.6683 279.593 87.2918 277.181 89.2403 277.259C89.3961 277.259 89.63 277.337 89.7858 277.414Z" fill="#0080F7"/>
<path d="M99.3725 409.468C101.087 410.402 100.464 412.814 98.5152 412.736C98.3593 412.736 98.1255 412.658 97.9696 412.581C96.2549 411.647 96.8785 409.235 98.8269 409.312C98.9828 409.312 99.2166 409.39 99.3725 409.468Z" fill="#0080F7"/>
<path d="M73.5744 360.055C75.2891 360.989 74.6656 363.401 72.7171 363.323C72.5612 363.323 72.3274 363.245 72.1715 363.168C70.4569 362.234 71.0804 359.821 73.0288 359.899C73.1847 359.899 73.3406 359.899 73.5744 360.055Z" fill="#0080F7"/>
<path d="M31.8772 359.277C33.5918 360.21 32.9683 362.623 31.0198 362.545C30.8639 362.545 30.6301 362.467 30.4743 362.389C28.7596 361.455 29.3831 359.043 31.3316 359.121C31.5654 359.121 31.7213 359.199 31.8772 359.277Z" fill="#0080F7"/>
<path d="M68.1967 386.435C69.9114 387.369 69.2879 389.781 67.3394 389.703C67.1835 389.703 66.9497 389.625 66.7938 389.547C65.0792 388.614 65.7027 386.201 67.6512 386.279C67.885 386.279 68.0409 386.357 68.1967 386.435Z" fill="#0080F7"/>
<path d="M50.5825 476.623C52.2971 477.557 51.6736 479.969 49.7251 479.892C49.5693 479.892 49.3354 479.814 49.1796 479.736C47.4649 478.802 48.0884 476.39 50.0369 476.468C50.2707 476.468 50.4266 476.468 50.5825 476.623Z" fill="#0080F7"/>
<path d="M114.337 488.374C116.051 489.308 115.428 491.72 113.479 491.642C113.323 491.642 113.09 491.565 112.934 491.487C111.219 490.553 111.843 488.141 113.791 488.219C113.947 488.219 114.181 488.296 114.337 488.374Z" fill="#0080F7"/>
<path d="M150.656 442.774C152.371 443.707 151.747 446.12 149.799 446.042C149.643 446.042 149.409 445.964 149.253 445.886C147.539 444.953 148.162 442.54 150.111 442.618C150.267 442.618 150.5 442.696 150.656 442.774Z" fill="#0080F7"/>
<path d="M194.068 471.954C195.783 472.888 195.159 475.3 193.211 475.223C193.055 475.223 192.821 475.145 192.665 475.067C190.951 474.133 191.574 471.721 193.523 471.799C193.757 471.877 193.912 471.877 194.068 471.954Z" fill="#0080F7"/>
<path d="M177.623 409.468C179.338 410.402 178.714 412.814 176.766 412.736C176.61 412.736 176.376 412.658 176.22 412.581C174.506 411.647 175.129 409.235 177.078 409.312C177.311 409.312 177.467 409.39 177.623 409.468Z" fill="#0080F7"/>
<path d="M228.595 409.701C230.31 410.635 229.687 413.048 227.738 412.97C227.582 412.97 227.348 412.892 227.193 412.814C225.478 411.88 226.101 409.468 228.05 409.546C228.206 409.546 228.44 409.546 228.595 409.701Z" fill="#0080F7"/>
<path d="M42.477 232.904C44.1917 233.837 43.5682 236.25 41.6197 236.172C41.4638 236.172 41.23 236.094 41.0741 236.016C39.3594 235.082 39.983 232.67 41.9314 232.748C42.1652 232.826 42.3211 232.826 42.477 232.904Z" fill="#0080F7"/>
<path d="M2.41622 285.819C4.13087 286.752 3.50736 289.165 1.55889 289.087C1.40301 289.087 1.16919 289.009 1.01331 288.931C-0.701346 287.997 -0.077834 285.585 1.87064 285.663C2.02652 285.741 2.26034 285.741 2.41622 285.819Z" fill="#0080F7"/>
<path d="M216.203 361.456C217.918 362.39 217.294 364.802 215.346 364.725C215.19 364.725 214.956 364.647 214.8 364.569C213.086 363.635 213.709 361.223 215.658 361.301C215.891 361.301 216.047 361.378 216.203 361.456Z" fill="#0080F7"/>
<path d="M310.197 254.926C311.912 255.86 311.289 258.272 309.34 258.194C309.184 258.194 308.95 258.116 308.795 258.039C307.08 257.105 307.703 254.693 309.652 254.77C309.808 254.848 310.042 254.848 310.197 254.926Z" fill="#0080F7"/>
<path d="M338.567 327.995C340.282 328.929 339.658 331.342 337.71 331.264C337.554 331.264 337.32 331.186 337.164 331.108C335.45 330.174 336.073 327.762 338.022 327.84C338.256 327.918 338.411 327.918 338.567 327.995Z" fill="#0080F7"/>
<path d="M361.248 276.636C362.962 277.57 362.339 279.982 360.39 279.904C360.234 279.904 360.001 279.826 359.845 279.749C358.13 278.815 358.753 276.403 360.702 276.48C360.936 276.48 361.092 276.558 361.248 276.636Z" fill="#0080F7"/>
<path d="M376.524 240.763C378.238 241.697 377.615 244.109 375.666 244.031C375.51 244.031 375.277 243.953 375.121 243.876C373.406 242.942 374.03 240.529 375.978 240.607C376.134 240.685 376.368 240.685 376.524 240.763Z" fill="#0080F7"/>
<path d="M447.448 312.665C449.163 313.599 448.539 316.011 446.591 315.934C446.435 315.934 446.201 315.856 446.045 315.778C444.331 314.844 444.954 312.432 446.903 312.51C447.058 312.587 447.292 312.587 447.448 312.665Z" fill="#0080F7"/>
<path d="M425.391 206.135C427.106 207.069 426.482 209.481 424.534 209.403C424.378 209.403 424.144 209.325 423.988 209.248C422.274 208.314 422.897 205.902 424.846 205.979C425.002 205.979 425.157 206.057 425.391 206.135Z" fill="#0080F7"/>
<path d="M334.592 47.6233C336.307 48.5571 335.684 50.9694 333.735 50.8916C333.579 50.8916 333.345 50.8137 333.19 50.7359C331.475 49.8021 332.098 47.3898 334.047 47.4677C334.203 47.5455 334.437 47.5455 334.592 47.6233Z" fill="#0080F7"/>
<path d="M384.473 0.155632C386.188 1.08943 385.564 3.50173 383.616 3.42391C383.46 3.42391 383.226 3.34609 383.07 3.26828C381.356 2.33448 381.979 0 383.928 0C384.084 0 384.317 0.0778161 384.473 0.155632Z" fill="#0080F7"/>
<path d="M377.303 71.9026C379.018 72.8364 378.394 75.2487 376.446 75.1709C376.29 75.1709 376.056 75.0931 375.9 75.0152C374.185 74.0814 374.809 71.6691 376.757 71.747C376.991 71.747 377.147 71.8248 377.303 71.9026Z" fill="#0080F7"/>
<path d="M281.672 169.794C283.48 169.794 284.945 168.331 284.945 166.526C284.945 164.721 283.48 163.258 281.672 163.258C279.864 163.258 278.398 164.721 278.398 166.526C278.398 168.331 279.864 169.794 281.672 169.794Z" fill="#0080F7"/>
<path d="M313.237 71.1235C315.045 71.1235 316.51 69.6602 316.51 67.8552C316.51 66.0502 315.045 64.5869 313.237 64.5869C311.429 64.5869 309.964 66.0502 309.964 67.8552C309.964 69.6602 311.429 71.1235 313.237 71.1235Z" fill="#0080F7"/>
<path d="M187.365 177.577C189.173 177.577 190.639 176.113 190.639 174.308C190.639 172.503 189.173 171.04 187.365 171.04C185.558 171.04 184.092 172.503 184.092 174.308C184.092 176.113 185.558 177.577 187.365 177.577Z" fill="#0080F7"/>
<path d="M215.891 258.894C217.699 258.894 219.165 257.431 219.165 255.626C219.165 253.821 217.699 252.357 215.891 252.357C214.083 252.357 212.618 253.821 212.618 255.626C212.618 257.431 214.083 258.894 215.891 258.894Z" fill="#0080F7"/>
<path d="M153.93 328.54C155.738 328.54 157.203 327.076 157.203 325.271C157.203 323.466 155.738 322.003 153.93 322.003C152.122 322.003 150.656 323.466 150.656 325.271C150.656 327.076 152.122 328.54 153.93 328.54Z" fill="#0080F7"/>
<path d="M53.2324 285.352C55.0403 285.352 56.5059 283.889 56.5059 282.084C56.5059 280.279 55.0403 278.815 53.2324 278.815C51.4246 278.815 49.959 280.279 49.959 282.084C49.959 283.889 51.4246 285.352 53.2324 285.352Z" fill="#0080F7"/>
<path d="M155.566 294.846L41.2297 411.725L40.918 412.037L61.2601 312.666L155.566 294.846ZM154.787 295.313L61.4939 312.977L41.3856 411.259L154.787 295.313Z" fill="#0080F7"/>
<path d="M60.8701 312.121L61.1819 312.51L113.323 405.967L113.557 405.811L61.6495 312.821L199.835 399.819L155.332 295.079L217.917 218.197L217.683 218.041L155.02 295.079L155.098 295.157L199.212 399.197L60.8701 312.121Z" fill="#0080F7"/>
<path d="M208.175 312.431L217.684 218.118H217.918L208.409 312.509L297.649 341.924L199.524 399.663L199.29 399.819L208.097 312.665L155.099 295.234L155.177 295L208.175 312.431ZM297.026 341.924L208.409 312.743L199.68 399.274L297.026 341.924Z" fill="#0080F7"/>
<path d="M298.896 248.389H298.974L297.415 341.924V342.391L217.684 218.196L217.45 217.885L298.896 248.389ZM298.74 248.544L218.151 218.352L297.259 341.457L298.74 248.544Z" fill="#0080F7"/>
<path d="M323.759 122.327L298.662 248.856L369.041 188.549L297.259 341.846L297.493 342.002L369.821 187.537L369.353 187.926L299.052 248.156L324.07 122.327H323.759Z" fill="#0080F7"/>
<path d="M323.915 122.094L323.759 122.249L247.846 264.964L247.768 325.661H248.002L248.158 265.042L323.915 122.561L369.353 188.082L406.218 250.257L298.896 248.389V248.623L406.686 250.568L406.53 250.335L369.509 187.926L323.915 122.094Z" fill="#0080F7"/>
<path d="M247.846 265.197L248.08 265.353L208.409 312.976L282.061 407.679L297.259 341.924V341.846L406.296 250.334L406.608 165.126L369.509 188.081L369.119 188.315L407.075 106.997L406.842 164.659L456.177 134.155L456.333 134.388L406.842 165.048L406.53 250.412V250.49L297.415 342.002L282.217 407.99L282.139 408.223L208.097 312.976L208.019 312.899L247.846 265.197ZM406.608 164.892L406.842 108.242L369.82 187.692L406.608 164.892Z" fill="#0080F7"/>
<path d="M406.92 107.464L525.231 86.376L456.489 134.311L454.385 263.408L406.374 250.568L406.218 250.49L456.099 134.311L406.92 107.698L323.915 122.405L323.837 122.171L406.92 107.464ZM524.062 86.9207L407.309 107.698L456.255 134.155L524.062 86.9207ZM454.151 263.096L456.099 135.011L406.608 250.412L454.151 263.096Z" fill="#0080F7"/>
<path d="M327.889 86.6094L333.813 34.9395L407.309 107.853L328.123 86.9984L323.992 122.638L279.177 73.7697L327.889 86.6094ZM327.889 86.9206L280.035 74.3144L323.837 122.094L327.889 86.9206ZM406.452 107.386L334.047 35.562L328.123 86.765L406.452 107.386Z" fill="#0080F7"/>
<path d="M135.848 380.676L155.099 295.079V295.001L299.208 248.233L199.602 399.586L183.78 449.232L183.702 449.466L135.926 381.299L117.298 464.329L108.725 342.391V341.924L135.848 380.676ZM135.77 380.988L109.037 342.858L117.454 462.539L135.77 380.988ZM183.624 448.921L199.368 399.508L298.506 248.778L155.254 295.234L136.004 380.988L183.624 448.921Z" fill="#0080F7"/>
<path d="M282.061 407.834L317.835 393.283L297.026 341.69L370.99 371.961L318.225 393.438L353.298 480.437L353.064 480.514L317.991 393.516L282.139 408.145L199.524 399.663V399.43L282.061 407.834ZM370.288 371.961L297.571 342.235L318.147 393.205L370.288 371.961Z" fill="#0080F7"/>
<path d="M334.054 35.2275L333.827 35.2803L369.304 188.019L369.531 187.966L334.054 35.2275Z" fill="#0080F7"/>
<path d="M327.967 86.6874L328.045 86.9208L406.53 66.2995L406.764 107.542L263.044 235.783L369.431 188.16C371.769 188.86 451.579 211.738 454.93 212.672H455.008L517.203 230.258L455.476 133.61L455.242 133.766L516.658 229.791L455.086 212.438C454.852 212.36 453.995 212.127 451.423 211.349C449.085 210.648 445.733 209.715 441.758 208.547C433.731 206.213 423.053 203.178 412.297 200.065C390.864 193.918 369.509 187.771 369.509 187.771H369.431H369.353L264.447 234.771L406.998 107.62V107.542L406.842 65.9883L406.686 66.0661L327.967 86.6874Z" fill="#0080F7"/>
<path d="M40.762 411.18L117.532 463.706L117.688 463.473L41.5414 411.414L199.524 399.664L278.242 494.677V494.366L282.295 408.301L353.375 480.748V480.437L370.834 371.883L268.11 295.935L454.93 262.474L454.852 262.24L267.487 295.779L267.72 295.935L370.6 371.961L353.142 480.203L282.061 407.679L278.008 493.977L199.68 399.352H199.602L40.762 411.18Z" fill="#0080F7"/>
<path d="M183.699 449.075L183.656 449.305L353.178 480.667L353.221 480.437L183.699 449.075Z" fill="#0080F7"/>
<path d="M234.466 346.509L234.234 346.534L246.186 455.995L246.418 455.969L234.466 346.509Z" fill="#0080F7"/>
<path d="M208.028 312.599L108.818 342.311L108.885 342.534L208.095 312.822L208.028 312.599Z" fill="#0080F7"/>
<path d="M147.201 331.11L147.015 331.252L199.391 399.604L199.577 399.463L147.201 331.11Z" fill="#0080F7"/>
<path d="M117.454 463.473L117.61 463.707L199.446 399.742L219.866 491.721L220.022 491.487L282.295 407.99L282.217 407.913L248.314 326.439L318.069 393.439L318.225 393.205L247.612 325.35L281.983 407.99L220.022 491.098L199.602 399.275L199.446 399.431L117.454 463.473Z" fill="#0080F7"/>
<path d="M369.387 187.899L309.567 194.345L309.592 194.577L369.412 188.131L369.387 187.899Z" fill="#0080F7"/>
<path d="M323.885 122.295L323.864 122.527L456.205 134.458L456.226 134.226L323.885 122.295Z" fill="#0080F7"/>
<path d="M305.144 216.716L305.07 216.938L406.327 250.526L406.401 250.305L305.144 216.716Z" fill="#0080F7"/>
<path d="M406.374 65.9883L406.608 66.2217L456.177 134.389H456.255L534.35 150.808L406.296 250.335V250.412L370.678 371.417L340.905 249.245H340.594L370.6 372.428L370.756 371.961L406.53 250.49L534.973 150.652L534.74 150.574L456.333 134.155L406.998 66.3774L524.608 86.843V86.5317L406.374 65.9883Z" fill="#0080F7"/>
<path d="M246.989 265.742C247.924 267.454 250.262 266.909 250.262 264.964C250.262 264.808 250.184 264.575 250.106 264.419C249.171 262.707 246.833 263.252 246.833 265.197C246.833 265.431 246.911 265.586 246.989 265.742Z" fill="#0080F7"/>
<path d="M216.826 218.897C217.762 220.609 220.1 220.065 220.1 218.119C220.1 217.964 220.022 217.73 219.944 217.575C219.009 215.863 216.67 216.407 216.67 218.353C216.67 218.586 216.748 218.742 216.826 218.897Z" fill="#0080F7"/>
<path d="M210.903 277.493C211.838 279.205 214.176 278.66 214.176 276.715C214.176 276.559 214.098 276.326 214.021 276.17C213.085 274.458 210.747 275.003 210.747 276.948C210.747 277.104 210.825 277.337 210.903 277.493Z" fill="#0080F7"/>
<path d="M154.163 295.857C155.099 297.569 157.437 297.025 157.437 295.079C157.437 294.924 157.359 294.69 157.281 294.534C156.346 292.823 154.008 293.367 154.008 295.313C154.086 295.546 154.086 295.702 154.163 295.857Z" fill="#0080F7"/>
<path d="M60.4027 313.521C61.338 315.233 63.6761 314.689 63.6761 312.743C63.6761 312.588 63.5982 312.354 63.5203 312.199C62.585 310.487 60.2468 311.031 60.2468 312.977C60.3248 313.132 60.3248 313.366 60.4027 313.521Z" fill="#0080F7"/>
<path d="M107.868 343.247C108.803 344.959 111.141 344.414 111.141 342.469C111.141 342.313 111.063 342.08 110.985 341.924C110.05 340.212 107.712 340.757 107.712 342.702C107.712 342.858 107.79 343.091 107.868 343.247Z" fill="#0080F7"/>
<path d="M146.136 331.964C147.071 333.676 149.409 333.131 149.409 331.186C149.409 331.03 149.331 330.797 149.253 330.641C148.318 328.929 145.98 329.474 145.98 331.419C145.98 331.575 146.058 331.808 146.136 331.964Z" fill="#0080F7"/>
<path d="M197.887 400.131C198.822 401.843 201.161 401.298 201.161 399.353C201.161 399.197 201.083 398.964 201.005 398.808C200.069 397.096 197.731 397.641 197.731 399.586C197.731 399.742 197.809 399.975 197.887 400.131Z" fill="#0080F7"/>
<path d="M182.455 449.855C183.39 451.567 185.729 451.023 185.729 449.077C185.729 448.922 185.651 448.688 185.573 448.533C184.637 446.821 182.299 447.365 182.299 449.311C182.299 449.544 182.377 449.7 182.455 449.855Z" fill="#0080F7"/>
<path d="M116.051 464.096C116.986 465.808 119.325 465.263 119.325 463.318C119.325 463.162 119.247 462.928 119.169 462.773C118.233 461.061 115.895 461.606 115.895 463.551C115.895 463.784 115.973 463.94 116.051 464.096Z" fill="#0080F7"/>
<path d="M218.385 491.954C219.32 493.666 221.659 493.121 221.659 491.176C221.659 491.02 221.581 490.787 221.503 490.631C220.567 488.919 218.229 489.464 218.229 491.409C218.229 491.643 218.307 491.798 218.385 491.954Z" fill="#0080F7"/>
<path d="M276.683 494.989C277.619 496.701 279.957 496.156 279.957 494.211C279.957 494.055 279.879 493.822 279.801 493.666C278.866 491.954 276.528 492.499 276.528 494.445C276.528 494.6 276.606 494.756 276.683 494.989Z" fill="#0080F7"/>
<path d="M233.349 347.372C234.285 349.084 236.623 348.539 236.623 346.594C236.623 346.438 236.545 346.205 236.467 346.049C235.532 344.337 233.194 344.882 233.194 346.827C233.194 346.983 233.272 347.216 233.349 347.372Z" fill="#0080F7"/>
<path d="M166.712 325.35C167.647 327.062 169.985 326.517 169.985 324.571C169.985 324.416 169.907 324.182 169.829 324.027C168.894 322.315 166.556 322.859 166.556 324.805C166.634 324.96 166.634 325.194 166.712 325.35Z" fill="#0080F7"/>
<path d="M247.067 326.595C248.002 328.307 250.34 327.762 250.34 325.817C250.34 325.661 250.262 325.427 250.184 325.272C249.249 323.56 246.911 324.105 246.911 326.05C246.911 326.206 246.989 326.439 247.067 326.595Z" fill="#0080F7"/>
<path d="M266.707 296.713C267.643 298.425 269.981 297.88 269.981 295.935C269.981 295.779 269.903 295.546 269.825 295.39C268.89 293.678 266.552 294.223 266.552 296.168C266.629 296.324 266.629 296.557 266.707 296.713Z" fill="#0080F7"/>
<path d="M297.805 249.245C298.74 250.957 301.079 250.412 301.079 248.467C301.079 248.311 301.001 248.078 300.923 247.922C299.987 246.21 297.649 246.755 297.649 248.7C297.649 248.856 297.727 249.089 297.805 249.245Z" fill="#0080F7"/>
<path d="M339.113 250.023C340.048 251.735 342.386 251.191 342.386 249.245C342.386 249.09 342.308 248.856 342.23 248.701C341.295 246.989 338.957 247.533 338.957 249.479C338.957 249.712 339.035 249.868 339.113 250.023Z" fill="#0080F7"/>
<path d="M262.733 236.095C263.668 237.807 266.006 237.262 266.006 235.316C266.006 235.161 265.928 234.927 265.85 234.772C264.915 233.06 262.577 233.605 262.577 235.55C262.655 235.783 262.655 235.939 262.733 236.095Z" fill="#0080F7"/>
<path d="M304.196 217.574C305.131 219.286 307.469 218.741 307.469 216.796C307.469 216.64 307.391 216.407 307.313 216.251C306.378 214.539 304.04 215.084 304.04 217.029C304.04 217.263 304.118 217.419 304.196 217.574Z" fill="#0080F7"/>
<path d="M308.639 195.241C309.574 196.953 311.912 196.408 311.912 194.463C311.912 194.307 311.834 194.074 311.756 193.918C310.821 192.206 308.483 192.751 308.483 194.696C308.483 194.93 308.483 195.086 308.639 195.241Z" fill="#0080F7"/>
<path d="M348.387 159.757C349.323 161.469 351.661 160.924 351.661 158.979C351.661 158.823 351.583 158.59 351.505 158.434C350.57 156.722 348.231 157.267 348.231 159.212C348.231 159.446 348.309 159.601 348.387 159.757Z" fill="#0080F7"/>
<path d="M322.901 123.105C323.837 124.817 326.175 124.273 326.175 122.327C326.175 122.172 326.097 121.938 326.019 121.783C325.084 120.071 322.746 120.615 322.746 122.561C322.746 122.794 322.824 122.95 322.901 123.105Z" fill="#0080F7"/>
<path d="M454.93 135.089C455.865 136.801 458.203 136.256 458.203 134.311C458.203 134.155 458.126 133.922 458.048 133.766C457.112 132.054 454.774 132.599 454.774 134.544C454.852 134.778 454.852 134.933 454.93 135.089Z" fill="#0080F7"/>
<path d="M405.906 108.398C406.842 110.11 409.18 109.566 409.18 107.62C409.18 107.465 409.102 107.231 409.024 107.076C408.089 105.364 405.75 105.908 405.75 107.854C405.75 108.087 405.828 108.243 405.906 108.398Z" fill="#0080F7"/>
<path d="M405.906 66.8447C406.842 68.5566 409.18 68.0119 409.18 66.0665C409.18 65.9109 409.102 65.6774 409.024 65.5218C408.089 63.8098 405.75 64.3545 405.75 66.3C405.828 66.5334 405.828 66.689 405.906 66.8447Z" fill="#0080F7"/>
<path d="M432.484 103.651C433.419 105.363 435.757 104.819 435.757 102.873C435.757 102.718 435.679 102.484 435.601 102.328C434.666 100.616 432.328 101.161 432.328 103.107C432.328 103.262 432.328 103.418 432.484 103.651Z" fill="#0080F7"/>
<path d="M523.049 87.6992C523.984 89.4111 526.322 88.8664 526.322 86.921C526.322 86.7654 526.244 86.5319 526.166 86.3763C525.231 84.6643 522.893 85.209 522.893 87.1544C522.971 87.3101 522.971 87.5435 523.049 87.6992Z" fill="#0080F7"/>
<path d="M533.726 151.509C534.662 153.221 537 152.676 537 150.731C537 150.575 536.922 150.341 536.844 150.186C535.909 148.474 533.571 149.019 533.571 150.964C533.571 151.197 533.571 151.353 533.726 151.509Z" fill="#0080F7"/>
<path d="M487.353 186.915C488.288 188.627 490.626 188.082 490.626 186.137C490.626 185.981 490.548 185.748 490.47 185.592C489.535 183.88 487.197 184.425 487.197 186.37C487.197 186.604 487.275 186.759 487.353 186.915Z" fill="#0080F7"/>
<path d="M515.8 230.803C516.736 232.515 519.074 231.97 519.074 230.025C519.074 229.869 518.996 229.635 518.918 229.48C517.983 227.768 515.645 228.313 515.645 230.258C515.722 230.491 515.722 230.647 515.8 230.803Z" fill="#0080F7"/>
<path d="M453.527 213.295C454.462 215.007 456.801 214.462 456.801 212.517C456.801 212.361 456.723 212.128 456.645 211.972C455.709 210.26 453.371 210.805 453.371 212.75C453.371 212.906 453.449 213.139 453.527 213.295Z" fill="#0080F7"/>
<path d="M452.748 264.108C453.683 265.82 456.021 265.276 456.021 263.33C456.021 263.175 455.943 262.941 455.865 262.785C454.93 261.074 452.592 261.618 452.592 263.564C452.67 263.719 452.67 263.953 452.748 264.108Z" fill="#0080F7"/>
<path d="M279.255 75.0927C280.191 76.8047 282.529 76.26 282.529 74.3145C282.529 74.1589 282.451 73.9255 282.373 73.7698C281.438 72.0579 279.1 72.6026 279.1 74.548C279.1 74.7036 279.178 74.9371 279.255 75.0927Z" fill="#0080F7"/>
<path d="M327.11 87.4658C328.045 89.1777 330.383 88.633 330.383 86.6876C330.383 86.532 330.305 86.2985 330.228 86.1429C329.292 84.4309 326.954 84.9756 326.954 86.921C326.954 87.0767 327.032 87.3101 327.11 87.4658Z" fill="#0080F7"/>
<path d="M332.955 36.0292C333.891 37.7412 336.229 37.1965 336.229 35.2511C336.229 35.0954 336.151 34.862 336.073 34.7064C335.138 32.9944 332.8 33.5391 332.8 35.4845C332.8 35.718 332.878 35.8736 332.955 36.0292Z" fill="#0080F7"/>
<path d="M368.418 188.782C369.353 190.494 371.691 189.949 371.691 188.004C371.691 187.848 371.613 187.615 371.535 187.459C370.6 185.747 368.262 186.292 368.262 188.237C368.262 188.471 368.34 188.627 368.418 188.782Z" fill="#0080F7"/>
<path d="M404.737 250.88C405.673 252.592 408.011 252.047 408.011 250.102C408.011 249.946 407.933 249.713 407.855 249.557C406.92 247.845 404.582 248.39 404.582 250.335C404.582 250.569 404.659 250.724 404.737 250.88Z" fill="#0080F7"/>
<path d="M296.324 342.703C297.259 344.415 299.598 343.87 299.598 341.925C299.598 341.769 299.52 341.536 299.442 341.38C298.506 339.668 296.168 340.213 296.168 342.158C296.246 342.392 296.246 342.547 296.324 342.703Z" fill="#0080F7"/>
<path d="M368.963 372.429C369.899 374.141 372.237 373.596 372.237 371.65C372.237 371.495 372.159 371.261 372.081 371.106C371.146 369.394 368.807 369.939 368.807 371.884C368.885 372.04 368.885 372.273 368.963 372.429Z" fill="#0080F7"/>
<path d="M317.134 394.061C318.069 395.773 320.407 395.229 320.407 393.283C320.407 393.128 320.329 392.894 320.251 392.739C319.316 391.027 316.978 391.571 316.978 393.517C316.978 393.75 317.056 393.906 317.134 394.061Z" fill="#0080F7"/>
<path d="M280.97 408.69C281.905 410.402 284.244 409.858 284.244 407.912C284.244 407.757 284.166 407.523 284.088 407.367C283.152 405.656 280.814 406.2 280.814 408.146C280.814 408.301 280.814 408.535 280.97 408.69Z" fill="#0080F7"/>
<path d="M351.661 480.826C352.596 482.538 354.934 481.993 354.934 480.048C354.934 479.892 354.856 479.659 354.778 479.503C353.843 477.791 351.505 478.336 351.505 480.281C351.505 480.437 351.583 480.67 351.661 480.826Z" fill="#0080F7"/>
<path d="M245.274 456.781C246.209 458.493 248.547 457.948 248.547 456.003C248.547 455.847 248.47 455.614 248.392 455.458C247.456 453.746 245.118 454.291 245.118 456.236C245.196 456.47 245.196 456.626 245.274 456.781Z" fill="#0080F7"/>
<path d="M88.3048 363.246C89.2401 364.958 91.5782 364.413 91.5782 362.468C91.5782 362.312 91.5003 362.079 91.4224 361.923C90.4871 360.211 88.1489 360.756 88.1489 362.701C88.1489 362.935 88.2269 363.09 88.3048 363.246Z" fill="#0080F7"/>
<path d="M39.8268 412.192C40.762 413.904 43.1002 413.36 43.1002 411.414C43.1002 411.259 43.0223 411.025 42.9443 410.869C42.0091 409.157 39.6709 409.702 39.6709 411.648C39.6709 411.803 39.7488 412.037 39.8268 412.192Z" fill="#0080F7"/>
<path d="M111.842 406.356C112.778 408.068 115.116 407.524 115.116 405.578C115.116 405.423 115.038 405.189 114.96 405.034C114.025 403.322 111.687 403.866 111.687 405.812C111.687 406.045 111.687 406.201 111.842 406.356Z" fill="#0080F7"/>
<path d="M207.084 313.6C208.019 315.312 210.357 314.767 210.357 312.821C210.357 312.666 210.279 312.432 210.201 312.277C209.266 310.565 206.928 311.109 206.928 313.055C207.006 313.21 207.006 313.444 207.084 313.6Z" fill="#0080F7"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="537" height="496" fill="white"/>
</clipPath>
</defs>
</svg>

    <router-view/>
  </div>
</template>

<script>
import tokenUtils from '@/utils/token'

export default {
  created() {
    // console.log(this.$router.currentRoute.path)
    let payload = tokenUtils.load();
    if (payload && payload.username) {
      this.$store.commit('changeUsername', payload.username)
      this.$store.commit('changeRole', payload.role)
      //  this.$store.commit('changeSite', payload.site)
    } else {
      this.$router.push('/login')
    }
  },
  // computed:{
  //   enable(){
  //     let path =this.$router.currentRoute.path
  //     console.log(path)
  //     if(path.indexOf('dashboard')!==-1)
  //     {
  //       console.log('dash route')
  //       return false
  //     }
  //     return true
  //   }
  // }

}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #ECECEC;
  .vector{
    height: 1187px;
    width:1287px;
    position: fixed;
    z-index:1;
    top:200px;
    right:-240px;
    opacity: 0.5;
    transform: matrix(0, -1, -1, 0, 0, 0);
  }
}

#nav {
  // padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>
