import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    username: '',
    role: '',
    // site:''
  },
  mutations: {
    changeUsername(state, username) {
      state.username = username
    },
    changeRole(state, role) {
      state.role = role
    },
    // changeSite(state, site) {
    //   state.site = site
    // }
  },
  actions: {
  },
  modules: {
  }
})
