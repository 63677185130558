export default {
  save: (token) => {
    localStorage.drkToken = token
  },
  remove:()=>{
    localStorage.drkToken=null
  },
  load: () => {
    let token = localStorage.drkToken

    if (token) {
      try {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        let payload = JSON.parse(jsonPayload);
        if (payload.exp * 1000 > Date.now()) {
          return payload
        }
      } catch(e) {
        console.log(e)
      }
    }

    return null
  }
}