import {SERVER_HOSTNAME} from './config'

export default async (method, path, body) => {
  try {
    let response = await fetch(SERVER_HOSTNAME + path, {
      method,
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.drkToken
      },
      body: JSON.stringify(body)
    })

    if (response.status >= 200 && response.status < 300) {
      return response.json()
    } else {
      return Promise.reject(new Error(response.statusText))
    }
  } catch (err) {
    return Promise.reject(err)
  }
}