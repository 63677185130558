<template>
  <div class="dashboard_container">
    <Header></Header>
    <nav class="main_nav">
      <router-link to="/" style="width:70px"><md-icon style="color:#0080F6">home</md-icon></router-link>
    </nav>
    <div class="dashboard-body ">
      <transition name="fade">
        <router-view/>
      </transition>
      <select class="custom_selector dash_selector" v-model="siteSelected">
            <option class='custom_selector_item' v-for="(site, index) in listSite" :key="index" :value="site._id">{{site.name}}</option>
      </select>
      <div class="dash_donut">
        <div class='chartUser'>
          <apexchart width="400" height="400" type="donut" :options="optionsUser" :series="optionsUser.series"></apexchart>
        </div>
        <div class='chartBin'>
          <apexchart width="400" height="400" type="donut" :options="optionsBin" :series="optionsBin.series"></apexchart>
        </div>
        <div class='chartBin chartBar'>
          <apexchart type="bar" height="390" :options="statusChartOptions" :series="statusSeries"></apexchart>
        </div>
        
       
      </div>

      <!-- chart rate by bin -->
      <div class="chartRate">
          <div class="title_chart">
            <div class="title_chart_content">
              <div class="title_chart_content_1">Utilization by devices</div>
              <div>{{dateQuery}}</div>
            </div>
            <select class="custom_selector dash_selector" style="margin-top:0px;margin-right:10px" v-model="shelfSelected">
              <option class='custom_selector_item' v-for="(shelf, index) in listShelf" :key="index" :value="shelf._id">{{("Shelf "+shelf.name+" in "+shelf.room.name)}}</option>
            </select>
          </div>
          <div>
             <div>
              <apexchart  height='240' type="line" :options="optionsRate" :series="seriesRate"></apexchart>
            </div>
            <div class="button_container">
              <md-button class='md-raised dash_btn' @click="getRateWeek">
                <strong>This week</strong>
              </md-button>
              <md-button class='md-raised dash_btn' @click="getRateMonth">
                <strong>This month</strong>
              </md-button>
              <md-button class='md-raised dash_btn' @click="getRateYear">
                <strong>This year</strong>
              </md-button>
            </div>
          </div>
         
           <div class="dash_loading" v-if="loadingRate">
            <div class="sk-cube-grid">
                <div class="sk-cube sk-cube1"></div>
                <div class="sk-cube sk-cube2"></div>
                <div class="sk-cube sk-cube3"></div>
                <div class="sk-cube sk-cube4"></div>
                <div class="sk-cube sk-cube5"></div>
                <div class="sk-cube sk-cube6"></div>
                <div class="sk-cube sk-cube7"></div>
                <div class="sk-cube sk-cube8"></div>
                <div class="sk-cube sk-cube9"></div>
            </div>
          </div>
      </div>
      
      <!-- chart ul by loc -->
      <div class="chartRate">
        <div class="title_chart">
            <div class="title_chart_content">
              <div class="title_chart_content_1">Utilization of devices on loan by locations</div>
              <div>{{dateQueryUl}}</div>
            </div>
        </div>
        <div class="dfhsjs">
          <div class="chart_line">
              <apexchart type="line" height="300" :options="ulChartOptions" :series="ulSeries"></apexchart>
          </div>
          <div class="button_container">
            <!-- <md-button class='md-raised dash_btn' @click="getUlWeek">
              <strong>Weekly</strong>
            </md-button>
            <md-button class='md-raised dash_btn' @click="getUlMonth">
              <strong>Monthly</strong>
            </md-button> -->
            <DatePicker ref="picker" @confirm="confirmFilterDate" class=' btn_datepicker' format="DD/MM/YYYY"/>
            <!-- <md-button class='md-raised dash_btn' @click="getUlYear">
              <strong>This year</strong>
            </md-button> -->
          </div>
        </div>
        <div class="dash_loading"  style="height:270px" v-if="loadingUl">
           <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
        </div>
      </div>


      <!-- chart transaction -->
      <div class="chartRate">
        <div class="title_chart">
            <div class="title_chart_content">
              <div class="title_chart_content_1">Transaction Logs of {{this.roomSelected}} location</div>
              <div>{{dateQueryTran}}</div>
            </div>
            <select class="custom_selector dash_selector" style="margin-top:0px;margin-right:10px" v-model="roomSelected">
              <option class='custom_selector_item' v-for="(room, index) in roomList" :key="index" :value="room">{{room}}</option>
            </select>
        </div>    
        <div class="dfhsjs">
           <div class="chart_line">
            <apexchart  height='300' type="line" :options="optionsTran" :series="seriesTran"></apexchart>
          </div>
          <div class="button_container">
            <md-button class='md-raised dash_btn' @click="getTranWeek">
              <strong>This week</strong>
            </md-button>
            <md-button class='md-raised dash_btn' @click="getTranMonth">
              <strong>This month</strong>
            </md-button>
            <md-button class='md-raised dash_btn' @click="getTranYear">
              <strong>This year</strong>
            </md-button>
          </div>
        </div>
        <div class="dash_loading" style="height:280px" v-if="loading">
           <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
        </div>
      </div>
     
     
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import request from '@/utils/request'
import DatePicker from '../../components/DatePicker.vue'
var getDaysArray = function(start, end) {
  for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
      arr.push(new Date(dt));
  }
  return arr;
}
var formatTime=(val)=>{
  if(val>=0 && val<10)
  {
    return '0'+val
  }
  else
  {
    return val.toString()
  }
}
var dayInMonth=(month,year)=>{
  return new Date(year,month,0).getDate();
}
export default {
  name:'Customer',
  components:{
    Footer,
    Header,
    DatePicker
  },
  created(){

    let roleRight=['ROOT','CUSTOMER'];
    if(!roleRight.includes(this.$store.state.role))
    {
      return this.$router.push('/')
    }
   
    request('get','/dashboard/listSite')
    .then((data)=>{
      if(data.length>0)
      {
        this.listSite=[...data]
        this.siteSelected=this.listSite[0]._id
        
      }
     
    })
    .catch(err=>{
      console.log(err.message)
      alert('Failed to get data')
    })
  
  },
  watch:{

    siteSelected:async function(){
      this.roomSelected=''
      // console.log("site changed")
      this.$refs.picker.reset()
      request('get','/dashboard/listShelf/'+this.siteSelected)
      .then((shelves)=>{
        if(shelves.length>0)
        {
          let newShelf=[]
          shelves.forEach(el=>{
            if(el.room)
            {
              newShelf.push(el)
            }
          })
          this.listShelf=[...newShelf]
          if(this.listShelf.length>0)
          {
            this.shelfSelected=this.listShelf[0]._id
          }
        }
        else
        {
          this.listShelf=[]
        }
      })
      .catch(err=>{
        console.log(err.message)
        alert('Failed to get data')
      })
      
      // let now=new Date().getTime();
      // let preDay = new Date(now - 7*24*60*60*1000)

      
      // // let day = now.getDay();
      // // let first=now.getDate()-day+(day==0?-6:1);
      // // let a=new Date(now.setDate(first));
      // let startWeek=new Date(preDay.getFullYear(),preDay.getMonth(),preDay.getDate(),0,0,0);
      // let n=new Date();
      // let endWeek=new Date(n.getFullYear(),n.getMonth(),n.getDate(),23,59,59);
      let now=new Date();
      let day = now.getDay();
      let first=now.getDate()-day+(day==0?-6:1);
      let a=new Date(now.setDate(first));
      let startWeek=new Date(a.getFullYear(),a.getMonth(),a.getDate());
      let n=new Date();
      let endWeek=new Date(n.getFullYear(),n.getMonth(),n.getDate(),23,59,59);
      this.dateQueryUl= `${formatTime(startWeek.getDate())}/${formatTime(startWeek.getMonth()+1)}/${startWeek.getFullYear()} - ${formatTime(endWeek.getDate())}/${formatTime(endWeek.getMonth()+1)}/${endWeek.getFullYear()}`
      let findSite = this.chartData.findIndex(el=>el.site===this.siteSelected)
      // this.optionsRate.series= [...this.seriesRateWeek]
      if(findSite>=0)
      {
        // console.log("old data sites")
        this.roomList=['All',...this.chartData[findSite].status.room]
       
        //update data for chart user
        this.optionsUser.series=[this.chartData[findSite].user.contractor,this.chartData[findSite].user.staff]
        //update data for chart bin
        this.optionsBin.series=[ this.chartData[findSite].bin.available,this.chartData[findSite].bin.charging,this.chartData[findSite].bin.onload,this.chartData[findSite].bin.fail]

        //update data for status chart
        this.statusChartOptions={
            ...this.statusChartOptions,
            xaxis:{
              categories:this.chartData[findSite].status.room
            }
        }
        
        setTimeout(()=>{
          let binStatus=[...this.statusSeries]
          binStatus[0].data=this.chartData[findSite].status.goodBin;
          binStatus[1].data=this.chartData[findSite].status.chargingBin;
          binStatus[2].data=this.chartData[findSite].status.loanBin;
          binStatus[3].data=this.chartData[findSite].status.failBin;
          this.statusSeries=[...binStatus]
          this.roomSelected='All'
         
        },0)
        
        
        //update data for chart ul by loc
        this.ulChartOptions ={
          ...this.ulChartOptions,
          xaxis:{
            categories:this.chartData[findSite].status.room
          }
        }
        setTimeout(()=>{
          let ulData =[...this.ulSeries]
          ulData[0].data=this.chartData[findSite].ul.total;
          ulData[1].data=this.chartData[findSite].ul.loan;
          ulData[2].data=this.chartData[findSite].ul.weeklyRate;
          this.ulSeries=[...ulData]
          // console.log(this.ulSeries)
        },0)
        
      }
      else
      {
        try
        {
          
          // this.optionsTran.title.text='Transaction Logs By Weekly'
          // let now=new Date();
          // let day = now.getDay();
          // let first=now.getDate()-day+(day==0?-6:1);
          // let a=new Date(now.setDate(first));
          // let startWeek=new Date(a.getFullYear(),a.getMonth(),a.getDate());
          // let n=new Date();
          // let endWeek=new Date(n.getFullYear(),n.getMonth(),n.getDate());
          // this.dateQuery=
          // let arrDate= getDaysArray(startWeek,endWeek)
          // // console.log(arrDate)
          // let timeArr=[]
          // arrDate.forEach(el=>{
          //   let temp = `${this.monthArray[el.getMonth()]} ${formatTime(el.getDate())}, ${el.getFullYear()}`
          //   timeArr.push(temp)
          // })
          // console.log(arrDate)
          // let listBin = await request('get','/dashboard/listBin/'+this.siteSelected)
          // let listUser= await request('get','/dashboard/listUser/'+this.siteSelected)
          // // let trans = await request('post','/dashboard/weeklog/'+this.siteSelected,{time:arrDate,room:this.roomSelected})
          // let binByLoc =  await request('get','/dashboard/binByLoc/'+this.siteSelected)
          this.loadingUl=true;
          let task=[request('get','/dashboard/listBin/'+this.siteSelected),request('get','/dashboard/listUser/'+this.siteSelected)
                    ,request('get','/dashboard/binByLoc/'+this.siteSelected)]
          let result= await Promise.all(task)
          // console.log(result)
          this.roomList=['All',...result[2].room]
          this.roomSelected='All'
          let startTime  = `${startWeek.getMonth()+1}/${startWeek.getDate()}/${startWeek.getFullYear()}`
          let endTime = `${endWeek.getMonth()+1}/${endWeek.getDate()}/${endWeek.getFullYear()}`
          let dataRateByLoc = await request('post','/dashboard/timebinbyloc/'+this.siteSelected,{start:startTime,end:endTime,roomList:result[2].room})
          // console.log(dataRateByLoc)
          //update data for user chart
          this.optionsUser.series=[result[1].contractor,result[1].staff]

          //update data for bin chart
          this.optionsBin.series=[result[0].available,result[0].charging,result[0].onload,result[0].fail]

          //update data for status chart
          this.statusChartOptions={
              ...this.statusChartOptions,
              xaxis:{
                categories:result[2].room
              }
          }
          setTimeout(()=>{
            
            let binStatus=[...this.statusSeries]
            binStatus[0].data=result[2].goodBin;
            binStatus[1].data=result[2].chargingBin;
            binStatus[2].data=result[2].loanBin;
            binStatus[3].data=result[2].failBin;
            this.statusSeries=[...binStatus]
          },0)
          
          

          //update data for chart ul by loc
          this.ulChartOptions ={
            ...this.ulChartOptions,
            xaxis:{
              categories:result[2].room
            }
          }
          let totalBin= Array(result[2].room.length).fill(0)
          let loanBin=[...totalBin]
          // let sumTime =0
          // dataRateByLoc.forEach(el=>{
          //   sumTime+=el
          // })
          

          for(let i=0;i<result[2].room.length;i++)
          {
            totalBin[i] +=result[2].goodBin[i]+result[2].chargingBin[i]+result[2].loanBin[i]+ result[2].failBin[i];
            // loanBin[i]=result[2].loanBin[i]
            // loanBin[i] = 0
            
          }
          let totalTime = endWeek.getTime()/1000 - startWeek.getTime()/1000
          let data_rateLoc = [...loanBin]
          for(let i=0;i< dataRateByLoc.length; i++)
          {
            if(dataRateByLoc[i] == null || dataRateByLoc[i].length===0)
            {
              continue
            }

            let time = totalTime * totalBin[i];
            data_rateLoc[i] = Math.round(dataRateByLoc[i][0]*10000/time)/100
            loanBin[i] = dataRateByLoc[i][1]
            
            
          }
          // console.log(totalBin)
          this.loadingUl=false
          setTimeout(()=>{
            let ulData =[...this.ulSeries]
            ulData[0].data=totalBin;
            ulData[1].data=loanBin;
            ulData[2].data=data_rateLoc;
            this.ulSeries=[...ulData]
          },0)

          //save data to cache
          this.chartData.push({
            site:this.siteSelected,
            user:{...result[1]},
            bin:{...result[0]},
            status:{...result[2]},
            ul:{
              total:totalBin,
              loan:loanBin,
              weeklyRate:dataRateByLoc
            }
          })
          // console.log(this.chartData)

          
          // let ulData =[...this.ulSeries]
          // ulData[0].data=this.chartData[findSite].ul.weekly.total;
          // ulData[1].data=this.chartData[findSite].ul.weekly.loan;
          // ulData[2].data=this.chartData[findSite].ul.weekly.rate;
          // this.ulSeries=[...ulData]
          
          
          
          // console.log(binLog)
          // this.chartData.push({
          //   site:this.siteSelected,
          //   user:{
          //     ...listUser
          //   },
          //   bin:{
          //     ...listBin
          //   },
          //   transaction:{
          //     weekly:{
          //       borrow:[...trans.borrow],
          //       return:[...trans.return],
          //       timeArr:[...timeArr]
          //     }
          //   },
           
          // })
          // this.loading=false
          // let currenIndex=this.chartData.length-1;
          
          // // this.optionsTran.title.text='Transaction Logs By Weekly'
          // this.optionsTran={
          //   ...this.optionsTran,
          //   series:[{
          //     name:"Borrow",
          //     data:[...trans.borrow]
          //   },
          //   {
          //     name:"Return",
          //     data:[...trans.return]
          //   }],
          //   xaxis:{
          //     categories:[...timeArr]
          //   }
          // }
        }
        catch(err)
        {
          console.log(err)
          alert('Failed to get data')
          this.loadingUl=false;
        }
      }
    },


    roomSelected:async function(){
      // console.log("room changed:",this.roomSelected)
      if(!this.roomSelected)
      {
        return
      }
      let now=new Date();
      let day = now.getDay();
      let first=now.getDate()-day+(day==0?-6:1);
      let a=new Date(now.setDate(first));
      let startWeek=new Date(a.getFullYear(),a.getMonth(),a.getDate());
      let n=new Date();
      let endWeek=new Date(n.getFullYear(),n.getMonth(),n.getDate(),23,59);
      this.dateQueryTran=`${formatTime(startWeek.getDate())}/${formatTime(startWeek.getMonth()+1)}/${startWeek.getFullYear()} - ${formatTime(endWeek.getDate())}/${formatTime(endWeek.getMonth()+1)}/${endWeek.getFullYear()}`

      let findRoom = this.tranData.findIndex(el=>el.site===this.siteSelected && el.room===this.roomSelected)
      // console.log(this.tranData)
      if(findRoom>=0)
      {
        // console.log("find old tran data")
        this.optionsTran={
          ...this.optionsTran,
          xaxis:{
            categories:[...this.tranData[findRoom].weekly.timeArr]
          }
        }
        setTimeout(()=>{
          let tran = [...this.seriesTran]
          tran[0].data = [...this.tranData[findRoom].weekly.borrow]
          tran[1].data = [...this.tranData[findRoom].weekly.return]
          this.seriesTran=[...tran]
        },0)
      }
      else
      {
        try
        {
          this.loading=true;
          let arrDate= getDaysArray(startWeek,endWeek)
          // console.log(arrDate)
          let timeArr=[]
          arrDate.forEach(el=>{
            let temp = `${this.monthArray[el.getMonth()]} ${formatTime(el.getDate())}, ${el.getFullYear()}`
            timeArr.push(temp)
          })

          let arrQuery = arrDate.map(el=> {return `${el.getMonth()+1}/${el.getDate()}/${el.getFullYear()}`})
          let trans = await request('post','/dashboard/weeklog/'+this.siteSelected,{time:arrQuery,room:this.roomSelected}) 
          this.loading=false
          this.optionsTran={
            ...this.optionsTran,
            xaxis:{
              categories:[...timeArr]
            }
          }
          setTimeout(()=>{
            let tran = [...this.seriesTran]
            tran[0].data = [...trans.borrow]
            tran[1].data = [...trans.return]
            this.seriesTran=[...tran]
          },0)

          //save to cache
          this.tranData.push({
            site:this.siteSelected,
            room:this.roomSelected,
            weekly:{
              borrow:trans.borrow,
              return:trans.return,
              timeArr:timeArr
            }
          })
        }
        catch(err)
        {
          console.log(err)
          this.loading=false
          alert('Failed to get data')
        }
      }
    },
    shelfSelected:async function(){
      // console.log(this.shelfSelected)
      let now=new Date();
      let day = now.getDay();
      let first=now.getDate()-day+(day==0?-6:1);
      let a=new Date(now.setDate(first));
      let startWeek=new Date(a.getFullYear(),a.getMonth(),a.getDate());
      let n=new Date();
      let endWeek=new Date(n.getFullYear(),n.getMonth(),n.getDate(),23,59,59);
      this.dateQuery=`${formatTime(startWeek.getDate())}/${formatTime(startWeek.getMonth()+1)}/${startWeek.getFullYear()} - ${formatTime(endWeek.getDate())}/${formatTime(endWeek.getMonth()+1)}/${endWeek.getFullYear()}`
      let findShelf = this.rateData.findIndex(el=>el.site===this.siteSelected && el.shelf===this.shelfSelected)
      if(findShelf>=0)
      {
        this.optionsRate={
          ...this.optionsRate,
          xaxis:{
            categories:[...this.rateData[findShelf].weekly.bin]
          }
        }
        setTimeout(()=>{
          let rate = [...this.seriesRate]
          rate[0].data=[...this.rateData[findShelf].weekly.rate]
          this.seriesRate =[...rate]
        },0)
      }
      else
      {
        try
        {
          this.loadingRate=true;
          let startTime  = `${startWeek.getMonth()+1}/${startWeek.getDate()}/${startWeek.getFullYear()}`
          let endTime = `${endWeek.getMonth()+1}/${endWeek.getDate()}/${endWeek.getFullYear()}`
          let binData = await request('post','/dashboard/timebinlog/'+this.siteSelected,{shelf:this.shelfSelected,start:startTime,end:endTime})
          this.loadingRate=false;
          // console.log(binData)
          let rateArr=[]
          if(binData.rate.length>0)
          {
            rateArr = binData.rate
            // let sum=0;
            // rateArr.forEach(el=>sum+=el)
            let totalTime = endWeek.getTime()/1000 - startWeek.getTime()/1000
            for(let i=0;i< rateArr.length; i++)
            {
              rateArr[i] = Math.round(rateArr[i]*10000/totalTime)/100
            }
          }   
          this.optionsRate={
            ...this.optionsRate,
            xaxis:{
              categories:[...binData.bins]
            }
          }
          setTimeout(()=>{
            let rate = [...this.seriesRate]
            rate[0].data=[...rateArr]
            this.seriesRate =[...rate]
          },0)
          
          // console.log(rateArr)
          this.rateData.push({
            site:this.siteSelected,
            shelf:this.shelfSelected,
            weekly:{
              bin:binData.bins,
              rate:rateArr
            }
          })
          
         
        }
        catch(err)
        {
          console.log(err)
          this.loadingRate=false;
          alert('Failed to get data')
        }
      }
        
       
    }
  },
  data(){
    return{
      dateQuery:'',
      dateQueryTran:'',
      dateQueryUl:'',
      minDate:new Date(2021,4,20),

      // seriesRateWeek:[
      //   {
      //     name: "Rate:",
      //     data: [5,12,9,6,11,23,17,17]
      //   }  
      // ],
      // seriesRateMonth:[
      //   {
      //     name: "Rate:",
      //     data: [9,8,4,14,10,16,20,19]
      //   }  
      // ],
      // seriesRateYear:[
      //   {
      //     name: "Rate:",
      //     data: [15,7,4,12,8,18,19,17]
      //   }  
      // ],
      roomList:[],
      roomSelected:'',
     
      loading:false,
      loadingRate:false,
      loadingUl:false,
      siteSelected:'',
      shelfSelected:'',
      monthArray:['Jan','Fed','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
      listSite:[],
      listShelf:[],
      chartData:[],
      rateData:[],
      tranData:[],
      seriesTran:[
        {
          name: "Borrow",
          data: []
        },
        {
          name: "Return",
          data: []
        }
      ],
      optionsTran:{
        
        colors:['#0080F6','#00C14D'],
        // series: [
        //   {
        //     name: "Borrow",
        //     data: [45, 52, 38, 24, 33, 26, 21,45,32]
        //   },
        //   {
        //     name: "Return",
        //     data: [33,32,21,23,45,53,25,30,29]
        //   }
        // ],
        chart: {
          height: 300,
          width:"100%",
          type: 'bar',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 0,
        },
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        },
        // title: {
        //   text: 'Transaction Logs By Weekly',
        //   align: 'left',
        //   style: {
        //     fontSize:  '16px',
        //     fontWeight:  'bold',
        //     color:  '#171A26'
        //   },
        // },
        // legend: {
        //   tooltipHoverFormatter: function(val, opts) {
        //     return val + ' : ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] 
        //   }
        // },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          labels: {
            show: true,
            rotate: -90,
            rotateAlways: true,
          },
          categories: [],
          tickPlacement: 'on'
        },

        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val 
                }
              }
            },
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
        },
         fill: {
          opacity: 1
        },
      },
      seriesRate: [
          {
            name: "Utilization:",
            data: []
          }
      ],
      optionsRate:{
        
        colors:['#EB9515'],
        
        chart: {
          height: 260,
          // height: 300,
          width:"100%",
          type: 'bar',
          zoom: {
            enabled: false
          },
          toolbar:{
            show:false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [0,0,0],
          // curve: 'straight',
          // dashArray: [0, 8, 5]
        },
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        },
         fill: {
          opacity: 1
        },
        // title: {
        //   text: '',
        //   align: 'left'
        // },
        // legend: {
        //   tooltipHoverFormatter: function(val, opts) {
        //     return val + ' : ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '%'
        //   }
        // },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          labels: {
            show: true,
            rotate: -90,
            rotateAlways: true,
          },
          categories: [],
          tickPlacement: 'on'
        },
        yaxis:[
          {
            title: {
              text: "% Utilization",
            }
          }
        ],
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return '%'+ val
                }
              }
            },
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
        }
      },

      optionsUser: {
       
        // colors:['#0080F6','#00C14D','#EB9515'],
        colors:['#00C14D','#EB9515'],
        title: {
          text: "Total Users by Role",
           style: {
            fontSize:  '16px',
            fontWeight:  'bold',
            color:  '#171A26'
          },
        },
        legend: {
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
          itemMargin: {
            vertical: 5

          },
          offsetX: -100,
          offsetY:10,
          position:'bottom',
          formatter: function(seriesName, opts) {
            return seriesName +" " + opts.w.globals.series[opts.seriesIndex]
          }
        },
      dataLabels: {
        enabled: true,
      },
      labels: ['Contractor', 'Staff'],
            
      plotOptions: {
            pie: {
              offsetX: -45,
              offsetY: 20,
              donut: {
                size:'60%',
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: '22px',
                    fontWeight:'bold',
                    fontFamily: 'Helvetica, Arial, sans-serif',

                    color: undefined,
                    offsetY: -10,
                    formatter: function (val) {
                      return val
                    }
                  },
                  value: {
                    show: true,
                    fontSize: '16px',
                    fontWeight:'bold',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    color:  '#171A26',
                    offsetY: 5,
                    formatter: function (val) {
                      return val
                    }
                  },
                  total: {
                    show: true,
                    label: 'Users',
                    fontSize: '22px',
                    color: '#171A26',
                    formatter: function (w) {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return a + b
                      }, 0)
                    }
                  }
                }
              }
            }
          },
          series: [1,0]
    
      },

      optionsBin: {
        colors:['#00C14D','#EB9515','#6D6E71','#ED1D24'],
        title: {
          text: "Total Status of Bin",
           style: {
            fontSize:  '16px',
            fontWeight:  'bold',
            color:  '#171A26'
          },
        },
        legend: {
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
          itemMargin: {
            vertical: 5

          },
          offsetX: -100,
          offsetY:10,
          position:'bottom',
          formatter: function(seriesName, opts) {
            return seriesName +" " + opts.w.globals.series[opts.seriesIndex]
          }
        // formatter: function(seriesName, opts) {
        //   return '<div class="legend-info">' + '<span>' + seriesName + '</span>' + '<span>' + opts.w.globals.series[opts.seriesIndex] + '</span>' + '</div>'
        // }
        },
      dataLabels: {
        enabled: true,
      },
      labels: ['Good', 'Charging', 'On-loan','Fail'],
            
      plotOptions: {
            pie: {
              offsetX: -45,
              offsetY: 20,
              donut: {
                size:'60%',
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: '22px',
                    fontWeight:'bold',
                    fontFamily: 'Helvetica, Arial, sans-serif',

                    color: undefined,
                    offsetY: -10,
                    formatter: function (val) {
                      return val
                    }
                  },
                  value: {
                    show: true,
                    fontSize: '16px',
                    fontWeight:'bold',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    color:  '#171A26',
                    offsetY: 5,
                    formatter: function (val) {
                      return val
                    }
                  },
                  total: {
                    show: true,
                    label: 'Bins',
                    fontSize: '22px',
                    color: '#171A26',
                    formatter: function (w) {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return a + b
                      }, 0)
                    }
                  }
                }
              }
            }
          },
          series: [1,0,0,0]
    
      },

      ulSeries: [
          {
            name: 'Total',
            type: 'column',
            data: [],
           
          }, 
          {
            name: 'On-loan',
            type: 'column',
            data: []
          }, 
          {
            name: '% Ultilization',
            type: 'line',
            data: []
          }
      ],
      ulChartOptions: {
        colors:['#0080F6','#6D6E71','#EB9515'],
        chart: {
          height: 300,
          width:"100%",
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        },
        stroke: {
          width: [0,0, 4]
        },
         fill: {
          opacity: 1
        },
        // title: {
        //   text: 'Utilization by Location',
        //   style: {
        //     fontSize:  '16px',
        //     fontWeight:  'bold',
        //     color:  '#171A26'
        //   },
        // },
        dataLabels: {
          enabled: false,
          enabledOnSeries: [1]
        },
        xaxis: {
          // categories: ['W/S', 'COS', 'TTLC', 'OMB','MCB'],
          categories:[]
        },
        // labels: ['W/S','COS','TTLC','OMB','MCB'],
        // xaxis: {
        //   type: 'datetime'
        // },
        yaxis: [{
          title: {
            text: 'Bin',
          },
          seriesName:'Total'
        
        }, 
        {
          seriesName:'Total',
          show: false
        },
        {
          opposite: true,
          title: {
            text: '% Ultilization'
          },
          seriesName:'% Ultilization'
        }]
      },


      statusSeries: [{
            name: 'Good',
            // data: [50, 35, 16, 40, 20]
            data:[]
          }, {
            name: 'Charging',
            // data: [30, 30, 3, 5, 20]
            data:[]
          }, {
            name: 'On-loan',
            // data: [30,9 , 2, 5, 10]
            data:[]
          }, {
            name: 'Failed',
            // data: [3, 5, 8, 2, 10]
            data:[]
      }],
      statusChartOptions: {
        colors:['#00C14D','#EB9515','#6D6E71','#ED1D24'],
        chart: {
          type: 'bar',
          height: 392,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0,
            columnWidth: "50%"
          },
        },
        title: {
          text: 'Status by Location',
          style: {
            fontSize:  '16px',
            fontWeight:  'bold',
            color:  '#171A26'
          },
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: [1]
        },
        xaxis: {
          // categories: ['W/S', 'COS', 'TTLC', 'OMB','MCB'],
          categories:[]
        },
        // legend: {
        //   position: 'bottom',
        //   offsetY: 40
        // },
        fill: {
          opacity: 1
        }
      },
      
    }
  },
  methods:{
    confirmFilterDate(date) {
      console.log(date.start,date.end)
      let arrStart = date.start.split('/').map(Number)
      let arrEnd = date.end.split('/').map(Number)
      let startMonth = new Date(arrStart[2],arrStart[1]-1,arrStart[0])
      let endMonth = new Date(arrEnd[2],arrEnd[1]-1,arrEnd[0],23,59,59)
      this.dateQueryUl = `${date.start} - ${date.end}`
      this.loadingUl=true;
      let findSite = this.chartData.findIndex(el=>el.site===this.siteSelected)
      let startTime  = `${arrStart[1]}/${arrStart[0]}/${arrStart[2]}`
      let endTime = `${arrEnd[1]}/${arrEnd[0]}/${arrEnd[2]}`
      request('post','/dashboard/timebinbyloc/'+this.siteSelected,{start:startTime,end:endTime,roomList:this.chartData[findSite].status.room})
      .then(data=>{
        this.loadingUl=false
        // console.log(data)
        let ulData = [...this.ulSeries]
        let totalTime = endMonth.getTime()/1000 - startMonth.getTime()/1000
        let data_rateLoc = Array(ulData[2].data.length).fill(0)
        let loanBin = [...data_rateLoc] 
        for(let i =0 ;i<data.length;i++)
        {
          if(data[i]==null || data[i].length ==0)
          {
            continue
          }
          loanBin[i]= data[i][1]
          let time = totalTime * ulData[0].data[i]
          data_rateLoc[i] = Math.round(data[i][0]*10000/time)/100
        }
        setTimeout(()=>{
          
          ulData[2].data =data_rateLoc
          ulData[1].data = loanBin
          // for(let i=0;i<data.length;i++)
          // {
          //   if(data[i]==0)
          //   {
          //     ulData[1].data[i] = 0
          //   }
          // }
          this.ulSeries=[...ulData]
        },0)
      })
      .catch(err=>{
        console.log(err)
        this.loadingUl=false
        alert('Error to get utilization by locations')
      })
    },
      // getUlWeek()
      // {
      //   // console.log("hello")
      //   let now=new Date().getTime();
      //   let preDay = new Date(now - 7*24*60*60*1000)
      //   let startWeek=new Date(preDay.getFullYear(),preDay.getMonth(),preDay.getDate(),0,0,0);
      //   let n=new Date();
      //   let endWeek=new Date(n.getFullYear(),n.getMonth(),n.getDate(),23,59,59);
      //   this.dateQueryUl= `${formatTime(startWeek.getDate())}/${formatTime(startWeek.getMonth()+1)}/${startWeek.getFullYear()} - ${formatTime(endWeek.getDate())}/${formatTime(endWeek.getMonth()+1)}/${endWeek.getFullYear()}`
      //   let findSite = this.chartData.findIndex(el=>el.site===this.siteSelected)
      //   setTimeout(()=>{
      //     let ulData = [...this.ulSeries]
      //     ulData[2].data=this.chartData[findSite].ul.weeklyRate;
      //     this.ulSeries=[...ulData]
      //   },0)
        
      // },
      // getUlMonth()
      // {
      //   let now=new Date().getTime();
      //   let preDay = new Date(now - 30*24*60*60*1000)
      //   let startMonth=new Date(preDay.getFullYear(),preDay.getMonth(),preDay.getDate(),0,0,0);
      //   let n=new Date();
      //   let endMonth=new Date(n.getFullYear(),n.getMonth(),n.getDate(),23,59,59);
      //   this.dateQueryUl=`${formatTime(startMonth.getDate())}/${formatTime(startMonth.getMonth()+1)}/${startMonth.getFullYear()} - ${formatTime(endMonth.getDate())}/${formatTime(endMonth.getMonth()+1)}/${endMonth.getFullYear()}`
      //   let findSite = this.chartData.findIndex(el=>el.site===this.siteSelected)
      //   if(Object.prototype.hasOwnProperty.call(this.chartData[findSite].ul,'monthlyRate'))
      //   {
      //     setTimeout(()=>{
      //       let ulData =[...this.ulSeries]
      //       ulData[2].data=this.chartData[findSite].ul.monthlyRate;
      //       this.ulSeries =[...ulData]
      //     },0)
      //   }
      //   else
      //   {
      //     this.loadingUl=true;
      //     request('post','/dashboard/timebinbyloc/'+this.siteSelected,{start:startMonth,end:endMonth,roomList:this.chartData[findSite].status.room})
      //     .then(data=>{
      //       // console.log(data)
      //       // let sumTime =0
      //       // data.forEach(el=>[
      //       //   sumTime+=el
      //       // ])
      //       // for(let i=0;i< data.length; i++)
      //       // {
      //       //   if(sumTime!==0)
      //       //   {
      //       //     data[i]= Math.round(data[i]*1000/sumTime)/10
      //       //   }
      //       // }
      //       this.loadingUl=false
      //       // console.log(data)
      //       setTimeout(()=>{
      //         let ulData = [...this.ulSeries]
      //         ulData[2].data =data
      //         this.ulSeries=[...ulData]
      //       },0)
      //       this.chartData[findSite]={
      //         ...this.chartData[findSite],
      //         ul:{
      //           ...this.chartData[findSite].ul,
      //           monthlyRate:data
      //         }
      //       }
      //     })
      //     .catch(err=>{
      //       console.log(err)
      //       this.loadingUl=false
      //       alert('Error to get utilization by locations')
      //     })
      //   }
      // },
      // getUlYear()
      // {
      //   console.log()
      //   let now=new Date();
      //   let startMonth=new Date(now.getFullYear(),0,1);
      //   let endMonth=new Date(now.getFullYear(),now.getMonth(),now.getDate(),23,59);
      //   this.dateQueryUl=`${formatTime(startMonth.getDate())}/${formatTime(startMonth.getMonth()+1)}/${startMonth.getFullYear()} - ${formatTime(endMonth.getDate())}/${formatTime(endMonth.getMonth()+1)}/${endMonth.getFullYear()}`
      //   let findSite = this.chartData.findIndex(el=>el.site===this.siteSelected)
      //   if(Object.prototype.hasOwnProperty.call(this.chartData[findSite].ul,'yearlyRate'))
      //   {
      //     setTimeout(()=>{
      //       let ulData =[...this.ulSeries]
      //       ulData[2].data=this.chartData[findSite].ul.yearlyRate;
      //       this.ulSeries =[...ulData]
      //     },0)
      //   }
      //   else
      //   {
      //     this.loadingUl=true;
      //     request('post','/dashboard/timebinbyloc/'+this.siteSelected,{start:startMonth,end:endMonth,roomList:this.chartData[findSite].status.room})
      //     .then(data=>{
      //       // console.log(data)
      //       let sumTime =0
      //       data.forEach(el=>[
      //         sumTime+=el
      //       ])
      //       for(let i=0;i< data.length; i++)
      //       {
      //         if(sumTime!==0)
      //         {
      //           data[i]= Math.round(data[i]*1000/sumTime)/10
      //         }
      //       }
      //       this.loadingUl=false
      //       // console.log(data)
      //       setTimeout(()=>{
      //         let ulData = [...this.ulSeries]
      //         ulData[2].data =data
      //         this.ulSeries=[...ulData]
      //       },0)
      //       this.chartData[findSite]={
      //         ...this.chartData[findSite],
      //         ul:{
      //           ...this.chartData[findSite].ul,
      //           yearlyRate:data
      //         }
      //       }
      //     })
      //     .catch(err=>{
      //       console.log(err)
      //       this.loadingUl=false
      //       alert('Error to get utilization by locations')
      //     })
      //   }

      // },

      getRateWeek()
      {
        
        let now=new Date();
        let day = now.getDay();
        let first=now.getDate()-day+(day==0?-6:1);
        let a=new Date(now.setDate(first));
        let startWeek=new Date(a.getFullYear(),a.getMonth(),a.getDate());
        let n=new Date();
        let endWeek=new Date(n.getFullYear(),n.getMonth(),n.getDate(),23,59);
        this.dateQuery=`${formatTime(startWeek.getDate())}/${formatTime(startWeek.getMonth()+1)}/${startWeek.getFullYear()} - ${formatTime(endWeek.getDate())}/${formatTime(endWeek.getMonth()+1)}/${endWeek.getFullYear()}`
        let findShelf = this.rateData.findIndex(el=>el.site===this.siteSelected && el.shelf===this.shelfSelected)
        if(findShelf>=0)
        {
          this.optionsRate={
            ...this.optionsRate,
            xaxis:{
              categories:[...this.rateData[findShelf].weekly.bin]
            }
          }
          setTimeout(()=>{
            let rate = [...this.seriesRate]
            rate[0].data=[...this.rateData[findShelf].weekly.rate]
            this.seriesRate =[...rate]
          },0)
          
        }
        else
        {
          this.optionsRate={
            ...this.optionsRate,
            xaxis:{
              categories:[]
            }
          }
          setTimeout(()=>{
            let rate = [...this.seriesRate]
            rate[0].data=[]
            this.seriesRate =[...rate]
          },0)
        }
        
      },
      getRateMonth:async function()
      {
        let now=new Date();
        let startMonth=new Date(now.getFullYear(),now.getMonth(),1);
        let endMonth=new Date(now.getFullYear(),now.getMonth(),now.getDate(),23,59,59);
        this.dateQuery=`${formatTime(startMonth.getDate())}/${formatTime(startMonth.getMonth()+1)}/${startMonth.getFullYear()} - ${formatTime(endMonth.getDate())}/${formatTime(endMonth.getMonth()+1)}/${endMonth.getFullYear()}`
        let findShelf = this.rateData.findIndex(el=>el.site===this.siteSelected && el.shelf===this.shelfSelected)
        if(findShelf>=0)
        {
          if(Object.prototype.hasOwnProperty.call(this.rateData[findShelf],'monthly'))
          {
            this.optionsRate={
              ...this.optionsRate,
              xaxis:{
                categories:[...this.rateData[findShelf].monthly.bin]
              }
            }
            setTimeout(()=>{
              let rate = [...this.seriesRate]
              rate[0].data=[...this.rateData[findShelf].monthly.rate]
              this.seriesRate =[...rate]
            },0)

          }
          else
          {
            try
            {
              this.loadingRate=true;
              let startTime  = `${startMonth.getMonth()+1}/${startMonth.getDate()}/${startMonth.getFullYear()}`
              let endTime = `${endMonth.getMonth()+1}/${endMonth.getDate()}/${endMonth.getFullYear()}`
              let binData = await request('post','/dashboard/timebinlog/'+this.siteSelected,{shelf:this.shelfSelected,start:startTime,end:endTime})
              // console.log(binData)
              this.loadingRate=false;
              let rateArr=[]
              if(binData.rate.length>0)
              {
                rateArr = binData.rate
                let totalTime = endMonth.getTime()/1000 - startMonth.getTime()/1000
                // console.log("total time :",totalTime)
                for(let i=0;i< rateArr.length; i++)
                {
                  rateArr[i] = Math.round(rateArr[i]*10000/totalTime)/100
                }
              }   
             
              // console.log(rateArr)
              this.optionsRate={
                ...this.optionsRate,
                xaxis:{
                  categories:[...binData.bins]
                }
              }
              setTimeout(()=>{
                let rate = [...this.seriesRate]
                rate[0].data=[...rateArr]
                this.seriesRate =[...rate]
              },0)
              
              this.rateData[findShelf]={
               ...this.rateData[findShelf],
                monthly:{
                  bin:binData.bins,
                  rate:rateArr
                }
              }
              
              
            }
            catch(err)
            {
              console.log(err)
              this.loadingRate=false;
            }
          }
        }
        else
        {
          this.optionsRate={
            ...this.optionsRate,
            xaxis:{
              categories:[]
            }
          }
          setTimeout(()=>{
            let rate = [...this.seriesRate]
            rate[0].data=[]
            this.seriesRate =[...rate]
          },0)
        }
        
      },
      getRateYear: async function()
      {
        let now=new Date();
        let startMonth=new Date(now.getFullYear(),0,1);
        let endMonth=new Date(now.getFullYear(),now.getMonth(),now.getDate(),23,59,59);
        this.dateQuery=`${formatTime(startMonth.getDate())}/${formatTime(startMonth.getMonth()+1)}/${startMonth.getFullYear()} - ${formatTime(endMonth.getDate())}/${formatTime(endMonth.getMonth()+1)}/${endMonth.getFullYear()}`
        
        let findShelf = this.rateData.findIndex(el=>el.site===this.siteSelected && el.shelf===this.shelfSelected)
        if(findShelf>=0)
        {
          if(Object.prototype.hasOwnProperty.call(this.rateData[findShelf],'yearly'))
          {
            this.optionsRate={
              ...this.optionsRate,
              xaxis:{
                categories:[...this.rateData[findShelf].yearly.bin]
              }
            }
            setTimeout(()=>{
              let rate = [...this.seriesRate]
              rate[0].data=[...this.rateData[findShelf].yearly.rate]
              this.seriesRate =[...rate]
            },0)
          }
          else
          {
            try
            {
              this.loadingRate=true;
              let startTime  = `${startMonth.getMonth()+1}/${startMonth.getDate()}/${startMonth.getFullYear()}`
              let endTime = `${endMonth.getMonth()+1}/${endMonth.getDate()}/${endMonth.getFullYear()}`
              let binData = await request('post','/dashboard/timebinlog/'+this.siteSelected,{shelf:this.shelfSelected,start:startTime,end:endTime})
              // console.log(binData)
              this.loadingRate=false;
              let rateArr=[]
              if(binData.rate.length>0)
              {
                rateArr = binData.rate
                let totalTime = endMonth.getTime()/1000 - startMonth.getTime()/1000
                // console.log("total time :",totalTime)
                for(let i=0;i< rateArr.length; i++)
                {
                  rateArr[i] = Math.round(rateArr[i]*10000/totalTime)/100
                }
              }   
              // console.log(rateArr)
              this.optionsRate={
                ...this.optionsRate,
                xaxis:{
                  categories:[...binData.bins]
                }
              }
              setTimeout(()=>{
                let rate = [...this.seriesRate]
                rate[0].data=[...rateArr]
                this.seriesRate =[...rate]
              },0)
              
              this.rateData[findShelf]={
               ...this.rateData[findShelf],
                yearly:{
                  bin:binData.bins,
                  rate:rateArr
                }
              }
              
            }
            catch(err)
            {
              console.log(err)
              this.loadingRate=false;
            }
          }
        }
        else
        {
         this.optionsRate={
            ...this.optionsRate,
            xaxis:{
              categories:[]
            }
          }
          setTimeout(()=>{
            let rate = [...this.seriesRate]
            rate[0].data=[]
            this.seriesRate =[...rate]
          },0)
        }
      },
      getTranWeek()
      {
         
        let now=new Date();
        let day = now.getDay();
        let first=now.getDate()-day+(day==0?-6:1);
        let a=new Date(now.setDate(first));
        let startWeek=new Date(a.getFullYear(),a.getMonth(),a.getDate());
        let n=new Date();
        let endWeek=new Date(n.getFullYear(),n.getMonth(),n.getDate(),23,59);
        this.dateQueryTran=`${formatTime(startWeek.getDate())}/${formatTime(startWeek.getMonth()+1)}/${startWeek.getFullYear()} - ${formatTime(endWeek.getDate())}/${formatTime(endWeek.getMonth()+1)}/${endWeek.getFullYear()}`
        let findRoom = this.tranData.findIndex(el=>el.site===this.siteSelected && el.room === this.roomSelected)

        if(findRoom>=0)
        {
          this.optionsTran={
            ...this.optionsTran,
            xaxis:{
              categories:[...this.tranData[findRoom].weekly.timeArr]
            }
          }
          setTimeout(()=>{
            let tran = [...this.seriesTran]
            tran[0].data = [...this.tranData[findRoom].weekly.borrow]
            tran[1].data = [...this.tranData[findRoom].weekly.return]
            this.seriesTran=[...tran]
          },0)
        }
        else
        {
          this.optionsTran={
            ...this.optionsTran,
            xaxis:{
              categories:[]
            }
          }
          setTimeout(()=>{
            let tran =[...this.seriesTran]
            tran[0].data=[]
            tran[1].data=[]
            this.seriesTran =[...tran]

          },0)                 
        }

      },
      getTranMonth()
      {
        let now=new Date();
        let startMonth=new Date(now.getFullYear(),now.getMonth(),1);
        let endMonth=new Date(now.getFullYear(),now.getMonth(),now.getDate());
        this.dateQueryTran=`${formatTime(startMonth.getDate())}/${formatTime(startMonth.getMonth()+1)}/${startMonth.getFullYear()} - ${formatTime(endMonth.getDate())}/${formatTime(endMonth.getMonth()+1)}/${endMonth.getFullYear()}`  
        // this.optionsTran.title.text='Transaction Logs By Monthly'
        let findRoom = this.tranData.findIndex(el=>el.site===this.siteSelected && el.room === this.roomSelected)
        if(findRoom>=0)
        {
          if(Object.prototype.hasOwnProperty.call(this.tranData[findRoom],'monthly'))
          {
            this.optionsTran={
              ...this.optionsTran,
              xaxis:{
                categories:[...this.tranData[findRoom].monthly.timeArr]
              }
            }
            setTimeout(()=>{
              let tran = [...this.seriesTran]
              tran[0].data = [...this.tranData[findRoom].monthly.borrow]
              tran[1].data = [...this.tranData[findRoom].monthly.return]
              this.seriesTran=[...tran]
            },0)
          }
          else
          {       
            this.loading=true;
            let arrDate= getDaysArray(startMonth,endMonth)
            let timeArr=[]
            arrDate.forEach(el=>{
              let temp = `${this.monthArray[el.getMonth()]} ${formatTime(el.getDate())}, ${el.getFullYear()}`
              timeArr.push(temp)
            })
            let arrQuery = arrDate.map(el=> {return `${el.getMonth()+1}/${el.getDate()}/${el.getFullYear()}`})
            request('post','/dashboard/monthlog/'+this.siteSelected,{time:arrQuery,room:this.roomSelected})
            .then((trans)=>{
              // console.log(data)
              this.loading=false;
              this.optionsTran={
                ...this.optionsTran,
                xaxis:{
                  categories:[...timeArr]
                }
              }
              setTimeout(()=>{
                let tran = [...this.seriesTran]
                tran[0].data = [...trans.borrow]
                tran[1].data = [...trans.return]
                this.seriesTran=[...tran]
              },0)

              //update data
              this.tranData[findRoom]={
                ...this.tranData[findRoom],
                monthly:{
                  borrow:[...trans.borrow],
                  return:[...trans.return],
                  timeArr:[...timeArr]
                }
              }
            })
            .catch(err=>{
              console.log(err)
              alert('Error to get transactions')
              this.loading=false
            })
          }
        }
        else
        {
          this.optionsTran={
            ...this.optionsTran,
            xaxis:{
              categories:[]
            }
          }
          setTimeout(()=>{
            let tran =[...this.seriesTran]
            tran[0].data=[]
            tran[1].data=[]
            this.seriesTran =[...tran]

          },0)         
        }
      },
      getTranYear()
      {
        let now=new Date();
        let startMonth=new Date(now.getFullYear(),0,1);
        let endMonth=new Date(now.getFullYear(),now.getMonth(),now.getDate(),23,59);
        this.dateQueryTran=`${formatTime(startMonth.getDate())}/${formatTime(startMonth.getMonth()+1)}/${startMonth.getFullYear()} - ${formatTime(endMonth.getDate())}/${formatTime(endMonth.getMonth()+1)}/${endMonth.getFullYear()}`
        // this.optionsTran.title.text='Transaction Logs By Yearly'
        let findRoom = this.tranData.findIndex(el=>el.site===this.siteSelected && el.room === this.roomSelected)
        if(findRoom>=0)
        {
          if(Object.prototype.hasOwnProperty.call(this.tranData[findRoom],'yearly'))
          {
            this.optionsTran={
              ...this.optionsTran,
              xaxis:{
                categories:[...this.tranData[findRoom].yearly.timeArr]
              }
            }
            setTimeout(()=>{
              let tran = [...this.seriesTran]
              tran[0].data = [...this.tranData[findRoom].yearly.borrow]
              tran[1].data = [...this.tranData[findRoom].yearly.return]
              this.seriesTran=[...tran]
            },0)
          }
          else
          {       
            this.loading=true;
            let nowMonth = new Date().getMonth();
            let nowYear= new Date().getFullYear()
            let timeArr=[]
            let arrMonth=[]
            for(let i=0;i<=nowMonth;i++)
            {
              arrMonth.push({
                month:i,
                year:nowYear,
                numDay:dayInMonth(i+1,nowYear)
              })
              let temp = `${this.monthArray[i]}, ${nowYear}`
              timeArr.push(temp)
            }
            request('post','/dashboard/yearlog/'+this.siteSelected,{time:arrMonth,room:this.roomSelected})
            .then((trans)=>{
              this.loading=false

              this.optionsTran={
                ...this.optionsTran,
                xaxis:{
                  categories:[...timeArr]
                }
              }
              setTimeout(()=>{
                let tran = [...this.seriesTran]
                tran[0].data = [...trans.borrow]
                tran[1].data = [...trans.return]
                this.seriesTran=[...tran]
              },0)
              this.tranData[findRoom]={
                ...this.tranData[findRoom],
                yearly:{
                  borrow:[...trans.borrow],
                  return:[...trans.return],
                  timeArr:[...timeArr]
                }
              }
            })
            .catch(err=>{
              console.log(err)
              alert('Error to get transactions')
              this.loading=false
            })
          }
        }
        else
        {
          this.optionsTran={
            ...this.optionsTran,
            xaxis:{
              categories:[]
            }
          }
          setTimeout(()=>{
            let tran =[...this.seriesTran]
            tran[0].data=[]
            tran[1].data=[]
            this.seriesTran =[...tran]

          },0)        
        }
        
        // console.log(timeArr)

      }

  }
}
</script>
<style lang="scss">
  .dashboard_container{
    min-height: 100vh;
    --textColor:#6D6E71;
    background-color: #ECECEC;
    display:flex;
    flex-direction: column;
    // justify-content: center;
    position: relative;
    align-items:center;
    .main_nav{
      margin-top:96px;
      min-height: 48px !important;
      max-height: 48px;
      width: 100%;
      // margin-top:96px;
      background-color: white;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      font-size: 1rem;
      font-weight: bold;
      display: flex;
      justify-content:flex-start;
      align-content: flex-start;
      align-items: center;
      a{
        text-align: center;
        line-height:48px;
        min-width: 115px;
        height: 100%;
        color: #6D6E71 !important;
         &.router-link-exact-active {
          color: #0080F6 !important;
          border-bottom: 3px solid  #0080F6;
          border-radius: 4px 4px 0px 0px;
        }
      }
      a:hover{
        text-decoration: none !important;
      }
      a span{
        color: #6D6E71;
      }
      a span:hover{
        color: #0080F6;
      }
      img{
          position: relative;
          z-index: 100;
          height: 100vh;
      }
    }
    .dashboard-body {
      // background: yellow;
      padding: 0px 32px 72px 32px;
      width: 100%;
      // background-color: #ECECEC;
      text-align: left;
      flex-grow: 1;
      position: relative;
      z-index: 2;
      .selector_title{
       
        margin-bottom: 5px;
        color: #6D6E71;
      }
      .dash_selector{
        margin-top: 36px;
        width: 260px;
      }
      .dash_donut{
        display: flex;
        flex-direction: row;
        margin-top:24px;
        .chartUser{
          
          background-color: #fff;
          border-radius: 9px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
          padding: 16px;
          width: 350px;
        
        }
        .chartBin{
          
          margin-left: 28px;
          background-color: #fff;
          border-radius: 9px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
          padding: 16px;
          width: 350px;
        
        }
        .chartBar{
          width:100%
        }
        
        
      }
      .chartRate{
        margin-top:24px;  
        background-color: #fff;
        border-radius: 9px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        padding: 18px;
        width: 100%;
        position: relative;
        .title_chart{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 10px;
          .title_chart_content{
            margin-left: 10px;
            .title_chart_content_1{
              font-size: 16px;
              font-weight: bold;
              color:  #171A26;
            }
            .title_chart_content_1 + div{
              font-size: 14px;
              color:#6D6E71
            }
          }
        }
        .dash_loading{
          z-index: 1;
          width: calc(100vw - 100px);
          width: -moz-calc(100vw - 100px);
          width: -webkit-calc(100vw - 100px);
          height: 240px;
          position: absolute;
          top:80px;
          align-items: center;
          display: flex;
          justify-content: center;
          background-color:white;
        }
        .button_container{
          display: flex;
          flex-direction: row;
          margin-left: 16px;
          .dash_btn{
            margin-left: 16px;
            text-transform: none; 
            font-size: 1rem;
            height: 48px !important;
            width: 118px;
            margin-bottom: 16px;
            margin-right: 0px;
            border-radius: 4px;
            border: 2px solid #0080F7;
            color: #0080F7 !important;
            // border: 2px solid #0080F7;
              .md-button-content{
                display: flex;
                justify-content: center;
                align-items: center;
            }
          }
          .vhd-input-icon{
            border-radius: 2px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
            margin-bottom: 16px;
            // margin-left: 16px;
             margin-left: 30px;
          }
          .vhd-picker{
            margin-left: 250px;
          }
          
        } 
      }
      .dash_line{
        margin-top:24px;
        
        background-color: #fff;
        border-radius: 9px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        padding: 18px;
        width:100%;
        position: relative;
        .dash_loading{
          z-index: 1;
          width: calc(100vw - 100px);
          width: -moz-calc(100vw - 100px);
          width: -webkit-calc(100vw - 100px);
          height: 280px;
          position: absolute;
          top:50px;
          align-items: center;
          display: flex;
          justify-content: center;
          background-color:white;
        }
        .button_container{
          display: flex;
          flex-direction: row;
          margin-left: 16px;
          .dash_btn{
            margin-left: 16px;
            text-transform: none; 
            font-size: 1rem;
            height: 48px !important;
            width: 118px;
            margin-bottom: 16px;
            margin-right: 0px;
            border-radius: 4px;
            border: 2px solid #0080F7;
            color: #0080F7 !important;
            // border: 2px solid #0080F7;
              .md-button-content{
                display: flex;
                justify-content: center;
                align-items: center;
            }
          }
        }

      }
      
    }
  }
.sk-cube-grid {
  width: 40px;
  height: 40px;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color:#0080F6;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1); 
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  } 
}
</style>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>