<template>
  <div class="login_container">
    <Logo />
    <div class="form_container md-elevation-5">
      <h2>Login</h2>
      <div class="form_controller">
        <div class="group_name">
          <input
            type="text"
            name="username"
            class="input_name"
            placeholder="Login name"
            v-model.lazy="username"
          />
          <div>Login name</div>
        </div>
        <div class="group_password">
          <div :style="{ color: color }">Password</div>
          <div class="input_password">
            <input
              :type="input_type"
              name="password"
              placeholder="Password"
              v-model="password"
              @focus="handleFocus"
              @blur="handleBlur"
              v-on:keyup.enter="handleLogin"
            />
            <div @click="handleShowPass" class="show_icon" v-if="icon_enable">
              <md-icon>{{ icon_name }}</md-icon>
            </div>
          </div>
        </div>
        <md-button class="md-raised md-primary btn_login" @click="handleLogin"
          >Login</md-button
        >
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "../components/Footer";
import Logo from "@/components/Logo";
import request from "@/utils/request.js";
import tokenUtils from "@/utils/token.js";
export default {
  name: "Login",
  components: {
    Footer,
    Logo,
  },
  data() {
    return {
      icon_name: "visibility_off",
      username: "",
      password: "",
      color: "#6D6E71",
      input_type: "password",
      icon_enable: false,
    };
  },
  methods: {
    handleFocus() {
      this.color = "#0080F6";
    },
    handleBlur() {
      this.color = "#6D6E71";
    },
    handleShowPass() {
      if (this.icon_name === "visibility_off") {
        this.input_type = "text";
        this.icon_name = "visibility";
      } else {
        this.input_type = "password";
        this.icon_name = "visibility_off";
      }
    },
    handleLogin() {
      request("post", "/auth/login", {
        username: this.username,
        password: this.password,
      })
        .then((data) => {
          let { token } = data;
          tokenUtils.save(token);

          let payload = tokenUtils.load();
          this.$store.commit("changeUsername", payload.username);
          this.$store.commit("changeRole", payload.role);
          // this.$store.commit("changeSite", payload.site);

          this.$router.push("/");
        })
        .catch((err) => {
          console.error(err.message);
          if(err.message==="Unauthorized")
          {
            alert("Incorrect username or password.");
          }
          else{
            alert("No permisson to access!")
          }
          
        });
    },
  },
  watch: {
    password: function () {
      if (this.password.length > 0) {
        this.icon_enable = true;
      } else {
        this.icon_enable = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.login_container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  --textColor: #6d6e71;
  position: relative;
  z-index: 1;
}
.form_container {
  width: 500px;
  height: 440px;
  background-color: white;
  h2 {
    margin-top: 38px;
  }
  .form_controller {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 47px;

    .group_name {
      margin-bottom: 17px;
      display: flex;
      flex-direction: column-reverse;
      div {
        text-align: left;
        margin-bottom: 5px;
        color: var(--textColor);
      }
      input {
        width: 361px;
        height: 48px;
        background-color: #ececec;
        border-width: 0px;
        border-radius: 3px;
        padding: 8px;
      }
      input:focus {
        outline: none !important;
        border: 1px solid #0080f6 !important ;
      }
      input:focus + div {
        color: #0080f6;
      }
    }
    .group_password {
      margin-bottom: 53px;
      display: flex;
      flex-direction: column;
      div {
        text-align: left;
        margin-bottom: 5px;
        color: var(--textColor);
      }
      .input_password {
        width: 361px;
        height: 48px;
        position: relative;
        display: flex;
        align-items: center;
        input {
          width: 100%;
          height: 100%;
          background-color: #ececec;
          border-width: 0px;
          border-radius: 3px;
          padding: 8px;
          z-index: 0;
        }
        input:focus {
          outline: none !important;
          border: 1px solid #0080f6 !important;
        }

        .show_icon {
          position: absolute;
          right: 14px;
          margin: auto;
          z-index: 100;
        }
      }
    }
    .btn_login {
      width: 361px;
      height: 48px;
      font-weight: bold;
      text-transform: none;
      font-size: 1rem;
    }
  }
}
</style>
