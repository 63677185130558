<template>
    <div class="customer_home siteWithdraw_container">
        <div class="main_table">
            <div class="Trans__btn_container">
                <div class="search_query">
                  <input type="text"  class="input_text" placeholder="Search query" v-model="searchQuery">
                </div>
                <md-button class="md-raised md-primary Tran_btn btn_search" @click="doSearch"> 
                    <svg  style="margin-right:8px" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8184 21.9373L17.4468 16.5678C18.8966 14.9157 19.7777 12.7519 19.7777 10.3857C19.7777 5.21029 15.5653 1 10.3888 1C5.21133 0.998962 1 5.21029 1 10.3847C1 15.5591 5.21237 19.7704 10.3888 19.7704C12.7519 19.7704 14.9136 18.8924 16.5657 17.4468L21.9383 22.8174C22.0598 22.9398 22.2196 23 22.3784 23C22.5382 23 22.697 22.9388 22.8184 22.8174C23.0623 22.5745 23.0623 22.1802 22.8184 21.9373ZM10.3889 18.5251C5.89836 18.5251 2.24536 14.8731 2.24536 10.3847C2.24536 5.89626 5.89836 2.24429 10.3889 2.24429C14.8794 2.24429 18.5324 5.89626 18.5324 10.3847C18.5324 14.8731 14.8794 18.5251 10.3889 18.5251Z" fill="white"/>
                    </svg>
                    <strong>Search</strong>
                </md-button>
                <export-excel
                   type    = "csv"
                    name    = "LogTransaction.csv"
                    :fields="dataFieldsExport"
                    :before-generate="handleExport"
                    :data   = "totalLogSearch" 
                >
                    <md-button class="md-raised Tran_btn btn_export" @click="add_active=true">       
                        <svg style="margin-right:8px" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                            <path d="M23.75 4.87515L18.75 1.12514C18.5625 0.982639 18.3075 0.960139 18.0963 1.06639C17.8838 1.17264 17.75 1.38889 17.75 1.62514V3.50015H15.875C12.0838 3.50015 9 6.58391 9 10.3752C9 10.6652 9.2 10.9177 9.48125 10.9839C9.52875 10.9952 9.5775 11.0002 9.625 11.0002C9.8575 11.0002 10.0763 10.8702 10.1838 10.6552L10.3313 10.3614C11.29 8.44266 13.2175 7.25016 15.3625 7.25016H17.75V9.12516C17.75 9.36141 17.8838 9.57766 18.095 9.68391C18.3063 9.79016 18.5613 9.76766 18.75 9.62516L23.75 5.87515C23.9075 5.75765 24 5.5714 24 5.37515C24 5.1789 23.9075 4.99265 23.75 4.87515Z" fill="#0080F7"/>
                            <path d="M10.25 2L3.75 2C2.233 2 0.999998 3.233 0.999998 4.75L1 20.25C1 21.767 2.233 23 3.75 23L15.25 23C16.767 23 18 21.767 18 20.25L18 11.75C18 11.336 17.664 11 17.25 11C16.836 11 16.5 11.336 16.5 11.75L16.5 20.25C16.5 20.939 15.939 21.5 15.25 21.5L3.75 21.5C3.061 21.5 2.5 20.939 2.5 20.25L2.5 4.75C2.5 4.061 3.061 3.5 3.75 3.5L10.25 3.5C10.664 3.5 11 3.164 11 2.75C11 2.336 10.664 2 10.25 2Z" fill="#0080F7"/>
                            <path d="M13.25 19.5H5.75C5.336 19.5 5 19.164 5 18.75C5 18.336 5.336 18 5.75 18H13.25C13.664 18 14 18.336 14 18.75C14 19.164 13.664 19.5 13.25 19.5Z" fill="#0080F7"/>
                            <path d="M12.25 15.5H5.75C5.336 15.5 5 15.164 5 14.75C5 14.336 5.336 14 5.75 14H12.25C12.664 14 13 14.336 13 14.75C13 15.164 12.664 15.5 12.25 15.5Z" fill="#0080F7"/>
                            </g>
                            <defs>
                            <clipPath id="clip0">
                            <rect width="24" height="24" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        <strong>Export file</strong>
                    </md-button>
                 </export-excel> 
                <DatePicker class=" btn_time" @confirm="confirmFilterDate" />
            </div>
            <md-table v-model="transactions" md-card style="max-height:540px">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Location" class="Tran__room_cell" md-sort-by="roomName">{{ item.roomName ? item.roomName : ''}}</md-table-cell>
                <md-table-cell md-label="Shelf" class="Tran__shelf_cell" md-sort-by="shelfName">{{ item.shelfName ? item.shelfName : '' }}</md-table-cell>
                <md-table-cell md-label="Bin" class="Tran__time_cell" md-sort-by="binName">{{ item.binName }}</md-table-cell>
                <md-table-cell md-label="Item" class="Tran__time_cell" md-sort-by="itemName">{{ item.itemName }}</md-table-cell>
                <md-table-cell md-label="Part No" class="Tran__time_cell" md-sort-by="partNo">{{ item.partNo }}</md-table-cell>
                <md-table-cell md-label="Serial number" class="Tran__time_cell" md-sort-by="serialNumber">{{ item.serialNumber }}</md-table-cell>
                <md-table-cell class="Tran__user_cell" md-label="Employee Number">{{ item.user ? item.user.emNum : '' }}</md-table-cell>
                <md-table-cell class="Tran__user_cell" md-label="Full Name" >{{ item.user ? item.user.name : '' }}</md-table-cell>
                <md-table-cell class="Tran__user_cell" md-label="Company">{{ item.user ? item.user.dept : '' }}</md-table-cell>
                <md-table-cell class="Tran__user_cell" md-label="Position">{{ item.user ? item.user.position : '' }}</md-table-cell>
                <md-table-cell class="Tran__user_cell" md-label="Picked quantity">{{ item.quantityChanged }}</md-table-cell>
                <md-table-cell class="Tran__user_cell" md-label="Type of Service">{{ item.serviceType?'Return':'Picked' }}</md-table-cell>
                <md-table-cell md-label="Operation Time" class="Tran__time_cell">{{item.operationTime}}</md-table-cell>
                </md-table-row>
            </md-table>
        </div>

        <Pagination  class="table_pagination" v-if=' pagiShow'
          v-model="currentPage" 
          :page-count="totalPages"
          @change="onChange"
        />
</div>
</template>
<script>
import request from '@/utils/request'
import DatePicker from '../../components/DatePicker'
import Pagination from '../../components/Pagination'

export default {
  name:'SiteWithdraw',
  components:{
      DatePicker,
      Pagination
  },
  created() {
    request('get', `/transactions/${this.currentPage}?site=${this.siteId}`)
        .then(data => {
        this.transactions = data.transactions
        this.totalPages=data.pages
        })
        .catch(err => console.log(err))
  },
  data(){
    return{
      currentPage: 1,
      totalPages:0,
      siteId: this.$route.params.id,
      transactions: [],
      startDate: '',
      endDate: '',
      searchQuery: '',
      totalLogSearch:[],
      dataFieldsExport:{
          "Location":"roomName",
           "Shelf":"shelfName",
           "Bin":"binName",
           "Item":"itemName",
           "Part No":"partNo",
           "Serial Number":"serialNumber",
           "Employee Number":"user.emNum",
           "Full Name":"user.name",
           "Company":"user.dept",
           "Position":"user.position",
           "Picker quantity":"quantityChanged",
           "Type of Service":"serviceType",
           "Time":"operationTime"
      }
    }
    
  },
  methods: {
    onChange: function () {
        console.log(this.startDate)
       request('get',  `/transactions/${this.currentPage}?site=${this.siteId}&startDate=${this.startDate}&endDate=${this.endDate}&query=${this.searchQuery}`)
      .then(data => {
        this.transactions = data.transactions;
        this.totalPages=data.pages;
      })
      .catch(err => console.log(err))
    },
    confirmFilterDate(date) {
        this.startDate = date.start
        this.endDate = date.end
        request('get',  `/transactions/1?site=${this.siteId}&startDate=${this.startDate}&endDate=${this.endDate}&query=${this.searchQuery}`)
            .then(data => {
                this.currentPage = 1
                this.transactions = data.transactions;
                this.totalPages=data.pages;
            })
            .catch(err => console.log(err))
    },
    doSearch() {
        request('get',  `/transactions/1?site=${this.siteId}&startDate=${this.startDate}&endDate=${this.endDate}&query=${this.searchQuery}`)
            .then(data => {
                this.currentPage = 1
                this.transactions = data.transactions;
                this.totalPages=data.pages;
            })
            .catch(err => console.log(err))
    },
    handleExport: async function()
    {
        this.totalLogSearch=[]
        try{
            let data = await request('post',  `/transactions/export?site=${this.siteId}&startDate=${this.startDate}&endDate=${this.endDate}`,{query:this.searchQuery})
            data.transactions.forEach(el=>el.serviceType=el.serviceType?"Return":"Picked")
            this.totalLogSearch = data.transactions;
        }
        catch(err)
        {
            console.log(err)
            alert("Failed to export data!")
        }
    },
    convertTime(time)
    {
        let dt = new Date(time)
        return `${
            (dt.getFullYear()).toString().padStart(4, '0')}-${
            (dt.getMonth()+1).toString().padStart(2, '0')}-${
            dt.getDate().toString().padStart(2, '0')} ${
            dt.getHours().toString().padStart(2, '0')}:${
            dt.getMinutes().toString().padStart(2, '0')}:${
            dt.getSeconds().toString().padStart(2, '0')}`
    }
  },
  computed: {
    pagiShow() {
      if(this.totalPages>0) {
          return true;
      }
      return false;
    }
  }
}
</script>
<style lang="scss" >
 .siteWithdraw_container{
     width: 100%;
     display: flex;
     justify-content: center;

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .main_table{
        align-items:flex-end;
        position: relative;
        z-index: 1;
        .md-table-cell{
            height: 56px !important;
        }
        .Tran__user_cell{
            width: 120px;
        }
        .Tran__device_cell,.Tran__quantity_cell,.Tran__shelf_cell,.Tran__room_cell
        ,.Tran__site_cell{
            width:110px;
        }
        .Tran__time_cell{
            width:150px;
        }

        .Trans__btn_container{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            position: relative;
            width: 100%;

            .search_query {
                input {
                    font-size: 1rem;
                    padding: 14px 8px 13px 8px;
                    position: relative;
                    top: 6px;
                    background-color:white;
                    border-width: 0px;
                    border-radius: 2px;
                }
                 .input_select:hover,:focus{
                    outline: none !important;
                    border:1px solid #0080F6 !important ;
                }
            }

            .Tran_btn{
                // background-color: #0080F7;
                border-radius: 4px;
                text-transform: none; 
                font-size: 1rem;
                height: 48px !important;
                margin-bottom: 26px;
                margin-right: 0px;
                border-radius: 4px;
                // border: 2px solid #0080F7;
                 .md-button-content{
                     display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .btn_search{
                margin-right: 24px;
                width: 147px;
            }
            .btn_export{
                width: 171px;
                background: white;
                border: 2px solid #0080F7;
                color: #0080F7 !important;
               
               
            }
            .btn_time{
                
                position: absolute;
                left: 0px;
                top:5px;
                
            }
        }
    }
    
 }

</style>