export default function(file, max_size = 512){
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.onload = (readerEvent) => {
      let image = new Image();
      image.onload = () => {

            // Resize the image
            let canvas = document.createElement('canvas'),
                width = image.width,
                height = image.height;
            if (width > height) {
                if (width > max_size) {
                    height *= max_size / width;
                    width = max_size;
                }
            } else {
                if (height > max_size) {
                    width *= max_size / height;
                    height = max_size;
                }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL('image/jpeg');
            
            resolve(dataUrl)
        }
        image.src = readerEvent.target.result;
    }
    reader.readAsDataURL(file);
  })
}