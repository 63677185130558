<template>
  <div class="site_container">
    <Header></Header>
    <nav class="main_nav">
      <router-link to="/" style="width:80px"><md-icon style="color:#0080F6">home</md-icon></router-link>
      <router-link :to="`/site/${id}/setting`">Setting</router-link> 
      <router-link :to="`/site/${id}/user`" style="margin-right:20px">User</router-link>
      <router-link :to="`/site/${id}/shelf`" style="margin-right:15px">Location Shelf</router-link>
      <router-link :to="`/site/${id}/bin`">Bin</router-link>
      <router-link :to="`/site/${id}/item`" style="width:72px;margin-right:25px">Item</router-link>
      <router-link :to="`/site/${id}/transaction`">Transaction</router-link>

      <!-- <div class="animation start-home"></div> -->
    </nav>
    <div class="site-body">
      <transition name="fade">
        <router-view />
      </transition>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from '../../components/Footer'
import Header from '../../components/Header'

export default {
  name:'Site',
  components:{
    Footer,
    Header
  },
  data(){
    return{
      id: this.$route.params.id,
    }
  },
  // created()
  // {
  //   let siteList=this.$store.state.site
  //   let siteArr= siteList.split(',')
  //   console.log(siteArr,this.$store.state.site)
  //   if (!siteArr.includes(this.id))
  //   {
  //     return this.$router.push('/')
  //   }

  // }
}
</script>
<style lang="scss">
  .site_container{
    min-height: 100vh;
    --textColor:#6D6E71;
    background-color: #ECECEC;
    display:flex;
    flex-direction: column;
    // justify-content: center;
    align-items:flex-start;
    .main_nav{
      margin-top:96px;
      min-height: 48px !important;
      max-height: 48px;
      width: 100%;
      // margin-top:96px;
      background-color: white;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      font-size: 1rem;
      font-weight: bold;
      display: flex;
      justify-content:flex-start;
      align-content: center;
      align-items: center;
      a{
        text-align: center;
        line-height:48px;
        min-width: 115px;
        height: 100%;
        
        color: #6D6E71 !important;
         &.router-link-exact-active {
          color: #0080F6 !important;
          border-bottom: 3px solid  #0080F6;
          border-radius: 4px 4px 0px 0px;
        }
      }
      a:hover{
        text-decoration: none !important;
      }
      a span{
        color: #6D6E71;
      }
      a span:hover{
        color: #0080F6;
      }
    }

    .site-body {
      padding: 0px 32px 72px 32px;
      width: 100%;
      // height: auto;
      // background-color: #ECECEC;
      text-align: left;
      flex-grow: 1;
      position: relative;
      z-index: 1;
    }
  }
</style>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>