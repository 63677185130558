<template>
    <div class="customer_home">
      <div class="main_table">
      <md-button class="md-raised md-primary btn_customer" @click="openAddCustomerForm"> <md-icon class="md-size-2x">add</md-icon>Add Customer</md-button>
      <md-table v-model="customers" md-card style="max-height:540px">
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Logo" md-sort-by="logo" md-image><img :src="item.logo" alt="avatar" v-if="item.logo"></md-table-cell>
          <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
          <!-- <md-table-cell md-label="Site" md-sort-by="site">{{ item.site }}</md-table-cell>
          <md-table-cell md-label="System" md-sort-by="system">{{ item.system }}</md-table-cell> -->
          <md-table-cell md-label="Address" md-sort-by="address">{{ item.address }}</md-table-cell>
          <md-table-cell md-label="Phone" md-sort-by="phone">{{item.phone}}</md-table-cell>
          <md-table-cell md-label="Email" md-sort-by="email">{{item.email}}</md-table-cell>
          <md-table-cell md-label="Status" md-sort-by="status"><div :style="{color:item.active?'green':'red'}">{{item.active ? 'Active' : 'Inactive'}}</div></md-table-cell>
          <md-table-cell md-label="" md-sort-by="func" >
            <!-- <div @click="handleFunc(item)"></div> -->
            <md-menu md-size="medium" md-align-trigger>
              <md-button md-menu-trigger><md-icon>more_horiz</md-icon></md-button>
              <md-menu-content>
                <md-menu-item @click="showAssignSite(item)">Assign Sites/Projects</md-menu-item>
                <md-menu-item @click="showReset(item)">Reset Password</md-menu-item>
                <md-menu-item @click="showUpdate(item)">Update</md-menu-item>
                <md-menu-item @click="showDelete(item)"><div style="color:red">Delete</div></md-menu-item>
              </md-menu-content>
            </md-menu>
          </md-table-cell>
        </md-table-row>
      </md-table>
      
    </div>
    <Pagination  class="table_pagination" v-if="pagiShow"
        v-model="currentPage" 
        :page-count="totalPages"
        @change="onChange"
    />
    <md-dialog :md-active.sync="delete_active" class="dialog_delete">
        <md-dialog-title class="dialog_delete__title">
          <div><strong>Delete Customer</strong></div>
          <div @click="delete_active=false"><md-icon class="icon_close">clear</md-icon></div>
        </md-dialog-title>
        <md-dialog-content class="dialog_delete___content">
          <span>Do you want to delete this customer?</span>
          <br>
          <span>All related data will alse be deleted.</span>
        </md-dialog-content>
        <md-dialog-actions class="dialog_delete__button">
          <md-button class="md-raised btn_cancel" @click="delete_active = false">Cancel</md-button>
          <md-button class="md-raised md-accent btn_delete" @click="handleDeleteCustomer">Delete</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="reset_active" class="dialog_delete">
        <md-dialog-title class="dialog_delete__title">
          <div><strong>Reset Password</strong></div>
         
          <div @click="delete_active=false"><md-icon class="icon_close">clear</md-icon></div>
        </md-dialog-title>
        <md-dialog-content class="dialog_delete___content">
          <span>Do you want to reset password of this customer?</span>
          <br>
          <span style="margin-right:15px">The new password will be sent via customer's email.</span>
          <md-progress-spinner  v-if="loadingReset" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
        </md-dialog-content>
        <md-dialog-actions class="dialog_delete__button">
          <md-button class="md-raised btn_cancel" @click="reset_active = false">Cancel</md-button>
          <md-button class="md-raised md-accent btn_delete" @click="handleReset">Reset</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="reset_password_active" class="dialog_delete">
        <md-dialog-title class="dialog_delete__title">
          <div><strong>Reset Password</strong></div>
          <div @click="reset_password_active=false"><md-icon class="icon_close">clear</md-icon></div>
        </md-dialog-title>
        <md-dialog-content class="dialog_delete___content">
          <span>New password will be sent to customer email.</span>
        </md-dialog-content>
        <md-dialog-actions class="dialog_delete__button">
          <md-button class="md-raised btn_cancel" @click="reset_password_active = false">Cancel</md-button>
          <md-button class="md-raised md-accent btn_delete" @click="handleResetPassword">Confirm</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="update_active" class="dialog_update">
      <div class="dialog_update__title">
        <div><strong>Update Customer</strong></div>
        <div style="cursor: pointer" @click="update_active=false"><md-icon class="icon_close">clear</md-icon></div>
      </div>
      <form class="dialog_update__texts" ref="updateCustomerForm">
        <div class="dialog_update__text_logo">
          <div class="label">Logo</div>
          <div class="image_logo">
            <img :src="tempCustomer.logo" v-if="tempCustomer.logo" alt="logo">
            <label>
              <input type="file" @change="addCustomerChangeLogo"/> Add logo
            </label>
          </div>
        </div>
        <div class="dialog_update__text_1">
          <input type="text"  class="input_text" v-model="tempCustomer.name" required>
          <div class="label">
            Customer Name*
          </div>
        </div>
         <div class="dialog_update__text_1">
          <input type="text"  class="input_text" v-model="tempCustomer.address">
          <div class="label">
            Address
          </div>
        </div>
        <div class="dialog_update__text_2">
          <div class="text_phone_1">
            <input type="text"  class="input_text" v-model="tempCustomer.phone">
            <div class="label">
              Phone
            </div>
          </div>
          <div class="text_phone ">
            <input type="text"  class="input_text" v-model="tempCustomer.tax">
            <div class="label">
              Tax
            </div>
          </div>
        </div>
        <div class="dialog_update__text_2">
          <div class="text_phone_1">
            <input type="email"  class="input_text" v-model="tempCustomer.email" required>
            <div class="label">
              Email
            </div>
          </div>
          <div class="text_phone ">
            <input type="url"  class="input_text"  v-model="tempCustomer.website">
            <div class="label">
              Website
            </div>
          </div>
        </div>
         <div class="dialog_update_radios">
            <div class="label">
                Status
            </div>
            <div class="dialog_update_radio">
                <md-radio v-model="tempCustomer.active" class="md-primary" :value="true">Active</md-radio>
                <md-radio v-model="tempCustomer.active" class="md-primary" :value="false">Inactive</md-radio>
            </div>
        </div>
      </form>
      <div class="dialog_update__btn">
         <md-button class="md-raised btn_cancel" @click="update_active = false">Cancel</md-button>
         <md-button class="md-raised md-primary btn_update" @click="handleUpdateCustomer">Update</md-button>
      </div>
    </md-dialog>

    <md-dialog class="dialog_assign" :md-active.sync="assign_active" @click="list_sites_show=false">
      <div class="dialog_assign__title">
        <div><strong>Assign Sites/Projects</strong></div>
        <div @click="assign_active=false"><md-icon class="icon_close">clear</md-icon></div>
      </div>
      <div class="dialog_assign__selects">
        <div class="dialog_assign__select">
          <div class="site_lists" v-if="list_sites_show">
            <li v-for="(item,i) in sites" :key="i" @click.stop="toggleSite(i)">
              <div class="">{{item.name}}</div>
              <md-checkbox class="md-primary " v-model="site_selected" :value="i"></md-checkbox>
            </li>
          </div>
          <md-icon class="icon">keyboard_arrow_down</md-icon>
          <div @click.stop="list_sites_show=!list_sites_show"><input type="text" class="input_select" placeholder="Sites/Projects" disabled v-model="num_sites_selected"></div>
          <div class="label" :style="{color:list_sites_show?'#0080F6':'#6D6E71'}">
            Sites/Projects
          </div>
        </div>
      </div>
     
      <div class="dialog__assign_chips" v-show=" num_sites_selected.length>0">
        <div class="dialog__assign_content">Sites/Projects assigned</div>
        <div class="chip_container">
          <md-chip  v-for="index in site_selected" :key="index" clickable class="dialog__assign_chip">
            <div>
              {{sites[index].name}}
            </div>
            <div class="chip_icon" @click="handleRemoveSites(site)">
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7M7 1L1 7" stroke="#6D6E71" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>    
            </div>
          </md-chip>
        </div>
        
      </div>
      <div class="dialog_assign__btn">
         <md-button class="md-raised btn_cancel" @click="assign_active = false">Cancel</md-button>
         <md-button class="md-raised md-primary btn_assign" @click.stop="handleAssignSites">Assign</md-button>
      </div>
    </md-dialog>

    <md-dialog :md-active.sync="add_active" class="dialog_update">
      <div class="dialog_update__title">
        <div><strong>Add Customer</strong></div>
        <div style="cursor: pointer" @click="add_active=false"><md-icon class="icon_close">clear</md-icon></div>
      </div>
      <form class="dialog_update__texts" ref="addCustomerForm">
        <div class="dialog_update__text_logo">
          <div class="label">Logo</div>
          <div class="image_logo">
            <!-- <img :src="tempCustomer.logo" v-if="tempCustomer.logo" alt="logo"> -->
            <div class="image" :style="`background-image: url(${tempCustomer.logo});`"><md-icon style="color:#D7D7D7;" v-if="!tempCustomer.logo">camera_alt</md-icon></div>
            <label style="margin-right:20px">
              <input type="file" @change="addCustomerChangeLogo"/> Add logo
            </label>
            <md-progress-spinner v-if="loadingCustomer"  :md-diameter="50" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
          </div>
        </div>
        <div class="dialog_update__text_1">
          <input type="text"  class="input_text" v-model="tempCustomer.name" required>
          <div class="label">
            Customer Name*
          </div>
        </div>
         <div class="dialog_update__text_1">
          <input type="text"  class="input_text" v-model="tempCustomer.address">
          <div class="label">
            Address
          </div>
        </div>
        <div class="dialog_update__text_2">
          <div class="text_phone_1">
            <input type="text"  class="input_text" v-model="tempCustomer.phone">
            <div class="label">
              Phone
            </div>
          </div>
          <div class="text_phone ">
            <input type="text"  class="input_text" v-model="tempCustomer.tax">
            <div class="label">
              Tax
            </div>
          </div>
        </div>
        <div class="dialog_update__text_2">
          <div class="text_phone_1">
            <input type="email"  class="input_text" v-model="tempCustomer.email" required>
            <div class="label">
              Email
            </div>
          </div>
          <div class="text_phone ">
            <input type="url"  class="input_text"  v-model="tempCustomer.website">
            <div class="label">
              Website
            </div>
          </div>
        </div>
        <div class="dialog_update_radios">
            <div class="label">
                Status
            </div>
            <div class="dialog_update_radio">
                <md-radio v-model="tempCustomer.active" class="md-primary" :value="true">Active</md-radio>
                <md-radio v-model="tempCustomer.active" class="md-primary" :value="false">Inactive</md-radio>
            </div>
        </div>
      </form>
      <div class="dialog_update__btn">
         <md-button class="md-raised btn_cancel" @click="add_active = false">Cancel</md-button>
         <md-button :disabled='btnAddShow' class="md-raised md-primary btn_update" @click="handleAddCustomer">Add</md-button>
      </div>
    </md-dialog>
</div>
</template>
<script>
import request from '@/utils/request'
import resize from '@/utils/resize'
import Pagination from '../../components/Pagination'

export default {
  components: { Pagination },
  name:'CustomerHome',
  created() {
    request('get', '/customers/'+this.currentPage)
      .then(data => {
        this.customers = data.customers
        this.totalPages=data.pages;
      })
      .catch(err => console.log(err))

    request('get', '/sites/noimg')
      .then(data => {
        this.sites = data.sites
      })
      .catch(err => console.log(err))
  },
  data(){
    return{
      loadingCustomer:false,
      loadingReset:false,
      btnAddShow:false,
      btnResetShow:false,
      currentPage: 1,
      totalPages:0,
      update_active:false,
      delete_active:false,
      reset_password_active: false,
      assign_active:false,
      reset_active:false,
      add_active:false,
      list_sites_show:false,
      num_sites_selected:'',
      site_assigned_show:false,
      sites:[],
      tempCustomer: {
        logo: '',
        name: '',
        site: '',
        system: '',
        address: '',
        phone: '',
        email: '',
        active: true
      },
      site_selected:[],
      customers: []
    }
  },
  methods:{
    onChange: function () {
      // eslint-disable-next-line no-console
      // console.log(this.currentPage)
       request('get', '/customers/'+this.currentPage)
      .then(data => {
        // console.log(data)
        this.customers = data.customers;
        this.totalPages=data.pages;
      })
      .catch(err => console.log(err))
    },
    openAddCustomerForm() {
      this.add_active = true
      this.tempCustomer = {
        logo: '',
        name: '',
        site: '',
        system: '',
        address: '',
        phone: '',
        email: '',
        active: true
      }
    },
    showAssignSite(el){
      this.tempCustomer={
        ...el
      }
      this.site_selected = []

      for (let j = 0; j < this.sites.length; j++) {
        if (this.sites[j].customer == this.tempCustomer._id) {
          this.site_selected.push(j)
        }
      }
      this.assign_active=true
    },
    showUpdate(el){
      this.tempCustomer={
        ...el
      }
      this.update_active=true
    },
    showDelete(el){
      this.tempCustomer={
        ...el
      }
      this.delete_active=true;
    },
    showReset(el){
      this.tempCustomer={
        ...el,
      }
      this.reset_active=true;
    },
    addCustomerChangeLogo(e) {
      let file = e.target.files[0]

      if(file.type.match(/image.*/)) {
        resize(file)
          .then(dataUrl => {
            this.tempCustomer.logo = dataUrl
          })
      }
    },
    toggleSite(i) {
      if (this.site_selected.includes(i)) {
        let index = this.site_selected.indexOf(i)
        this.site_selected.splice(index, 1)
      } else {
        this.site_selected.push(i)
      }
    },
    handleReset(){
      // console.log("hello")
      this.btnResetShow=true;
      this.loadingReset=true;
      console.log(this.tempCustomer._id)
       request('put', `/customers/reset/${this.tempCustomer._id}`,{name:this.tempCustomer.name,email:this.tempCustomer.email})
      .then(()=>{
        this.btnResetShow=false;
        this.loadingReset=false;
        this.reset_active=false;
        
      })
      .catch(err=>{
        alert("Failed to reset password!")
        console.log(err)
        this.btnResetShow=false;
        this.loadingReset=false;
      })

    },
    handleAddCustomer() {
      if (this.$refs.addCustomerForm.reportValidity()) {
        this.loadingCustomer=true;
        this.btnAddShow=true;
        request('post', '/customers', this.tempCustomer)
          .then(() => {
            return request('get', '/customers/'+this.currentPage)

          })
          .then(data => {
            this.customers = data.customers
            this.totalPages=data.pages;
            this.add_active = false
            this.loadingCustomer=false;
            this.btnAddShow=false;
          })
          .catch(() => {
            this.loadingCustomer=false;
            this.btnAddShow=false;
            alert("Customer name exist.")
          })
       
      }
    },
    handleUpdateCustomer() {
      if (this.$refs.updateCustomerForm.reportValidity()) {
        request('put', '/customers', this.tempCustomer)
          .then(data => {
            for (let i = 0; i < this.customers.length; i++) {
              if (this.customers[i]._id == data.customer._id) {
                this.customers[i] = data.customer
                break
              }
            }
            this.update_active = false
          })
          .catch(err => {
            alert(err.message)
          })
      }
    },
    handleRemoveSites(i){
      let index=this.site_selected.findIndex(el=>el===i);
      this.site_selected.splice(index,1);
    },
    handleDeleteCustomer(){
      request('delete', `/customers/${this.tempCustomer._id}`)
        .then(() => {
          // for (let i = 0; i < this.customers.length; i++) {
          //   if (this.customers[i]._id == this.tempCustomer._id) {
          //     this.customers.splice(i, 1)
          //     break
          //   }
          // }
          if(this.customers.length===1 && this.currentPage>1)
          {
            this.currentPage=this.currentPage-1;
          }
          return request('get', '/customers/'+this.currentPage)
         
        })
        .then(data => {
            this.customers = data.customers
            this.totalPages=data.pages;
            this.delete_active=false;
        })
        .catch(err => {
          alert(err.message)
        })
    },
    handleResetPassword() {
      request('post', `/customers/${this.tempCustomer._id}/reset-password`)
        .then(() => {
          // for (let i = 0; i < this.customers.length; i++) {
          //   if (this.customers[i]._id == this.tempCustomer._id) {
          //     this.customers.splice(i, 1)
          //     break
          //   }
          // }
          if(this.customers.length===1 && this.currentPage>1)
          {
            this.currentPage=this.currentPage-1;
          }
          return request('get', '/customers/'+this.currentPage)
         
        })
        .then(data => {
            this.customers = data.customers
            this.totalPages=data.pages;
            this.delete_active=false;
        })
        .catch(err => {
          alert(err.message)
        })
    },
    handleAssignSites(){
      let siteIds = []
      let removeSiteIds = []
      for (let i = 0; i < this.sites.length; i++) {
        if (this.site_selected.includes(i)) {
          siteIds.push(this.sites[i]._id)
        } else if (this.sites[i].customer == this.tempCustomer._id) {
          removeSiteIds.push(this.sites[i]._id)
        }
      }

      request('put', '/customers/assign', {
        _id: this.tempCustomer._id,
        sites: siteIds,
        removeSites: removeSiteIds
      })
        .then(() => {
          for (let i = 0; i < this.sites.length; i++) {
            if (this.site_selected.includes(i)) {
              this.sites[i].customer = this.tempCustomer._id
            } else if (this.sites[i].customer == this.tempCustomer._id) {
              this.sites[i].customer = ''
            }
          }
          this.assign_active=false;
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  watch:{
    site_selected:function(){
      if(this.site_selected.length>0)
      {
        this.num_sites_selected=this.site_selected.length+" Sites/Projects";
      }
      else
      {
        this.num_sites_selected='';
      }
    }
  },
  computed:{
    pagiShow(){
      if(this.totalPages===0)
      {
        return false;
      }
      return true;
    }
  }
}
</script>
<style lang="scss" >

    .customer_home{
      margin-top: 25px;
    }
    .main_table{
      position: relative;
      z-index: 0;
      width: 100%;
      // height: 648px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .btn_customer{
        background-color: #0080F7;
        border-radius: 4px;
        font-weight: bold;
        text-transform: none; 
        font-size: 1rem;
        height: 48px !important;
        width: 189px !important;
        // z-index: 1;
        margin-bottom: 26px;
        margin-right: 0px;
        
        .md-size-2x{
            font-weight: normal;
        }
      }
      img{
        height:73px;
        width: 73px;
      }
      .md-table-head{
        background-color: #ECECEC;
      }
      .md-table-cell{
        height: 80px !important;
      }
      .md-table-head-label,.md-table-cell-container {
        color: black;
        font-size: 1rem;
      }
      .md-table {
        border-collapse: collapse;
        width: 100%;
      }

      td, th {
        border: 2px solid #D7D7D7;
        text-align: left;
      }
      /deep/ .md-table {
        min-width: 100%;
      }
    }
    .table_pagination{
      width: 100%;
      margin-left: 0px;
    }
  .dialog_delete{
    .md-dialog-container
    {
      width: 516px !important;
      min-height: 240px ;
      max-height: 240px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
    }
    .dialog_delete__title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      margin: 20px 40px;
      div{
        font-size: 1.4rem;
      }
      .icon_close:hover{
        color: red !important;
      }
    }
    .dialog_delete___content{
      margin-left: 15px;
      color: #6D6E71;
    }
    .dialog_delete__button{
      padding: 0px;
      margin-bottom: 32px;
      .btn_delete{
        width: 164px;
        height: 50px;
        text-transform: none;
        font-weight: bold;
        border-radius: 4px;
        margin-right: 32px;
      }
      .btn_cancel{
        width: 100px;
        height: 50px;
        text-transform: none;
        font-weight: bold;
        border-radius: 4px;
        margin-right: 14px;
      }
    }
  }
  .dialog_update{
    // overflow-y: initial !important;
    .md-dialog-container
    {
      max-height: 100vh;
      // max-height: calc(100vh - 200px);
      width: 516px ;
      padding: 22px 32px 32px 38px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      // overflow-y: auto;
    } 
    .dialog_update__title{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      div{
        font-size: 1.4rem;
      }
      .icon_close:hover{
        color: red !important;
      }
      
    }
    .dialog_update__texts{
      max-height: calc(100% - 300px);
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 80px;
      // width: 100%;
      margin-top: 22px;
      .label{
        font-size: 0.8rem;
        color:#6D6E71;
        margin-bottom: 5px;
      }
      .input_text{
        color: black;
        font-size: 1rem;
        height: 48px;
        background-color: #ECECEC;
        border-width: 0px;
        border-radius: 2px;
        padding: 8px;
      }
      .input_text:focus{
        outline: none !important;
        border:1px solid #0080F6 !important ;
      }
      .input_text:focus + div {
        color:#0080F6;
      }
      .dialog_update__text_logo{
        display: flex;
        flex-direction: column;
        .image_logo{
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          height: 70px;
          .image{
            background-position: center;
            background-size: cover;
            background-color: #ECECEC;
            width: 120px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            // margin-bottom:7px
          }
        }
        img{
          width: 120px;
          height: 70px;
          background-color: #D7D7D7;
          border-radius: 4px;
        }
        input[type="file"] {
          display: none;
        }
        label{
            cursor: pointer;
            text-decoration: underline;
            color: #0080F7;
            font-size: 0.8rem;
            margin-left: 17px;

        }
      }
      .dialog_update__text_1{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 20px;
      }
      .dialog_update__text_2{
        margin-top: 20px;
        display: flex;
        // flex-direction: row;
        justify-content: space-between;
        .text_phone{
          display:flex;
          flex-direction: column-reverse;
          width: 50%;
          
        }
         .text_phone_1{
          display:flex;
          flex-direction: column-reverse;
          width: 50%;
          margin-right: 10px;
        }
      }

      .dialog_update__selects{
        margin-top: 25px;
        .input_select{
          color: black;
          font-size: 1rem;
          height: 48px;
          width: 100%;
          background-color: #ECECEC;
          border-width: 0px;
          border-radius: 2px;
          padding: 8px;
          z-index: 1;
        }
        .input_select:hover{
          outline: none !important;
          border:1px solid #0080F6 !important ;
        }
        .dialog_update__select{
          display: flex;
          flex-direction: column-reverse;
          position: relative;
          .icon{
            position: absolute;
            left: 412px;
            top:36px;
          }
          .status_lists{
            width: 100%;
            list-style-type: none;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            background-color: white;
            position: absolute;
            top:75px;
            z-index: 15;
            li{
              height: 35px;
              display: flex;
              align-items:center;
              justify-content: space-between;
              font-size: 1rem;
              div{
                margin-left: 19px;
              }

            }
            li:hover{
              background-color: #CFECF9;
            }
          }
        }

      }

      .dialog_update_radios{
        margin-top: 24px;
        .md-primary{
            margin-right: 58px;
        }
      }

    }
    .dialog_update__btn{
      height: 50px;
      margin-top: 13px;
      padding: 0px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      position: absolute;
      bottom: 32px;
      width: 446px;
       .btn_update{
        width: 164px;
        height: 50px;
        text-transform: none;
        font-weight: bold;
        border-radius: 4px;
        margin-right: 0px;
      }
      .btn_cancel{
        width: 100px;
        height: 50px;
        text-transform: none;
        font-weight: bold;
        border-radius: 4px;
        margin-right: 14px;
      }
    }
    
  }
  .dialog_assign{
    .md-dialog-container
    {
      min-height: 277px;
      max-height: 400px;
      width: 516px;
      padding: 22px 32px 32px 38px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      overflow: visible;
    }
    .dialog_assign__title{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      div{
        font-size: 1.4rem;
      }
      .icon_close:hover{
        color: red !important;
      }
      
    }
    .dialog_assign__selects{
      margin-top: 23px;
      .label{
        font-size: 0.8rem;
        color:#6D6E71;
        margin-bottom: 5px;
      }
      .input_select{
        color: black;
        font-size: 1rem;
        height: 48px;
        width: 100%;
        background-color: #ECECEC;
        border-width: 0px;
        border-radius: 2px;
        padding: 8px;
      }
      .input_select:hover{
        outline: none !important;
        border:1px solid #0080F6 !important ;
      }
      .dialog_assign__select{
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        .icon{
          position: absolute;
          left: 412px;
          top:36px;
        }
        .site_lists{
          width: 100%;
          position: absolute;
          top:75px;
          z-index: 15;
          background-color: white;
          list-style-type: none;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
          border-radius: 4px;

          li{
            height: 35px;
            display: flex;
            align-items:center;
            justify-content: space-between;
            font-size: 1rem;
            div{
              margin-left: 19px;
            }

          }
          li:hover{
            background-color: #CFECF9;
          }
        }
      }

    }
    .dialog__assign_chips{
      margin-top: 16px;
      margin-bottom: 84px;
      display: flex;
      flex-direction: column ;
      .dialog__assign_content{
        font-size: 0.8rem;
        color:#6D6E71;
        margin-bottom: 15px;
      }
      .chip_container{
        display: flex;
        flex-direction: row ;
      }
      .dialog__assign_chip{
        
        margin-right: 10px;
        position: relative;
       
        .chip_icon{
          position: absolute;
          top:0px;
          right: 0px;
          width: 16px;
          height: 16px;
          background-color: #ECECEC;
          border-radius: 50%;
          font-size: 5px;
          display:flex;
          justify-content: center;
          align-items: center;
        }
        .chip_icon:hover{
          background-color:#f28577;
        }
      }
     
    }
     .dialog_assign__btn{
        height: 50px;
        padding: 0px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        bottom:32px;
        width: 446px;
        .btn_assign{
          width: 164px;
          height: 50px;
          text-transform: none;
          font-weight: bold;
          border-radius: 4px;
          margin-right: 0px;
        }
        .btn_cancel{
          width: 100px;
          height: 50px;
          text-transform: none;
          font-weight: bold;
          border-radius: 4px;
          margin-right: 14px;
        }
      }

  }
</style>
