<template>
  <div class="pie-wrapper">
    <span class="label">{{percentage}}%</span>
    <div class="pie" :style="percentage > 50 ? 'clip: rect(auto, auto, auto, auto);' : ''">
      <div class="left-side half-circle" :style="leftSideStyle" v-if="percentage > 0"></div>
      <div class="right-side half-circle" :style="rightSideStyle" v-if="percentage > 50"></div>
    </div>
    <div class="shadow"></div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      default: 0
    },
    progressColor: {
      type: String
    }
  },
  computed: {
    leftSideStyle() {
      return `transform: rotate(${parseInt(this.percentage * 3.6)}deg); border-color: ${this.progressColor};`
    },
    rightSideStyle() {
      return `transform: rotate(180deg); border-color: ${this.progressColor};`
    }
  }
}
</script>

<style lang="scss" scoped>
$default-size: 1em;

.pie-wrapper {
  width: $default-size;
  height: $default-size;
  position: relative;
  font-size: 4em;
  background-color: #fff;
  padding: 2px;
  border-radius: 50%;
  
  .label {
    font-size: 12px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
  }
  
  .pie {
    width: 100%;
    height: 100%;
    clip: rect(0, $default-size, $default-size, $default-size / 2);
    left: 0;
    position: absolute;
    top: 0;

    .half-circle {
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      border: ($default-size / 10) solid transparent;
      border-radius: 50%;
      clip: rect(0, $default-size / 2, $default-size, 0);
      left: 2px;
      position: absolute;
      top: 2px;
    }
    
  }

  .shadow {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: $default-size / 10 solid #bdc3c7;
    border-radius: 50%;
  }
}
</style>