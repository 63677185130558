<template>
  <div class="customer_home siteItem_container">
    <div class="main_table">
      <div class="User__btn_container" v-if='accessRight3'>
        <md-button class="md-raised md-primary btn_customer" @click="showAdd"> <md-icon class="md-size-2x">add</md-icon>Add Item</md-button>
      </div>
      <md-table v-model="currentSiteItems" md-card style="max-height:540px">
          <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell class="Items__item_cell" md-label="Item Name" md-sort-by="name">{{ item.name }}</md-table-cell>
          <md-table-cell class="Items__part_cell" md-label="Part Number " md-sort-by="part">{{ item.partNumber }}</md-table-cell>
          <md-table-cell md-label="Serial Number" class="Items__quantity_cell" md-sort-by="serialNumber">{{ item.serialNumber }}</md-table-cell>
          <md-table-cell md-label="Expired date" class="Items__quantity_cell" md-sort-by="expiredDate">{{ item.expiredDate }}</md-table-cell>
          <!-- <md-table-cell md-label="Bin" class="Items__quantity_cell" md-sort-by="bin">{{ item.bin.name }}</md-table-cell> -->
          <md-table-cell md-label="Description" class="Items__description_cell" md-sort-by="quantity">{{ item.description }}</md-table-cell>
          <md-table-cell md-label="Status" class="Items__status_cell" md-sort-by="status"><div :style="{color:item.active?'green':'red'}">{{item.active ? 'Active' : 'Inactive'}}</div></md-table-cell>
          <md-table-cell md-label="" md-sort-by="func" v-if='accessRight3'>
              <md-menu md-size="medium" md-align-trigger>
              <md-button md-menu-trigger><md-icon>more_horiz</md-icon></md-button>
              <md-menu-content>
                  <md-menu-item @click="showUpdate(item)">Update</md-menu-item>
                  <md-menu-item @click="showDelete(item)"><div style="color:red">Delete</div></md-menu-item>
              </md-menu-content>
              </md-menu>
          </md-table-cell>
          </md-table-row>
      </md-table>
    </div>
     <Pagination  class="table_pagination" v-if='pagiShow'
          v-model="currentPage" 
          :page-count="totalPages"
          @change="onChange"
      />
    <md-dialog :md-active.sync="delete_active" class="dialog_delete">
        <md-dialog-title class="dialog_delete__title">
          <div><strong>Delete Item</strong></div>
          <div @click="delete_active=false"><md-icon class="icon_close">clear</md-icon></div>
        </md-dialog-title>
        <md-dialog-content class="dialog_delete___content">
          <span>Do you want to delete this item?</span>
          <br>
          <span>All related data will alse be deleted.</span>
        </md-dialog-content>
        <md-dialog-actions class="dialog_delete__button">
          <md-button class="md-raised btn_cancel" @click="delete_active = false">Cancel</md-button>
          <md-button class="md-raised md-accent btn_delete" @click="handleDeleteItem">Delete</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="update_active" class="dialog_updateItem dialog_update">
      <div class="dialog_update__title">
        <div><strong>Update Item</strong></div>
        <div @click="update_active=false;list_bin_show=false"><md-icon class="icon_close ">clear</md-icon></div>
      </div>
      <form class="dialog_update__texts dialog_updateItem_texts" ref="updateItemForm">
        <div class="dialog_update__text_1">
          <input type="text"  class="input_text" placeholder="Item Name" v-model="tempItem.name" required>
          <div class="label">
            Item Name*
          </div>
        </div>
        <div class="dialog_update__text_2">
          <div class="text_phone_1">
            <input type="text" placeholder=" Part Number" class="input_text" v-model="tempItem.partNumber" required>
            <div class="label">
              Part Number*
            </div>
          </div>

          <div class="text_phone ">
            <input type="text" placeholder="Serial Number"  class="input_text"  v-model="tempItem.serialNumber">
            <div class="label">
              Serial Number
            </div>
          </div>
        </div>

        <!-- <div class="dialog_update__text_2">
          <div class="text_phone">
            <input type="text" placeholder="Expired date" class="input_text" v-model="tempItem.expiredDate" required>
            <div class="label">
              Expired date
            </div>
          </div>
        </div> -->
        <div class="dialog_update__text_1">
          <input type="text"  class="input_text" placeholder="Expired date" v-model="tempItem.expiredDate" required>
          <div class="label">
            Expired date*
          </div>
        </div>
        <div class="dialog_update__text_1">
          <input type="text"  class="input_text" placeholder="Description" v-model="tempItem.description">
          <div class="label">
          Description
          </div>
        </div>
        <div class="dialog_update__selects">
          <div class="dialog_update__select">
            <div class="status_lists" v-if="list_bin_show">
              <li v-for="(item,index) in currentSiteBins" :key="index" @click="selectBin(item)">
                <div >{{item.name}}</div>
              </li>
            </div>
            <md-icon class="icon">keyboard_arrow_down</md-icon>
            <div @click="list_bin_show=!list_bin_show"><input type="text" class="input_select" placeholder="Bin" disabled v-model="bin_selected.name"></div>
            <div class="label" :style="{color:list_bin_show?'#0080F6':'#6D6E71'}">
              Bin
            </div>
          </div>
        </div>
        <div class="dialog_update_radios">
          <div class="label">
            Status
          </div>
          <div class="dialog_update_radio">
            <md-radio v-model="tempItem.active" class="md-primary" :value="true">Active</md-radio>
            <md-radio v-model="tempItem.active" class="md-primary" :value="false">Inactive</md-radio>
          </div>
        </div>
      </form>
    
    
      <div class="dialog_update__btn">
        <md-button class="md-raised btn_cancel" @click="update_active = false;list_bin_show=false">Cancel</md-button>
        <md-button class="md-raised md-primary btn_update" @click="handleUpdateItem">Save</md-button>
      </div>
    </md-dialog>


    <md-dialog :md-active.sync="add_active" class="dialog_updateItem dialog_update">
      <div class="dialog_update__title">
        <div><strong>Add Item</strong></div>
        <div @click="add_active=false;list_bin_show=false"><md-icon class="icon_close ">clear</md-icon></div>
      </div>
      <form class="dialog_update__texts dialog_updateItem_texts" ref="addItemForm">
        <div class="dialog_update__text_1">
          <input type="text"  class="input_text" placeholder="Item Name" v-model="tempItem.name" required>
          <div class="label">
            Item Name*
          </div>
        </div>
        <div class="dialog_update__text_2">
          <div class="text_phone_1">
            <input type="text" placeholder=" Part Number" class="input_text" v-model="tempItem.partNumber" required>
            <div class="label">
              Part Number*
            </div>
          </div>

          <div class="text_phone ">
            <input type="text" placeholder="Serial Number"  class="input_text"  v-model="tempItem.serialNumber">
            <div class="label">
              Serial Number
            </div>
          </div>
        </div>

        <!-- <div class="dialog_update__text_2">
          <div class="text_phone">
            <input type="text" placeholder="Expired date" class="input_text" v-model="tempItem.expiredDate" required>
            <div class="label">
              Expired date
            </div>
          </div>

        </div> -->
        <div class="dialog_update__text_1">
          <input type="text"  class="input_text" placeholder="Expired date" v-model="tempItem.expiredDate" required>
          <div class="label">
            Expired date*
          </div>
        </div>
        <div class="dialog_update__text_1">
          <input type="text"  class="input_text" placeholder="Description" v-model="tempItem.description">
          <div class="label">
          Description
          </div>
        </div>

        <div class="dialog_update__selects">
          <div class="dialog_update__select">
            <div class="status_lists" v-if="list_bin_show">
              <li v-for="(item,index) in currentSiteBins" :key="index" @click="selectBin(item)">
                <div >{{item.name}}</div>
              </li>
            </div>
            <md-icon class="icon">keyboard_arrow_down</md-icon>
            <div @click="list_bin_show=!list_bin_show"><input type="text" class="input_select" placeholder="Bin" disabled v-model="bin_selected.name"></div>
            <div class="label" :style="{color:list_bin_show?'#0080F6':'#6D6E71'}">
              Bin
            </div>
          </div>
        </div>
        <div class="dialog_update_radios">
          <div class="label">
            Status
          </div>
          <div class="dialog_update_radio">
            <md-radio v-model="tempItem.active" class="md-primary" :value="true">Active</md-radio>
            <md-radio v-model="tempItem.active" class="md-primary" :value="false">Inactive</md-radio>
          </div>
        </div>
      </form>
    
    
      <div class="dialog_update__btn">
        <md-button class="md-raised btn_cancel" @click="add_active = false;list_bin_show=false">Cancel</md-button>
        <md-button class="md-raised md-primary btn_update" @click="handleAddItem">Add</md-button>
      </div>
    </md-dialog>
  </div>
</template>
<script>
import request from '@/utils/request'
import Pagination from '../../components/Pagination'
export default {
  name:'SiteItem',
  components:{
    Pagination
  },
  created() {
    request('get', `/items/${this.currentPage}/?site=${this.siteId}`)
      .then(data => {
        this.items = data.items
        this.totalPages=data.pages
      })
      .catch(err => console.log(err))
    request('post', `/bins/mini?site=${this.siteId}`,{mes:1})
      .then(data => {
        this.bins = data.bins
      })
      .catch(err => console.log(err))
  },
  data(){
    return{
      currentPage: 1,
      totalPages:0,
      siteId: this.$route.params.id,
      update_active:false,
      delete_active:false,
      add_active:false,
      list_status_show:false,
      list_bin_show:false,
      status_selected:'',
      bin_selected: {},
      items: [],
      bins:[],
      tempItem: {},


    }
  },
  computed: {
    pagiShow() {
      if(this.totalPages>0) {
          return true;
      }
      return false;
    },
    currentSiteItems() {
      return this.items.filter(item => {
        return item.site && (item.site == this.siteId || item.site._id == this.siteId)
      })
    },
    currentSiteBins() {
      return this.bins.filter(bin => {
        return bin.site && (bin.site == this.siteId || bin.site._id == this.siteId)
      })
    },
    accessRight3(){
      let roleRight=['ROOT','CUSTOMER','MASTER','OPERATOR','CONTRACTOR'];
      return roleRight.includes(this.$store.state.role)
    },
  },
  methods:{
    onChange: function () {
       request('get',  `/items/${this.currentPage}?site=${this.siteId}`)
      .then(data => {
        this.items = data.items;
        this.totalPages=data.pages;
      })
      .catch(err => console.log(err))
    },
    showAdd() {
      this.tempItem = {
        name: '',
        description: '',
        partNumber: '',
        capacity: 0,
        bin: null,
        site: null,
        active: true
      }
      this.add_active=true
    },
    showUpdate(el){
      this.tempItem = {
        ...el
      }

      this.bin_selected = {}
      if (el.bin && el.bin._id) {
        this.bin_selected = el.bin
      } else {
        for (let i = 0; i < this.bins.length; i++) {
          if (this.bins[i]._id == el.bin) {
            this.bin_selected = this.bins[i]
            break
          }
        }
      }
      this.update_active=true;
    },
    showDelete(el){
      this.tempItem = el
      this.delete_active=true;
    },
    handleDeleteItem(){
      request('delete', `/items/${this.tempItem._id}`, {site: this.tempItem.site})
      .then(() => {
        if(this.items.length===1 && this.currentPage>1)
        {
          this.currentPage=this.currentPage-1;
        }
        return request('get',  `/items/${this.currentPage}?site=${this.siteId}`)
      })
      .then(data => {
        this.delete_active=false;
        this.items = data.items;
        this.totalPages=data.pages;
      })       
      .catch(err => {
        alert(err.message)
      })
    },
    handleUpdateItem(){
      // console.log(this.tempItem)
      if (this.bin_selected) {
        this.tempItem.bin = this.bin_selected._id
      } else {
        this.tempItem.bin = null
      }
      this.tempItem.site = this.siteId

      if (this.$refs.updateItemForm.reportValidity()) {
        request('put', '/items', this.tempItem)
          .then(data => {
            for (let i = 0; i < this.items.length; i++) {
              if (this.items[i].bin == data.item.bin) {
                this.items[i].bin = null
                break
              }
            }

            for (let i = 0; i < this.items.length; i++) {
              if (this.items[i]._id == data.item._id) {
                this.items.splice(i, 1, data.item)
                break
              }
            }
            this.update_active = false
          })
          .catch(err => {
            console.log(err)
            alert(err.message)
          })
      }
    },
    handleAddItem(){
      if (this.bin_selected) {
        this.tempItem.bin = this.bin_selected._id
      }
      this.tempItem.site = this.siteId

      if (this.$refs.addItemForm.reportValidity()) {
        request('post', '/items', this.tempItem)
        .then(() => {
          return request('get',  `/items/${this.currentPage}?site=${this.siteId}`)
          
        })
        .then(data => {
          this.add_active = false
          this.items = data.items;
          this.totalPages=data.pages;
        }) 
        .catch(err => {
          console.log(err)
          alert(err.message)
        })
      }
    },
    selectBin(el){
      this.bin_selected=el;
      this.list_bin_show=false;
    }
  },
}
</script>
<style lang="scss" >
 .siteItem_container{
     width: 100%;
     display: flex;
     justify-content: center;
     flex-direction: column;
    .main_table{
        align-items:flex-end;
        .md-table-cell{
            height: 56px !important;
        }
        .Items__item_cell{
            width: 330px;
        }
        .Items__part_cell{
            width:230px;
        }
        .Items__quantity_cell{
            width: 229px;
        }
        .Items__description_cell{
            width: 328px;
        }
        .Items__status_cell{
            width: 158px;
        }
    }
    
 }
 .dialog_updateItem{
   .md-dialog-container
    {
      max-height: 100vh;
      overflow: visible;
    }
    .dialog_updateItem_texts{
      max-height: calc(100% - 300px);
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 80px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 0px;
      padding: 0;
    }
 }

</style>