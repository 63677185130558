<template>
  <div class="dashboard_container">
    <Header />
    <div class="main_sites">
      <div class="main_site" @click="handleCustomerRoute" v-if="$store.state.role == 'ROOT'">
        <img src="../assets/customer.png" alt="customer">
        <div>Manage</div>
      </div>
      <div class="main_site" @click="handleDashRoute" v-if="accessRight">
        <img src="../assets/dashboard.png" alt="dashboard">
        <div>DashBoard</div>
      </div>
      <div class="main_site" v-for="(data,index) in sites" :key="index" @click="handleSiteRoute(data._id)">
          <img :src="data.smallImage || data.mediumImage || data.originalImage" :alt="data.name">
          <div>{{data.name}}</div>
      </div>
      
    </div>
    <Footer></Footer>
    
  </div>
</template>
<script>
import Footer from '../components/Footer'
import request from '@/utils/request'
import tokenUtils from '../utils/token'
import Header from '../components/Header'
export default {
  name:'Home',
  components:{
    Footer,
    Header,
  },
  created() {
    // console.log(this.$store.state)
    request('get', '/sites')
      .then(data => {
        this.sites = data.sites
      })
      .catch(err => console.log(err))
  },
  data(){
    return{
      customerInfo: null,
      sites: [],
    }
  },
  computed:{
    accessRight(){
      let roleRight=['ROOT','CUSTOMER'];
      return roleRight.includes(this.$store.state.role)
    }
  },
  methods:{
    handleCustomerRoute(){
      this.$router.push('/customer/home')
    },
    handleSiteRoute(_id) {
      this.$router.push(`/site/${_id}/setting`)
    },
    handleDashRoute(){
      this.$router.push('/dashboard/')
    },
    logout(){
      tokenUtils.remove();
      this.$router.push('/login')
    }
  }
}
</script>
<style lang="scss" scoped>
  .dashboard_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    --textColor:#6D6E71;
    background-color: #ECECEC;
  }
  .main_sites{
    position: relative;
    z-index: 1;
    margin-top:149px;
    display: flex;
    justify-content: center;
    .main_site{
      width:160px ;
      height: 162px;
      background-color: white;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left:24px;
      margin-right: 24px;
      cursor: pointer;
      img{
        width: 80px;
        height: 80px;
        margin-bottom: 16px;
      }
      div{
        font-weight: bold;
        font-size: 1.2rem;
      }
    }
    .main_site:hover{
     box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    }
  }
    

</style>