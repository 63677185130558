<template>
  <div class="customer_home siteWithdraw_container">
    <div class="main_table">
      <div class="User__btn_container">
        <md-button v-if='accessRight3' class="md-raised md-primary btn_customer" @click="showAdd"> <md-icon class="md-size-2x">add</md-icon>Add Bin</md-button>
        <md-button class="md-raised User_btn btn_export">       
          <svg style="margin-right:8px" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <path d="M23.75 4.87515L18.75 1.12514C18.5625 0.982639 18.3075 0.960139 18.0963 1.06639C17.8838 1.17264 17.75 1.38889 17.75 1.62514V3.50015H15.875C12.0838 3.50015 9 6.58391 9 10.3752C9 10.6652 9.2 10.9177 9.48125 10.9839C9.52875 10.9952 9.5775 11.0002 9.625 11.0002C9.8575 11.0002 10.0763 10.8702 10.1838 10.6552L10.3313 10.3614C11.29 8.44266 13.2175 7.25016 15.3625 7.25016H17.75V9.12516C17.75 9.36141 17.8838 9.57766 18.095 9.68391C18.3063 9.79016 18.5613 9.76766 18.75 9.62516L23.75 5.87515C23.9075 5.75765 24 5.5714 24 5.37515C24 5.1789 23.9075 4.99265 23.75 4.87515Z" fill="#0080F7"/>
              <path d="M10.25 2L3.75 2C2.233 2 0.999998 3.233 0.999998 4.75L1 20.25C1 21.767 2.233 23 3.75 23L15.25 23C16.767 23 18 21.767 18 20.25L18 11.75C18 11.336 17.664 11 17.25 11C16.836 11 16.5 11.336 16.5 11.75L16.5 20.25C16.5 20.939 15.939 21.5 15.25 21.5L3.75 21.5C3.061 21.5 2.5 20.939 2.5 20.25L2.5 4.75C2.5 4.061 3.061 3.5 3.75 3.5L10.25 3.5C10.664 3.5 11 3.164 11 2.75C11 2.336 10.664 2 10.25 2Z" fill="#0080F7"/>
              <path d="M13.25 19.5H5.75C5.336 19.5 5 19.164 5 18.75C5 18.336 5.336 18 5.75 18H13.25C13.664 18 14 18.336 14 18.75C14 19.164 13.664 19.5 13.25 19.5Z" fill="#0080F7"/>
              <path d="M12.25 15.5H5.75C5.336 15.5 5 15.164 5 14.75C5 14.336 5.336 14 5.75 14H12.25C12.664 14 13 14.336 13 14.75C13 15.164 12.664 15.5 12.25 15.5Z" fill="#0080F7"/>
              </g>
              <defs>
              <clipPath id="clip0">
              <rect width="24" height="24" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          <strong>Export File</strong>
        </md-button>
      </div>
      <div class="bin-grid">
        <div class="filters">
          <select class="custom_selector" v-model="filterShelf">
            <option class='custom_selector_item' v-for="(shelf, index) in shelves" :key="index" :value="shelf._id">{{"Shelf " +shelf.name +" in "+ shelf.room.name}}</option>
          </select>
          
        </div>
        <div class="statuses">
          <div class="status status-good">Good</div>
          <div class="status status-low">Charging</div>
          <div class="status status-critical">Fail</div>
          <div class="status status-unassigned">On-loan</div>
        </div>
        <div class="md-tabs">
          <div id="tab-home">
            <div class="bin-wrapper" v-for="(bin, index) in bins" :key="index">
              <Bin :id="bin._id" :name="bin.name" :deviceId="bin.item ? bin.item.partNumber:''" :quantity="bin.item ? bin.quantity : -1" :capacity="bin.capacity?bin.capacity:1" :itemStatus="bin.itemStatus" :percent="bin.percent" @update="showUpdate(bin)" @delete="showDelete(bin)" @detail="showDetail(bin)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Pagination  class="table_pagination" v-if='pagiShow'
      v-model="currentPage" 
      :page-count="totalPages"
      @change="onChange"
    />
    <md-dialog :md-active.sync="delete_active" class="dialog_delete">
        <md-dialog-title class="dialog_delete__title">
          <div><strong>Delete Bin</strong></div>
          <div @click="delete_active=false"><md-icon class="icon_close">clear</md-icon></div>
        </md-dialog-title>
        <md-dialog-content class="dialog_delete___content">
          <span>Do you want to delete this bin?</span>
          <br>
          <span>All related data will alse be deleted.</span>
        </md-dialog-content>
        <md-dialog-actions class="dialog_delete__button">
          <md-button class="md-raised btn_cancel" @click="delete_active = false">Cancel</md-button>
          <md-button class="md-raised md-accent btn_delete" @click="handleDeleteBin">Delete</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="detail_active" class="dialog_details">
        <md-dialog-title class="dialog_details__title">
          <div><strong>Bin Details</strong></div>
          <div @click="detail_active=false"><md-icon class="icon_close">clear</md-icon></div>
        </md-dialog-title>
        <md-dialog-content class="dialog_details___content">
          <h3>General information</h3>

          <div class="general-infos">
            <div class="general-info">
              <div class="label">Item name</div>
              <div class="value">{{tempBin.item?tempBin.item.name: ''}}</div>
            </div>
            <div class="general-info">
              <div class="label">Part Number</div>
              <div class="value">{{tempBin.item?tempBin.item.partNumber : ''}}</div>
            </div>
            <div class="general-info">
              <div class="label">Quantity</div>
              <div class="value">{{tempBin.quantity}}</div>
            </div>
            <div class="general-info">
              <div class="label">Capacity</div>
              <div class="value">{{tempBin.capacity}}</div>
            </div>
            <div class="general-info">
              <div class="label">Bin name</div>
              <div class="value">{{tempBin.name || ''}}</div>
            </div>
            <div class="general-info">
              <div class="label">Device ID</div>
              <div class="value">{{tempBin._id || ''}}</div>
            </div>
          </div>

          <h3>Item Status</h3>
          <div class="threshold-infos">
            <div class="threshold-info">
              <div class="label">Percent</div>
              <div class="value">{{tempBin.percent || 0}}</div>
            </div>
            <div class="threshold-info">
              <div class="label">Item Status</div>
              <div class="value">{{convertItemStatus(tempBin.itemStatus) || 'On-loan'}}</div>
            </div>
          </div>
        </md-dialog-content>
        <div class="dialog_detail__btn">
          <md-button class="md-raised btn_cancel" @click="detail_active = false">Close</md-button>
        </div>
    </md-dialog>

    <md-dialog :md-active.sync="update_active" class="dialog_update dialog_updateBin">
      <div class="dialog_update__title">
        <div><strong>Edit Bin</strong></div>
        <div @click="closeUpdate"><md-icon class="icon_close ">clear</md-icon></div>
      </div>
      <form class="dialog_update__texts dialog_updateBin_texts" ref="updateBinForm">
        <div class="dialog_update__text_1">
          <input type="text"  class="input_text" placeholder="Device ID/Bin ID" v-model="tempBin._id" required readonly>
          <div class="label">
            Device ID/Bin ID*
          </div>
        </div>

        <div class="dialog_update__text_1">
          <input type="text"  class="input_text" placeholder="Bin Name" v-model="tempBin.name" required>
          <div class="label">
            Bin Name*
          </div>
        </div>

        <div class="input-group">
          <div class="dialog_update__selects">
            <div class="dialog_update__select">
              <div class="status_lists room_lists" v-if="list_room_show">
                <li v-for="(item, index) in rooms" :key="index" @click="chosenRoom=item; shelf_selected={}; list_room_show=false">
                  <div >{{item.name}}</div>
                </li>
              </div>
              <md-icon class="icon">keyboard_arrow_down</md-icon>
              <div @click="list_room_show=!list_room_show"><input type="text" class="input_select" placeholder="Location" disabled v-model="chosenRoom.name" required></div>
              <div class="label" :style="{color:list_room_show?'#0080F6':'#6D6E71'}">
                Location
              </div>
            </div>
          </div>
          <div class="dialog_update__selects">
            <div class="dialog_update__select">
              <div class="status_lists room_lists" v-if="list_shelf_show">
                <li v-for="(item, index) in currentSiteShelves" :key="index" @click="selectShelf(item)">
                  <div >{{item.name}}</div>
                </li>
              </div>
              <md-icon class="icon">keyboard_arrow_down</md-icon>
              <div @click="list_shelf_show=!list_shelf_show"><input type="text" class="input_select" placeholder="Shelf" disabled v-model="shelf_selected.name" required></div>
              <div class="label" :style="{color:list_shelf_show?'#0080F6':'#6D6E71'}">
                Shelf
              </div>
            </div>
          </div>
        </div>
        <div class="dialog_update__text_2" style="margin-top:0px;position:relative;">
          <div class="text_phone_1" style="width:220px">
            <input type="number"  class="input_text" placeholder="Percent" v-model="tempBin.percent" min=0 max=100>
            <div class="label">
              Percent 
            </div>
          </div>
          <div class="dialog_update__selects" style="width:220px">
            <div class="dialog_update__select">
              <div class="status_lists" v-if="list_itemStatus_show">
                <li v-for="(item, index) in itemStatusList" :key="index" @click="selectItemStatus(item)">
                  <div >{{item}}</div>
                </li>
              </div>
              <md-icon class="icon" style="left:190px">keyboard_arrow_down</md-icon>
              <div @click="list_itemStatus_show=!list_itemStatus_show"><input type="text" class="input_select" placeholder="Item Status" disabled v-model="itemStatusSelected" required></div>
              <div class="label" :style="{color:list_itemStatus_show?'#0080F6':'#6D6E71'}">
                Item Status
              </div>
            </div>
          </div>
        </div>

        <div class="dialog_update__selects">
          <div class="dialog_update__select">
            <div class="status_lists" v-if="list_item_show" >
              <li v-for="(item, index) in currentSiteItems" :key="index" @click="selectItem(item)">
                <div >{{item.name}}</div>
              </li>
            </div>
            <md-icon class="icon">keyboard_arrow_down</md-icon>
            <div @click="list_item_show=!list_item_show"><input type="text" class="input_select" placeholder="Item" disabled v-model="item_selected.name"></div>
            <div class="label" :style="{color:list_item_show?'#0080F6':'#6D6E71'}">
              Item 
            </div>
          </div>
        </div>

        <div class="dialog_update_radios">
          <div class="label">
            Status
          </div>
          <div class="dialog_update_radio">
            <md-radio v-model="tempBin.active" class="md-primary" :value="true">Active</md-radio>
            <md-radio v-model="tempBin.active" class="md-primary" :value="false">Inactive</md-radio>
          </div>
        </div>
      </form>
    
    
      <div class="dialog_update__btn">
        <md-button class="md-raised btn_cancel" @click="closeUpdate">Cancel</md-button>
        <md-button class="md-raised md-primary btn_update" @click="handleEditBin">Update</md-button>
      </div>
    </md-dialog>




    <!-- add bin diaglog  -->
    <md-dialog :md-active.sync="add_active" class="dialog_update dialog_updateBin">
      <div class="dialog_update__title">
        <div><strong>Add Bin</strong></div>
        <div @click="closeUpdate"><md-icon class="icon_close ">clear</md-icon></div>
      </div>
      <form class="dialog_update__texts dialog_updateBin_texts" ref="addBinForm">
        <div class="dialog_update__text_1">
          <input type="text"  class="input_text" placeholder="Device ID/Bin ID" v-model="tempBin._id" required>
          <div class="label">
            Device ID/Bin ID*
          </div>
        </div>

        <div class="dialog_update__text_1">
          <input type="text"  class="input_text" placeholder="Bin Name" v-model="tempBin.name" required>
          <div class="label">
            Bin Name*
          </div>
        </div>

        <div class="input-group">
          <div class="dialog_update__selects">
            <div class="dialog_update__select">
              <div class="status_lists room_lists" v-if="list_room_show">
                <li v-for="(item, index) in rooms" :key="index" @click="chosenRoom=item; shelf_selected={}; list_room_show=false">
                  <div >{{item.name}}</div>
                </li>
              </div>
              <md-icon class="icon">keyboard_arrow_down</md-icon>
              <div @click="list_room_show=!list_room_show"><input type="text" class="input_select" placeholder="Location" disabled v-model="chosenRoom.name" required></div>
              <div class="label" :style="{color:list_room_show?'#0080F6':'#6D6E71'}">
                Location
              </div>
            </div>
          </div>
          <div class="dialog_update__selects">
            <div class="dialog_update__select">
              <div class="status_lists" v-if="list_shelf_show">
                <li v-for="(item, index) in currentSiteShelves" :key="index" @click="selectShelf(item)">
                  <div >{{item.name}}</div>
                </li>
              </div>
              <md-icon class="icon">keyboard_arrow_down</md-icon>
              <div @click="list_shelf_show=!list_shelf_show"><input type="text" class="input_select" placeholder="Shelf" disabled v-model="shelf_selected.name" required></div>
              <div class="label" :style="{color:list_shelf_show?'#0080F6':'#6D6E71'}">
                Shelf
              </div>
            </div>
          </div>
        </div>
        <div class="dialog_update__text_2" style="margin-top:0px;position:relative;">
          <div class="text_phone_1" style="width:220px">
            <input type="number"  class="input_text" placeholder="Percent" v-model="tempBin.percent" min=0 max=100>
            <div class="label">
              Percent 
            </div>
          </div>
          <div class="dialog_update__selects" style="width:220px">
            <div class="dialog_update__select">
              <div class="status_lists" v-if="list_itemStatus_show">
                <li v-for="(item, index) in itemStatusList" :key="index" @click="selectItemStatus(item)">
                  <div >{{item}}</div>
                </li>
              </div>
              <md-icon class="icon" style="left:190px">keyboard_arrow_down</md-icon>
              <div @click="list_itemStatus_show=!list_itemStatus_show"><input type="text" class="input_select" placeholder="Item Status" disabled v-model="itemStatusSelected" required></div>
              <div class="label" :style="{color:list_itemStatus_show?'#0080F6':'#6D6E71'}">
                Item Status
              </div>
            </div>
          </div>
        </div>
        <div class="dialog_update__selects">
          <div class="dialog_update__select">
            <div class="status_lists" v-if="list_item_show">
              <li v-for="(item, index) in currentSiteItems" :key="index" @click="selectItem(item)">
                <div >{{item.name}}</div>
              </li>
            </div>
            <md-icon class="icon" >keyboard_arrow_down</md-icon>
            <div @click="list_item_show=!list_item_show"><input type="text" class="input_select" placeholder="Item" disabled v-model="item_selected.name"></div>
            <div class="label" :style="{color:list_item_show?'#0080F6':'#6D6E71'}">
              Item
            </div>
          </div>
        </div>
        

        <div class="dialog_update_radios">
          <div class="label">
            Status
          </div>
          <div class="dialog_update_radio">
            <md-radio v-model="tempBin.active" class="md-primary" :value="true">Active</md-radio>
            <md-radio v-model="tempBin.active" class="md-primary" :value="false">Inactive</md-radio>
          </div>
        </div>
      </form>
    
    
      <div class="dialog_update__btn">
        <md-button class="md-raised btn_cancel" @click="closeUpdate">Cancel</md-button>
        <md-button class="md-raised md-primary btn_update" @click="handleAddBin">Add</md-button>
      </div>
    </md-dialog>
  </div>
</template>
<script>
import Bin from '@/components/Bin'
import request from '@/utils/request'
import Pagination from '../../components/Pagination'
export default {
  name:'SiteBin',
  components: {
    Bin,
    Pagination
  },
  created() {
    // request('get', `/bins?site=${this.siteId}`)
    //   .then(data => {
    //     this.bins = data.bins
    //   })
    //   .catch(err => console.log(err))

    request('post', `/items/mini?site=${this.siteId}`,{mess:1})
      .then(data => {
        this.items = data.items
        // console.log(this.items)
      })
      .catch(err => {console.log(err);alert(err.message)})

    request('get', `/shelves?site=${this.siteId}`)
      .then(data => {
        this.shelves = data.shelves
        this.filterShelf= this.shelves[0]._id
      })
      .catch(err => console.log(err))

    request('get', `/rooms?site=${this.siteId}`)
      .then(data => {
        this.rooms = data.rooms
      })
      .catch(err => console.log(err))
  },
  watch:{
    filterShelf:function(){
      if(this.filterShelf!=="")
      {
        this.currentPage=1
        request('get',`/bins/${this.currentPage}?site=${this.siteId}&shelf=${this.filterShelf}`)
        .then((data)=>{
          // console.log(data)
          // this.bins=data.bins
          this.totalPages=data.pages
          if(data.bins.length>0)
          {
            let binList= [];
            data.bins.forEach(el=>binList.push(el._id))
            request('post',`/items/getBin?site=${this.siteId}`,{binList:binList})
            .then((items)=>{
              let tempListItem = items.items
              let bins=[...data.bins]
              for(let j=0;j<bins.length;j++)
              {
                for(let i=0;i<tempListItem.length;i++)
                {
                  if(tempListItem[i].bin===bins[j]._id)
                  {
                    bins[j]={...bins[j],item:{...tempListItem[i]}}
                    tempListItem.splice(i,1)
                    break
                  }
                }
              }
              this.bins=[...bins]
              // console.log(bins)
            })
            .catch(err=>{
              console.log(err)
            })
          }
          else
          {
            this.bins=[]
          }
          
        })
        .catch(err=>{
          console.log(err)
        })
      }
      
    }
  },
  data(){
    return{
      currentPage: 1,
      totalPages:0,
      siteId: this.$route.params.id,
      update_active:false,
      delete_active:false,
      detail_active:false,
      add_active:false,
      tempBin: {},
      tempItem: {},
      bins: [],
      items: [],
      shelves: [],
      rooms: [],
      list_shelf_show: false,
      shelf_selected: {},
      chosenRoom: {},
      list_item_show: false,
      list_room_show: false,
      item_selected: {},
      filterRoom: '',
      filterShelf: '',
      list_itemStatus_show:false,
      itemStatusList:["Fail","On-loan","Good","Charging"],
      itemStatusSelected:''

    }
    
  },
  
  computed: {
    pagiShow() {
      if(this.totalPages>0) {
          return true;
      }
      return false;
    },
    accessRight3(){
      let roleRight=['ROOT','CUSTOMER','MASTER','OPERATOR','CONTRACTOR'];
      return roleRight.includes(this.$store.state.role)
    },
    currentSiteItems() {
      return this.items.filter(item => {
        return item.site && (item.site == this.siteId || item.site._id == this.siteId)
      })
    },
    currentSiteShelves() {
      return this.shelves.filter(shelf => {
        return (shelf.room == this.chosenRoom._id || (shelf.room && shelf.room._id == this.chosenRoom._id)) && shelf.room && shelf.room.site && (shelf.room.site == this.siteId || shelf.room.site._id == this.siteId)
      })
    },
  },
  methods:{
    closeUpdate()
    {
      this.update_active=false;
      this.add_active=false;
      this.list_shelf_show=false;
      this.list_item_show=false;
      this.list_shelf_show=false;
      this.list_itemStatus_show=false
    },
     onChange: function () {
      request('get',`/bins/${this.currentPage}?site=${this.siteId}&shelf=${this.filterShelf}`)
      .then((data)=>{
        this.totalPages=data.pages
        if(data.bins.length>0)
        {
          let binList= [];
          data.bins.forEach(el=>binList.push(el._id))
          request('post',`/items/getBin?site=${this.siteId}`,{binList:binList})
          .then((items)=>{
            let tempListItem = items.items
            let bins=[...data.bins]
            for(let j=0;j<bins.length;j++)
            {
              for(let i=0;i<tempListItem.length;i++)
              {
                if(tempListItem[i].bin===bins[j]._id)
                {
                  bins[j]={...bins[j],item:{...tempListItem[i]}}
                  tempListItem.splice(i,1)
                  break
                }
              }
            }
            this.bins=[...bins]
            // console.log(bins)
          })
          .catch(err=>{
            console.log(err)
          })
        }
      })
      .catch(err=>{
        console.log(err)
      })
    },
    respectiveItem(binId) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].bin == binId || (this.items[i].bin && this.items[i].bin._id == binId)) {
          return this.items[i]
        }
      }

      return null
    },
    showAdd() {
      this.tempBin = {
        _id: '',
        name: '',
        active: true,
        capacity: 1,
        // lowThreshold: 50,
        // critThreshold: 20,
        shelf: {},
        item: {},
        site: this.siteId,
        percent:0,
        itemStatus:0
      }
      this.shelf_selected = {}
      this.chosenRoom = {}
      this.itemStatusSelected=''
      this.add_active=true
    },
    showUpdate(el){
      this.tempBin = {
        ...el
      }
     
      if (el.shelf && el.shelf._id) {
        this.shelf_selected = el.shelf
      } else {
        for (let i = 0; i < this.shelves.length; i++) {
          if (this.shelves[i]._id == el.shelf) {
            this.shelf_selected = this.shelves[i]
            break
          }
        }
      }
     
      this.chosenRoom = {}
      for (let i = 0; i < this.rooms.length; i++) {
        if (this.shelf_selected.room  && (this.rooms[i]._id == this.shelf_selected.room || this.rooms[i]._id == this.shelf_selected.room._id)) {
          this.chosenRoom = this.rooms[i]
        }
      }
      
      this.item_selected = {...el.item}
      // for (let i = 0; i < this.items.length; i++) {
      //   if (this.items[i].bin == el._id || (this.items[i].bin && this.items[i].bin._id == el._id)) {
      //     this.item_selected = this.items[i]
      //     break
      //   }
      // }
      this.itemStatusSelected=''
      for(let i=0;i<this.bins.length;i++)
      {
        if (this.bins[i]._id == el._id)
        {
          // this.itemStatusSelected=this.convertItemStatus(this.bin[i].itemStatus)
          switch(this.bins[i].itemStatus){
            case 1:
               this.itemStatusSelected='Good'
              break
            case 2:
               this.itemStatusSelected='Charging'
              break
            case 0:
               this.itemStatusSelected='On-loan'
              break
            case -1:
              this.itemStatusSelected='Fail'
              break
            default:
              this.itemStatusSelected='On-loan'
          }
          break;
        }
      }
      this.update_active=true;
    },
    convertItemStatus(el){
      let tempStatus=''
       switch(el){
          case 1:
              tempStatus='Good'
            break
          case 2:
              tempStatus='Charging'
            break
          case 0:
              tempStatus='On-loan'
            break
          case -1:
            tempStatus='Fail'
            break
          default:
            tempStatus='On-loan'
        }
        return tempStatus
    },
    showDelete(el){
      this.tempBin = el
      this.delete_active=true;
    },
    showDetail(el) {
      this.tempBin = {...el}
      // this.tempItem = {}
      // for (let i = 0; i < this.items.length; i++) {
      //   if (this.items[i].bin == this.tempBin._id) {
      //     this.tempItem = this.items[i]
      //   }
      // }
      this.detail_active=true
    },
    selectShelf(el){
      this.shelf_selected=el;
      this.list_shelf_show=false;
    },
    selectItem(el){
      this.item_selected=el;
      this.list_item_show=false;
    },
    selectItemStatus(el){
      this.itemStatusSelected=el;
      this.list_itemStatus_show=false;
    },
    handleAddBin(){
      switch(this.itemStatusSelected){
        case 'Good':
          this.tempBin.itemStatus=1
          break
        case 'Charging':
          this.tempBin.itemStatus=2
          break
        case 'On-loan':
          this.tempBin.itemStatus=0
          break
        case 'Fail':
          this.tempBin.itemStatus=-1
          break
        default:
          this.tempBin.itemStatus=0
      }
      // console.log(this.tempBin.itemStatus)
      if (this.shelf_selected) {
        this.tempBin.shelf = this.shelf_selected._id
      }
      if (this.item_selected) {
        this.tempBin.itemId = this.item_selected._id
      }
      this.tempBin.site = this.siteId

      if (this.$refs.addBinForm.reportValidity()) {
        request('post', '/bins', this.tempBin)
        .then(() => {
          return request('get',`/bins/${this.currentPage}?site=${this.siteId}&shelf=${this.filterShelf}`)
        })
        .then((data)=>{
          this.totalPages=data.pages
          this.add_active=false;
          if(data.bins.length>0)
          {
            let binList= [];
            data.bins.forEach(el=>binList.push(el._id))
            request('post',`/items/getBin?site=${this.siteId}`,{binList:binList})
            .then((items)=>{
              let tempListItem = items.items
              let bins=[...data.bins]
              for(let j=0;j<bins.length;j++)
              {
                for(let i=0;i<tempListItem.length;i++)
                {
                  if(tempListItem[i].bin===bins[j]._id)
                  {
                    bins[j]={...bins[j],item:{...tempListItem[i]}}
                    tempListItem.splice(i,1)
                    break
                  }
                }
              }
              this.bins=[...bins]
              // console.log(bins)
            })
            .catch(err=>{
              console.log(err)
            })
          }
        })
          .catch(err => {
            console.log(err)
            alert(err.message)
          })
      }
    },
    handleEditBin(){
      switch(this.itemStatusSelected){
        case 'Good':
          this.tempBin.itemStatus=1
          break
        case 'Charging':
          this.tempBin.itemStatus=2
          break
        case 'On-loan':
          this.tempBin.itemStatus=0
          break
        case 'Fail':
          this.tempBin.itemStatus=-1
          break
        default:
          this.tempBin.itemStatus=0
      }
      if (this.shelf_selected) {
        this.tempBin.shelf = this.shelf_selected._id
      } else {
        this.tempBin.shelf = null
      }

      if (this.item_selected) {
        this.tempBin.itemId = this.item_selected._id
      }

      this.tempBin.site = this.siteId
      
      if (this.$refs.updateBinForm.reportValidity()) {
        request('put', '/bins', {...this.tempBin})
          .then(data => {
            let findBin = this.bins.findIndex(el=>el._id===data.bin._id)
            if(this.item_selected)
            {
               this.bins[findBin]={...data.bin,item:{...this.item_selected}}
            }
            else
            {
              this.bins[findBin]={...data.bin}
            }
            let findOldBin = this.bins.findIndex(el=>el.item && el.item._id===this.item_selected._id && el._id!==data.bin._id)
            // console.log(findOldBin)
            if(findOldBin>=0)
            {
              this.bins[findOldBin].item={}
            }
           
           

            // for (let i = 0; i < this.items.length; i++) {
            //   if (this.items[i]._id == this.tempBin.item) {
            //     this.items[i].bin = data.bin._id
            //   } else if (this.items[i].bin == data.bin._id) {
            //     this.items[i].bin == null
            //   }
            // }
           this.update_active = false
          })
          .catch(err => {
            console.log(err)
            alert(err.message)
          })
      }
    },
    handleDeleteBin() {
      request('delete', `/bins/${this.tempBin._id}`, {site: this.tempBin.site})
        .then(() => {
          if(this.bins.length===1 && this.currentPage>1)
          {
            this.currentPage=this.currentPage-1;
          }
          return request('get',`/bins/${this.currentPage}?site=${this.siteId}&shelf=${this.filterShelf}`)
         
        })
        .then((data)=>{
          this.totalPages=data.pages
          this.delete_active=false;
          if(data.bins.length>0)
          {
            let binList= [];
            data.bins.forEach(el=>binList.push(el._id))
            request('post',`/items/getBin?site=${this.siteId}`,{binList:binList})
            .then((items)=>{
              let tempListItem = items.items
              let bins=[...data.bins]
              for(let j=0;j<bins.length;j++)
              {
                for(let i=0;i<tempListItem.length;i++)
                {
                  if(tempListItem[i].bin===bins[j]._id)
                  {
                    bins[j]={...bins[j],item:{...tempListItem[i]}}
                    tempListItem.splice(i,1)
                    break
                  }
                }
              }
              this.bins=[...bins]
              // console.log(bins)
            })
            .catch(err=>{
              console.log(err)
            })
          }
        })
        .catch(err => {
          alert(err.message)
        })
    }
  },
}
</script>
<style lang="scss" >
 .siteWithdraw_container{
     width: 100%;
     display: flex;
     justify-content: center;
    .main_table{
        align-items:flex-end;
        .User__btn_container {
          .User_btn{
            border-radius: 4px;
            font-size: 1rem;
            height: 48px !important;
            margin-bottom: 26px;
            margin-right: 0px;
            border-radius: 4px;
              .md-button-content{
                  display: flex;
                justify-content: center;
                align-items: center;
                 text-transform: none; 
            }
          }

          .btn_export{
              width: 199px;
              background: white;
              border: 2px solid #0080F7;
              color: #0080F7 !important;
              margin-left: 24px;    
          }
        }

        .bin-grid {
          position: relative;
          width: 100%;
         
          .filters {
            position: relative;
            top: 0px;
            display: flex;
            flex-direction: row;
            select {
              padding: 8px;
              font-weight: 500;
              border-radius: 5px;
              margin-right: 16px;
            }
          }
          
          .md-tabs {
            width: 100%;
            margin-top: 54px;
            background: white;
            padding: 16px 16px 32px 16px;
            #tab-home {
              height: auto !important;
              margin-bottom: -16px;
             
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .bin-wrapper {
                flex-basis: 25%;
                padding: 16px;
              }

              &::after {
                content: "";
                flex: auto;
              }
            }
           
          }

          .statuses {
            display: flex;
            justify-content: flex-end;
            position: absolute;
            top: 8px;
            right: 0;
            .status {
              width: 120px;
              border-radius: 5px;
              text-align: center;
              font-weight: bold;
              color: #fff;
              padding: 6px 0;
              margin-left: 8px;

              &.status-good {
                background-color: #00C14D;
              }
              &.status-low {
                background-color: #EB9515;
              }
              &.status-critical {
                background-color: #ED1D24;
              }
              &.status-unassigned {
                background-color: #6D6E71;
              }
            }
          }
        }
    }
    
 }

.dialog_updateBin{
   .md-dialog-container
    {
      
      max-height: 100vh !important;
    }
    .dialog_updateBin_texts{
      max-height: calc(100% - 300px);
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 60px;
      margin-top:0px;

      .input-group {
        display: flex;
        justify-content: space-between;
        .dialog_update__selects {
          flex-basis: calc(50% - 5px);

          .md-icon {
            left: 190px;
          }
        }

        .dialog_update__text_1 {
          flex-basis: calc(50% - 4px);
        }
      }
    }
    // .dialog_update_radios {
    //   // margin-top: 32px;
    // }
}
.dialog_details {
  .dialog_details__title {
    width: 640px;
    display: flex;
    justify-content: space-between;
  }

  .dialog_details___content {
    width: 640px;
    h3 {
      font-size: 18px;
    }

    .general-infos {
      display: flex;
      flex-wrap: wrap;
      .general-info {
        flex-basis: 25%;
        margin-bottom: 16px;
        .label {
          opacity: 0.7;
        }
      }
    }

    .threshold-infos {
      display: flex;
      flex-wrap: wrap;
      .threshold-info {
        flex-basis: 25%;
        margin-bottom: 16px;
        .label {
          opacity: 0.7;
        }
      }
    }
  }

  .dialog_detail__btn {
    text-align: right;
    padding: 16px;
  }
}
.custom_selector{
  color: black;
  font-size: 1rem;
  height: 48px;
  background-color:white;
  border-width: 0px;
  border-radius: 2px;
  padding: 8px;
  width: 260px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}
.custom_selector:focus{
  outline: none !important;
  border:1px solid #0080F6 !important ;
}
</style>