<template>
  <div :class="mobile.toLowerCase()" class="vhd-container">
    <div class="vhd-input-icon">
       <input v-model="value"
           :style="{'border-color':active?'#0080F7':'white'}"
           :placeholder="placeholder"
           type="text" class="vhd-input" aria-label="vue-hotel-datepicker-input"
           @mousedown.prevent="toggle"
           @focus.prevent="toggle" />
         <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8465 0.172809C14.6398 -0.0445822 14.3038 -0.0445822 14.0971 0.172809L7.50725 7.11574L0.904488 0.172809C0.697748 -0.0445822 0.361795 -0.0445822 0.155055 0.172809C-0.051685 0.3902 -0.051685 0.743461 0.155055 0.960852L7.11961 8.28422C7.22298 8.39292 7.35219 8.44727 7.49433 8.44727C7.62354 8.44727 7.76567 8.39292 7.86904 8.28422L14.8336 0.960852C15.0533 0.743461 15.0533 0.3902 14.8465 0.172809Z" fill="#171A26"/>
        </svg>
    </div>
   
    <div v-if="active" class="vhd-picker">
      <div class="vhd-calendar">
        <div class="vhd-calendar-left">
          <div class="calendar-month">
            <a  class="previous-arrow offset-2" @click="updateCalendar(-1)">
              <IconArrowBack class="arrow" />
            </a>
            <a  class="previous-arrow offset-1" @click="updateCalendar(-1)">
              <IconArrowBack class="arrow"/>
            </a>
            <div class="calendar-month-title">
              {{ monthList[startMonthDate.getMonth()] }} {{ startMonthDate.getFullYear() }}
            </div>
            <a class="next-arrow offset-1" @click="updateCalendar(1)">
              <IconArrowForward class="arrow" />
            </a>
          </div>
          <div class="calendar-week">
            <div v-for="(wItem, index) in weekList" :key="index" class="calendar-week-item">
              {{ wItem }}
            </div>
          </div>
          <div class="calendar-date">
            <div v-for="(week, wIndex) in startMonthAry" :key="wIndex" class="week">
              <div v-for="(startDay, dIndex) in week"
                   :key="dIndex"
                   :class="dayStatus(startDay)"
                   class="day"
                   @click="dayOnClick(startDay)">
                <span v-if="startDay">
                  {{ startDay.getDate() }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="vhd-calendar-right">
          <div class="calendar-month">
            <a  class="next-arrow" @click="updateCalendar(1)">
              <IconArrowForward class="arrow" />
            </a>
            <div class="calendar-month-title">
              {{ monthList[endMonthDate.getMonth()] }} {{ endMonthDate.getFullYear() }}
            </div>
          </div>
          <div class="calendar-week">
            <div v-for="(wItem, index) in weekList" :key="index" class="calendar-week-item">
              {{ wItem }}
            </div>
          </div>
          <div class="calendar-date">
            <div v-for="(week, wIndex) in endMonthAry" :key="wIndex" class="week">
              <div v-for="(endDay, dIndex) in week" :key="dIndex" :class="dayStatus(endDay)" class="day" @click="dayOnClick(endDay)">
                <span v-if="endDay">
                  {{ endDay.getDate() }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="vhd-line"></div>
         <div class="vhd-calendar-selector">
            <div class="radio_group">
                <div style="vertical-a">
                    <input type="radio" value="nowWeek" v-model="selectTime">
                    <label style="margin-left:9px" for="nowWeek">This week</label>
                </div>
                <div style="margin-top:6px">
                    <input type="radio"  value="nowMonth" v-model="selectTime">
                    <label style="margin-left:9px" for="nowMonth">This month</label>
                </div>
                <div style="margin-top:6px">
                    <input type="radio"  value="selected" v-model="selectTime">
                    <label style="margin-left:9px" for="selected">{{displayDateText(selectStartDate)}} - {{displayDateText(selectEndDate)}}</label>
                </div>
            </div>
            <div class="radio_group">
                <div>
                    <input type="radio"  value="lastWeek" v-model="selectTime">
                    <label style="margin-left:9px" for="lastWeek">Last week</label>
                </div>
                <div  style="margin-top:6px">
                      <input type="radio" value="lastMonth" v-model="selectTime">
                    <label style="margin-left:9px" for="lastMonth">Last month</label>
                </div>
            </div>
        </div>
        <div class="vhd-calendar-footer">
          <md-button class="md-raised btn_cancel" @click="active = false">Cancel</md-button>
          <md-button class="md-raised md-primary btn_add" @click="confirm">Set</md-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IconArrowBack from './icon/IconArrowBack'
import IconArrowForward from './icon/IconArrowForward'
export default {
  name: 'DatePicker',
  components: {
    IconArrowBack,
    IconArrowForward
  },
  directives: {},
  props: {
    placeholder: {
      type: String,
      default: 'Choose Time Range'
    },
    separator: {
      type: String,
      default: '-'
    },
    format: {
      type: String,
      default: 'MM/DD/YYYY'
    },
    startDate: {
      type: [String, Date],
      default: undefined
    },
    endDate: {
      type: [String, Date],
      default: undefined
    },
    minDate: {
      type: [String, Date],
      default: () => new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0)
    },
    maxDate: {
      type: [String, Date, Boolean],
      default: false
    },
    minNight: {
      type: Number,
      default: undefined
    },
    maxNight: {
      type: Number,
      default: undefined
    },
    selectForward: {
      type: Boolean,
      default: true
    },
    disabledDates: {
      type: Array,
      default: () => []
    },
    weekList: {
      type: Array,
      default: () => ['Sun', 'M', 'T', 'W', 'T', 'F', 'Sat']
    },
    monthList: {
      type: Array,
      default: () => ['January', 'February', 'March', '	April', 'May', 'June', 'July', '	August', 'September', 'October', 'November', 'December']
    },
    fromText: {
      type: String,
      default: 'From'
    },
    toText: {
      type: String,
      default: 'To'
    },
    resetText: {
      type: String,
      default: 'Reset'
    },
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    mobile: {
      type: String,
      default: '' // mobile or desktop
    }
  },
  data () {
    return {
      value: '',
      selectTime:'selected',
      active: false,
      startMonthDate: undefined,
      endMonthDate: undefined,
      selectStartDate: undefined,
      selectEndDate: undefined,
      selectMinDate: undefined,
      selectMaxDate: undefined,
      startMonthAry: [],
      endMonthAry: [],
      clickCount: 0
    }
  },
  watch: {
    selectTime:function(){
        if(this.selectTime=== "nowWeek")
        {
            let now=new Date();
            let day = now.getDay();
            let first=now.getDate()-day+(day==0?-6:1);
            let a=new Date(now.setDate(first));
            this.selectStartDate=new Date(a.getFullYear(),a.getMonth(),a.getDate());
            let n=new Date();
            this.selectEndDate=new Date(n.getFullYear(),n.getMonth(),n.getDate());
        }
            
        else if(this.selectTime==="nowMonth")
        {
          let now1=new Date();
          this.selectStartDate=new Date(now1.getFullYear(),now1.getMonth(),1);
          this.selectEndDate=new Date(now1.getFullYear(),now1.getMonth(),now1.getDate());
        }
        else if(this.selectTime==="lastWeek")
        {
            let now=new Date();
            let day = now.getDay();
            let first=now.getDate()-day+(day==0?-6:1)-7;
            let a=new Date(now.setDate(first));
            // let b= new Date(now.setDate(end))
            this.selectStartDate=new Date(a.getFullYear(),a.getMonth(),a.getDate());
            this.selectEndDate=new Date(this.selectStartDate.getTime()+6*24*3600*1000);
        }
        else if(this.selectTime==="lastMonth")
        {
          let now = new Date();
          let dayInlastMonth=new Date(now.getFullYear(),now.getMonth(),0).getDate();
          this.selectStartDate= new Date(now.getFullYear(),now.getMonth()-1,1);
          this.selectEndDate= new Date(now.getFullYear(),now.getMonth()-1,dayInlastMonth);
        }
          
    }
  },
  created () {
    if (this.minDate) {
      const minDateValue = typeof (this.minDate) === 'string' ? this.minDate : this.minDate.getTime()
      this.selectMinDate = new Date(minDateValue)
    }
    if (this.maxDate) {
      const maxDateValue = typeof (this.maxDate) === 'string' ? this.maxDate : this.maxDate.getTime()
      this.selectMaxDate = new Date(maxDateValue)
    }
    if (this.startDate) {
      const startDateValue = typeof (this.startDate) === 'string' ? this.startDate : this.startDate.getTime()
      this.selectStartDate = new Date(startDateValue)
      if (this.selectMinDate && this.selectMinDate.getTime() > this.selectStartDate.getTime()) {
        this.selectMinDate = new Date(startDateValue)
      }
      if (!this.endDate) {
        this.selectEndDate = new Date(this.selectStartDate.getTime() + (24 * 60 * 60 * 1000))
      } else {
        const endDateValue = typeof (this.endDate) === 'string' ? this.endDate : this.endDate.getTime()
        this.selectEndDate = new Date(endDateValue)
      }

      this.updateValue()
    }
    this.updateCalendar(0) // after setting
  },
  mounted () {},
  methods: {
    toggle (e) {
      if (e.type === 'focus') {
        this.active = true
        return true
      }

      this.active = !this.active
    },
    close () {
      this.active = false
      this.$emit('close')
    },
    reset () {
      this.selectStartDate = undefined
      this.selectEndDate = undefined
      this.value = ''
      this.$emit('reset')
    },
    confirm () {
      this.updateValue();
      if (this.selectStartDate && !this.selectEndDate) {
        this.selectEndDate = new Date(this.selectStartDate.getTime())
        this.selectEndDate.setDate(this.selectStartDate.getDate() + 1)
        
      }
      if (this.selectStartDate && this.selectEndDate) {
        const dateResult = {
          start: this.displayDateText(this.selectStartDate),
          end: this.displayDateText(this.selectEndDate)
        }
        this.$emit('confirm', dateResult)
        this.active = false
      }
    },
    displayDateText (datetime) {
      if (datetime) {
        datetime = typeof (datetime) === 'string' ? new Date(datetime) : datetime
        const yyyy = datetime.getFullYear()
        const mm = datetime.getMonth() + 1 > 9 ? datetime.getMonth() + 1 : `0${datetime.getMonth() + 1}`
        const dd = datetime.getDate() > 9 ? datetime.getDate() : `0${datetime.getDate()}`
        const displayStr = (this.format || 'YYYY/MM/DD').replace('YYYY', yyyy).replace('MM', mm).replace('DD', dd)
        return displayStr
      } else {
        return undefined
      }
    },
    generateCalendar (calculateYear = new Date().getFullYear(), calculateMonth = new Date().getMonth(), config = {}) {
      const showPreviousMonthDate = config.showPreviousMonthDate || false
      const showNextMonthDate = config.showNextMonthDate || false
      const baseDateTime = new Date(calculateYear, calculateMonth, 1, 0, 0, 0)
      let countTime = new Date(calculateYear, calculateMonth, 1, 0, 0, 0)
      let tempMonthAry = []
      let tempWeekAry = []
      let nextMonth = false
      let completed = false
      while (!nextMonth || (nextMonth && !completed)) {
        let day = countTime.getDay()
        let date = countTime.getDate()
        let month = countTime.getMonth()
        // check next month
        if (month !== calculateMonth) {
          nextMonth = true
          if (day === 6 || (date === 1 && day === 0)) {
            completed = true
          }
        }
        // Set date
        if (!nextMonth) {
          tempWeekAry[day] = new Date(countTime.getTime()) // date obj
        } else {
          tempWeekAry[day] = showNextMonthDate ? new Date(countTime.getTime()) : false
        }
        // check previous
        if (countTime.getTime() === baseDateTime.getTime() && day !== 0) {
          // Fill previous
          let previousDay = day
          let previousCountTime = new Date(countTime.getTime())
          previousCountTime.setDate(previousCountTime.getDate())
          if (showPreviousMonthDate) {
            while (previousDay !== 0) {
              // let previousDate = previousDateTime.getDate()
              let previousDateTime = new Date(previousCountTime.getTime())
              previousDay = previousDateTime.getDay()
              tempWeekAry[previousDay] = previousDateTime // date obj
              previousCountTime.setDate(previousCountTime.getDate() - 1)
            }
          }
        }
        // check new week
        if ((countTime.getTime() === baseDateTime.getTime() && tempWeekAry.length === 7) ||
            (countTime.getTime() > baseDateTime && day === 6)) {
          // Next week
          tempMonthAry.push(tempWeekAry)
          tempWeekAry = []
        }
        // calculate next day
        countTime.setDate(countTime.getDate() + 1)
      }

      return tempMonthAry
    },
    updateCalendar (offset = 0) {
      if (!this.startMonthDate) {
        this.startMonthDate = this.selectStartDate
          ? new Date(this.selectStartDate.getTime())
          : new Date(new Date().getFullYear(), new Date().getMonth()-1) // now
      }

      this.startMonthDate.setMonth(this.startMonthDate.getMonth() + offset)
      this.endMonthDate = new Date(this.startMonthDate.getFullYear(), this.startMonthDate.getMonth() + 1)

      this.startMonthAry = []
      this.endMonthAry = []
      this.startMonthAry = this.generateCalendar(this.startMonthDate.getFullYear(), this.startMonthDate.getMonth())
      this.endMonthAry = this.generateCalendar(this.endMonthDate.getFullYear(), this.endMonthDate.getMonth())
    },
    updateValue () {
      if (this.selectStartDate && this.selectEndDate) {
         this.value = `${this.displayDateText(this.selectStartDate)} ${this.separator} ${this.displayDateText(this.selectEndDate)}`
      }
      
    },
    disabledPreviousArrow (monthDatetime) {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
      if (monthDatetime && this.selectForward) {
        if (this.selectMinDate) {
          if (monthDatetime.getFullYear() < this.selectMinDate.getFullYear()) {
            return 'disabled'
          }
          if (monthDatetime.getFullYear() === this.selectMinDate.getFullYear() &&
            monthDatetime.getMonth() <= this.selectMinDate.getMonth()) {
            return 'disabled'
          }
        } else {
          if (monthDatetime.getFullYear() >= today.getFullYear() && monthDatetime.getMonth() >= today.getMonth()) {
            // console.log("disabled")
            return 'disabled'
          }
        }
      }
    },
    dayStatus (datetime) {
      const classList = []
      if (datetime) {
        const now = new Date()
        // const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
        // check status
        if (now.getTime() < datetime.getTime()) {
          classList.push('disabled')
        } else if (this.selectMaxDate && this.selectMaxDate.getTime() < datetime.getTime()) {
          classList.push('disabled')
        } else if (this.disabledDates.indexOf(this.displayDateText(datetime)) > -1) {
          classList.push('disabled')
          classList.push('forbidden')
        } else if (this.selectStartDate && this.selectStartDate.getTime() > datetime.getTime() && !this.selectForward) {
          classList.push('disabled')
        } else if (this.selectStartDate && this.selectStartDate.getTime() === datetime.getTime()) {
          classList.push('start-date')
        } else if (this.selectEndDate && this.selectEndDate.getTime() === datetime.getTime()) {
          classList.push('end-date')
        } else if (this.selectStartDate && this.selectEndDate &&
                   datetime.getTime() > this.selectStartDate.getTime() &&
                   datetime.getTime() < this.selectEndDate.getTime()) {
          classList.push('in-date-range')
        }
        // check min night and max night
        if (this.selectStartDate && ((Number.isInteger(this.minNight) && this.minNight > 0) ||
            (Number.isInteger(this.maxNight) && this.maxNight > 0))) {
          const night = Math.abs((datetime.getTime() - this.selectStartDate.getTime())) / (1000 * 60 * 60 * 24)
          if (night < this.minNight) {
            classList.push('disabled')
          } else if (night > this.maxNight) {
            classList.push('disabled')
          }
        }
        // check today
        if (now.getFullYear() === datetime.getFullYear() &&
            now.getMonth() === datetime.getMonth() &&
            now.getDate() === datetime.getDate()
        ) {
          classList.push('today')
        }
      }
      return classList
    },
    dayOnClick (datetime) {
      // console.log(datetime);
      this.selectTime='selected'
      if (datetime) {
        if (!this.selectStartDate) {
          this.selectStartDate = datetime
        } else if (!this.selectEndDate) {
          if (this.selectStartDate && datetime.getTime() < this.selectStartDate.getTime()) {
            this.selectEndDate = this.selectStartDate
            this.selectStartDate = datetime
          } else {
            this.selectEndDate = datetime
          }
        } else if (datetime.getTime() < this.selectStartDate.getTime()) {
          this.selectStartDate = datetime
        } else if (datetime.getTime() > this.selectEndDate.getTime()) {
          this.selectEndDate = datetime
        } else if (datetime.getTime() > this.selectStartDate.getTime() &&
                  datetime.getTime() < this.selectEndDate.getTime()) {
          if (this.clickCount % 2 === 0) {
            this.selectStartDate = datetime
          } else {
            this.selectEndDate = datetime
          }
          this.clickCount++
        }
        // check maxNight
        if (this.selectStartDate && this.selectEndDate && this.maxNight) {
          const limitDate = this.selectStartDate.getTime() + this.maxNight * 1000 * 60 * 60 * 24
          if (this.selectEndDate.getTime() > limitDate) {
            this.selectEndDate = new Date(limitDate)
          }
        }
        // check minNight
        if (this.selectStartDate && this.selectEndDate && this.minNight) {
          const limitDate = this.selectStartDate.getTime() + this.minNight * 1000 * 60 * 60 * 24
          if (this.selectEndDate.getTime() < limitDate) {
            this.selectEndDate = new Date(limitDate)
          }
        }
        const dateResult = {
          start: this.displayDateText(this.selectStartDate),
          end: this.displayDateText(this.selectEndDate)
        }
        this.$emit('update', dateResult)

        // if (this.selectStartDate && this.selectEndDate) {
        //   this.updateValue()
        // }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@mixin mobile-vhd() {
  .vhd {
    &-picker {
      width: 300px;
      padding: 8px;
     
    }
    &-calendar {
      &-header {
        height: 60px;
        > .info {
          display: block;
          width: 100%;
          height: 60px;
          padding-top: 36px;
        }
      }
      &-left {
        width: 100%;
        margin-right: 0;
      }
      &-right {
        display: none;
      }
      .calendar {
        &-month {
          .previous-arrow.offset-2 {
            display: none;
          }
          .previous-arrow.offset-1 {
            display: inline-block;
          }
          .next-arrow.offset-1 {
            display: inline-block;
          }
        }
        &-week {}
        &-date {
          .week {
            .day {
              width: calc(100% / 7);
              &.start-date {
                color: #171A26;
                border-left: none;
                background-color: #e8f8ff;
                transition: all .2s ease;
              }
              &.end-date {
                color: #171A26;
                border-right: none;
                background-color: #e8f8ff;
                transition: all .2s ease;
              }
            }
          }
        }
      }
    }
  }
}
* {
  box-sizing: border-box;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}
svg {
  fill: #7d7d7d;
  @media (hover: hover) {
    &:hover {
      fill: darken(#7d7d7d, 20%)
    }
  }
}
.vhd {
  &-container.mobile {
    @include mobile-vhd();
  }
  &-container {
    display: inline-block;
    position: relative;
  }
  &-input-icon{
    position: relative;
     input {
    width: 260px;
    height: 48px;
    padding: 8px;
    border: solid 1px #cccccc;
    color: #171A26;
    font-size: 16px;
    line-height: 32px;
    outline: none;
    border-radius: 2px;
    &::placeholder {
      color: #cccccc;
    }
    }
    svg{
      position: absolute;
      top:20px;
      right: 18px;
    }
  }
 
  &-picker {
    margin-left: 250px;
    margin-top:3px;
    z-index: 100;
    position: absolute;
    left: 0;
    width: 438px;
    min-height: 450px;
    max-height: 450px;
    padding:16px;
    background-color: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
    overflow: hidden;
  }
  &-calendar {
    .vhd-line{
      margin-top:12px;
      width: 100%;
      height: 0px;
      border-bottom: 1.5px solid #D7D7D7;
    }
    .vhd-calendar-selector{
      margin-top:10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .radio_group{
          width: 50%;
          display: flex;
          flex-direction: column;
          div{
            display:flex;
            align-items: center;
          }
      }
    }
    &-footer {
      height: 50px;
      margin-top: 13px;
      padding: 0px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      position: absolute;
      bottom: 20px;
      width: 406px;
       .btn_add{
        width: 164px;
        height: 50px;
        text-transform: none;
        font-weight: bold;
        border-radius: 4px;
        margin-right: 0px;
      }
      .btn_cancel{
        width: 100px;
        height: 50px;
        text-transform: none;
        font-weight: bold;
        border-radius: 4px;
        margin-right: 14px;
      }
    }
    &-left,
    &-right {
      display: inline-block;
      vertical-align: top;
      width:194px;
      height: 235px;
    }
    &-left::after{
      width: 12px;
      border-bottom: 1px solid #D7D7D7;
    }
    &-left {
      margin-right:12px;
    }
    .calendar {
      &-month {
        position: relative;
        height: 32px;
        margin-bottom: 8px;
        padding: 0px;
        .next-arrow,
        .previous-arrow {
          position: absolute;
          top: 0;
          padding-top: 0px;
          // border: solid 1px #0088FF;
          cursor: pointer;
          &.disabled {
            // cursor: not-allowed;
            display: none!important;
          }
        }
        .previous-arrow {
          left: -30px;
        }
        .next-arrow {
          right: 0;
          &.disabled {
            // cursor: not-allowed;
            display: none!important;
          }

        }
        .previous-arrow.offset-1 {
          display: none;
        }
        .next-arrow.offset-1 {
          display: none;
        }
        &-title {
          margin: 8px 0;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.6;
          text-align: center;
          color: #171A26;
        }
      }
      &-week {
        width: 100%;
        height: 28px;
        border-bottom:0.71px solid  #D7D7D7;
        margin-bottom: 8px;
        &-item {
          float: left;
          display: inline-block;
          font-size: 0.8rem;
          font-weight: bold;
          width: calc(100% / 7);
          height: 20px;
          color: #171A26;
          text-align: center;
          line-height: 20px;
          opacity: 0.6;
        }
      }
      &-date {
        
        .week {
          display: block;
          width: 100%;
          height: 27px;
          .day {
            float: left;
            position: relative;
            display: inline-block;
            width: 27px;
            height:27px;
            font-size: 14px;
            font-weight: 500;
            line-height:27px;
            color: #171A26;
            background-color: transparent;
            text-align: center;
            cursor: pointer;
            transition: background-color .4s cubic-bezier(0.165, 0.84, 0.44, 1);
            &::before,
            &::after{
              content: '';
              position: absolute;
              width: 0px;
              height: 100%;
              left: 0;
              background-color: transparent;
              opacity: 0;
              transition: opacity .4s cubic-bezier(0.165, 0.84, 0.44, 1),
                          background-color .4s cubic-bezier(0.165, 0.84, 0.44, 1),
                          width .4s cubic-bezier(0.165, 0.84, 0.44, 1);
            }
            &::after{
              left: auto;
              right: 0;
            }
            &.disabled {
              color: #ececec;
              pointer-events: none;
            }
            &.in-date-range {
              background-color: #e8f8ff;
            }
            &.start-date {
              position: relative;
              background-color:#e8f8ff;
              border-bottom-left-radius:100%;
              border-top-left-radius:100%;
              // transition: all .2s ease;
              &::before {
                // content: '';
                // position: absolute;
                // width: 4px;
                // height: 100%;
                // left: 0;
                border-radius: 100%;
                width: 26px;
                background-color: #c3e9fa;
                opacity: 0.7;
              }
            }
            &.end-date {
              position: relative;
              background-color:#e8f8ff;
              border-bottom-right-radius:100%;
              border-top-right-radius:100%;
              // transition: all .2s ease;
              &::after {
                // content: '';
                // position: absolute;
                // width: 4px;
                // height: 100%;
                border-radius: 100%;
                width: 26px;
                background-color:#c3e9fa;
                opacity: 0.7;
                transition: opacity .2s cubic-bezier(0.165, 0.84, 0.44, 1),
                            background-color .2s cubic-bezier(0.165, 0.84, 0.44, 1),
                            width .2s cubic-bezier(0.165, 0.84, 0.44, 1);
              }
            }
            &.today {
              // border: solid 1px #0088ff;
            }
            &.forbidden {
              // color: #a10903;
              color: #fed9d8;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .vhd {
    &-container:not(.desktop) {
      @include mobile-vhd();
    }
  }
}
input[type="radio"] {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
    transform: scale(1.5);
}
</style>
