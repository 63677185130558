<template>
    <div class="room_container">
      <div class="room_headers">
        <div>{{roomInfo.name}}</div>
        <div class="room_icons">
          <div v-if='accessRight3'>
            <md-menu md-size='medium' md-align-trigger>
              <div md-menu-trigger><Dot style="margin-right:15px" /></div>
               <md-menu-content>
                  <md-menu-item @click="$emit('update')">Update</md-menu-item>
                  <md-menu-item @click="$emit('delete')"><div style="color:red">Delete</div></md-menu-item>
              </md-menu-content>
            </md-menu>          
          </div>
          <div @click="show=!show"><Collapse  /></div>
        </div>
      </div>
      <div class="room_content">
        <div>ID: {{roomInfo._id}}</div>
        <div>Description: {{roomInfo.description}}</div>
        <div>Shelf: {{roomInfo.shelves.length}}</div>
        <div>Total Bin: {{roomInfo.binCount}}</div>
      </div>
      <transition  class="shelf" name="expand">
        <div v-if="show" class="list" >
          <div v-for="(shelf,index) in listShelf" :key="index">
              <shelf-content style="margin-bottom:10px" :info="shelf" @update="$emit('updateShelf', index)" @delete="$emit('deleteShelf', index)" />
          </div>
          <br>
        </div>
      </transition>
      
    </div>
</template>
<script>
import Dot from './icon/Dot'
import Collapse from './icon/Collapse'
import ShelfContent from './ShelfContent'
export default {
    name:'RoomContent',
    props:{
        roomInfo:{
            type:Object,
            default(){
                return {}
            }
        },
        listShelf:{
            type:Array,
            default(){
                return []
            }
        }
    },
    components:{
        Dot,Collapse,ShelfContent
    },
    data(){
        return{
            show:false
        }
    },
    computed:{
      accessRight3(){
        let roleRight=['ROOT','CUSTOMER','MASTER','ADMIN','CONTRACTOR'];
        return roleRight.includes(this.$store.state.role)
      },
    }
}
</script>
<style scoped lang='scss'>
.room_container{
    min-height:119px;
    width: 100%;
    background-color: white;
    padding:10px 18px;
    border-radius: 4px;
    .room_headers{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      div{
        font-weight: bold;
        font-size: 1rem;
      }
      .room_icons{
        display: flex;
      }
    }
    .room_content{
      font-size: 0.8rem;
      color: #6D6E71;
      div{
        text-align: left;
        margin-bottom: 5px;
      }
    }
}
.list{
  transform-origin: top;
  transition: transform .5s ease;
  overflow: hidden;
}
.expand-enter, .expand-leave-to{
  transform: scaleY(0);
}
</style>